import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import { FiDownload, FiPrinter } from "react-icons/fi";
import { MdDelete, MdRefresh } from "react-icons/md";
import ConfigConstants from "assets/Constants/config-constant";
import { CircularProgress } from "@material-ui/core";
import InfoModal from "components/Modals/InfoModal";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import MailSentModal from "components/Modals/MailSentModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { AiOutlineSchedule } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import GatePassSlip from "FrmGeneric/Invoice/GatePassSlip";
import { renderToString } from "react-dom/server";
import { whitespace } from "stylis";
import SizedYarnDispatch from "FrmGeneric/Invoice/SizedYarnDispatch"
import Select from 'react-select';


export default function TSizedYarnDispatchNoteEntry() {
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    // Select box
    let expanded = false;
    let docGroup = "Dispatch Challan Sized Yarn"

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    const { state } = useLocation()
    //changes by tushar
    const { idList, keyForViewUpdate, compType = '', deletionKey = false, requestfor = "SB", modules_forms_id } = state || {};

    // UseRefs
    const validate = useRef();
    const comboDataFunc = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();

    const [isApprove, setIsApprove] = useState(false);
    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')
    const [isLoading, setIsLoading] = useState(false);


    // Product Type Properties Table Data
    const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);
    const [storedProductTypePropertiesData, setStoredProductTypePropertiesData] = useState([]);



    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'approve':
                setActionType('(Approval)');
                setActionLabel('Approve')
                // setIsApprove(true)
                break;
            case 'update':
                $('#txt_dispatch_date').prop('disabled', true);
                $('#txt_customer').prop('disabled', true);
                setActionType('(Modification)');
                setActionLabel('Update')
                break;
            case 'view':
                if (deletionKey === true) {
                    setActionType('(Deletion)');
                } else {
                    setActionType('(View)');
                }
                await validate.current.readOnly("dispatchnoteId");
                // await validate.current.readOnly("GatePassFrmId");
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    //key for get Request type header
    //changes by tushar
    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'YN':
                return '(Cotton Yarn)';
            case 'RC':
                return '(Raw Cotton)';
            case 'BL':
                return '(Cotton Bales)';
            case 'GF':
                return '(Greige Fabric)';
            case 'FF':
                return '(Finish Fabric)';
            case 'PRM':
                return '(Raw Material)';
            default:
                return '';
        }
    }

    const [dispatch_challan_version, setDispatchVersion] = useState(idList !== null ? idList.dispatch_challan_version : 1)
    const [txt_dispatch_challan_no, setDispatchChallanNo] = useState(idList !== null ? idList.dispatch_challan_no : '')
    const [dispatch_challan_sized_yarn_id, setdispatchscheduledetailstransactionId] = useState(idList !== null ? idList.dispatch_challan_sized_yarn_id : 0);
    const [sizedYarnMasterData, setSizedYarnMasterData] = useState([]);
    const [checkedDetailsDataForTotal, setCheckedDetailsDataForTotal] = useState([])
    // Form Fields
    const [checkedCustomerOrderNoLength, setCheckedCustomerOrderNoLength] = useState(0)
    const [txt_actual_weight, settxt_actual_weight] = useState(0);
    const [txt_actual_quantity, settxt_actual_quantity] = useState(0);
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    const [rb_dispatch_order_creation_type, setDispOrdCreationType] = useState("S");
    const [txt_remark, setRemark] = useState('');
    const [txt_yarn_count, setYarnCount] = useState('');
    const [txt_rate, setRate] = useState(0);
    const [txt_weight, setWeight] = useState(0);
    const [txt_total_weight, settxt_Total_Weight] = useState(0)
    const [txt_total_quantity, settxt_total_quantity] = useState(0)
    const [txt_dispatch_date, setDispatchDate] = useState(today);
    const [dt_approve_date, setApproveDate] = useState(today());
    const [txt_customer_order_no, setCustomerOrderNo] = useState('')
    const [cmb_agent_id, setAgentId] = useState('')
    const [txt_yarn_bill_no, setYarnBillNo] = useState();

    //Form Fields
    const [cmb_dispatch_challan_type, setDispatchChallanType] = useState('');
    const [cmb_dispatch_type, setDispatchType] = useState(1024);

    const [cmb_dispatch_by_id, setDispatchById] = useState('');
    const [txt_customer, setCustomerName] = useState('');
    const [txt_customer_id, setCustomerId] = useState('');
    const [txt_customer_contacts_ids, setCustomerContactsId] = useState('');
    const [cmb_count_id, setCountId] = useState('');
    const [cmb_set_no, setSetNo] = useState('')

    // gate pass fields
    const [txt_gate_pass_no, setGatePassNo] = useState("");
    const [dt_gate_pass_date, setGatePassDate] = useState(requestfor === 'YN' ? currentDate : '');
    const [txt_vehicle_no, setVehicleNo] = useState("");
    const [txt_net_weight, setNetWeight] = useState("0");
    const [txt_container_no, setContainerNo] = useState("");
    const [txt_seal_no, setSealNo] = useState("");
    const [txt_driver_name, setDriverName] = useState("");
    const [txt_driver_contact_no, setDriverContactNo] = useState("");
    const [dt_vehicle_reporting_time, setVehicleReportingTime] = useState("");
    const [dt_vehicle_loading_started_time, setVehicleLoadingStartedTime] = useState("");
    const [dt_vehicle_loading_finish_time, setVehicleLoadingFinishTime] = useState("");

    // consignee details form fields
    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeState] = useState('');
    const [cmb_consignee_city_id, setConsigneeCity] = useState('');
    const [cmb_approved_by_id, setApprovedById] = useState();
    const [txt_note_Remark, setNoteRemark] = useState('');
    const [txt_other_terms_conditions, setOtherCondition] = useState('');
    const [rb_is_active, setIsActive] = useState(true)

    // Combo box arrays
    const [stateOpts, setStateOpts] = useState([]);
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [custContactDetails, setCustContactDetails] = useState([]);
    const [custContactDetailsCols, setCustContactDetailsCols] = useState([]);
    const [consigneeList, setConsigneeList] = useState([]);
    const [cmb_dispatch_note_status, setDispatchNoteStatus] = useState('P');
    const [customerList, setCustomerList] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [consigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [approveByOpts, setApproved] = useState([]);
    const [customerOrderNoList, setCustomerOrderNoList] = useState([])
    const [storedDispatchDetailsData, setStoredDispatchDetailsData] = useState([])
    const [cmb_supervisorList, setSupervisorList] = useState([])
    const [dispatchScheduleTypeList, setDispatchScheduleTypeList] = useState([])
    const [packingSummaryList, setPackingSummaryList] = useState([])
    const [PackingDetailsList, setPackingDetailsList] = useState([])
    const [dispatchTypeList, setDispatchTypeList] = useState([])


    const [agentOptions, setAgentOptions] = useState([])

    const [DispatchMasterJson, setDispatchMasterJson] = useState([]);
    const [dispatchDetailsData, setDispatchDetailsData] = useState([]);
    // const [checkedDispatchDetailsData, setCheckedDispatchDetailsData] = useState([]);

    // For Uploaded Document Table
    const [uploadedDocData, setUploadedDocData] = useState([]);

    // Fn to get Inspection modal with items
    const [showPackingDetailsModal, setViewPackingDetailsModal] = useState(false);
    const [currentPkgngMtrlForModal, setCurrentPkgngMtrlForModal] = useState(null);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // Info Modal
    const [showInfoMsgModal, setShowInfoMsgModal] = useState(false);
    const [infoMsg, setInfoMsg] = useState('');
    const handleCloseInfoModal = () => setShowInfoMsgModal(false);
    let dispatchByComboRef = useRef(null);
    let customerComboRef = useRef(null);
    let consigneeComboRef = useRef(null);
    let setNoComboRef = useRef(null);

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => {
        setShowDeleteModal(false)
        // moveToListing();
    };

    // For confirmation Modal.
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const FnCloseConfirmationModal = () => {
        setShowConfirmationModal(false)
        moveToListing();
    };

    //Show another component in modal
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Customer City':
                comboOnChange('CustomerState');
                break;
            case 'Consignee City':
                comboOnChange('ConsigneeState');
                break;
            case 'Dispatch Schedule Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const product_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                setDispatchScheduleTypeList(product_Types)
                break;
            case 'Dispatch Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("property_value");
                globalQuery.table = "amv_properties"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "Dispatch Type" });
                const dispatch_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                setDispatchTypeList(dispatch_Types)
                break;
            default:
                break;

        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }

    // For navigate
    const navigate = useNavigate();

    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const FnCloseDocumentUploadModal = async () => {
        setShowDocumentForm(false)
        await showDocumentRecords();
    };
    const viewDocumentForm = () => setShowDocumentForm(true);
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    const colNames =
        ['sr_no', "customer_order_no", "product_material_print_name", "product_material_tech_spect",
            "batch_no", "so_sr_no", "material_schedule_date", "material_quantity",
            "material_weight", "dispatched_quantity", "dispatched_weight", "stock_quantity",
            "stock_weight", "expected_dispatch_quantity", "expected_dispatch_weight", "actual_dispatch_quantity",
            "actual_dispatch_weight", "product_material_our_part_code", "product_material_unit_id",
            "product_material_packing_id", "dispatch_return_quantity", "dispatch_return_weight",
            "pending_quantity", "pending_weight", "delayed_days",
            "invoice_quantity", "invoice_weight", "dispatch_schedule_item_status", "actual_count"];

    // Use colNames in your component as needed

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }

    useEffect(() => {
        setIsLoading(true);
        const loadDataOnload = async () => {
            ActionType()
            await fillComobos();
            if (idList !== null) {
                await FnCheckUpdateResponce();
            }
            setIsLoading(false);
        }
        loadDataOnload()

        // Close customer order no dropdown
        $(document).on('mouseup', function (e) {
            var container = $("#customer-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [requestfor])


    // Function to convert all objects to the dispSche-details keys object.
    const FnConvert2DispSchDetails = async (convertFrom, recievedData) => {
        debugger
        let currentDate = new Date();
        let materialIds = [];
        let getAvlStockQty = [];
        switch (convertFrom) {
            case 'Filter2DispSchDetails':
                let todayDate = today();
                let convertedDataFilter2DispSchDetails = recievedData
                    .filter(dispSchDetailFromFilter => {
                        return !dispatchDetailsData.some(dispatchDetail => dispatchDetail.product_id === dispSchDetailFromFilter.product_id);
                    })
                    .map((dispSchDetailFromFilter, index) => {
                        const {
                            product_type_group,
                            product_type_name: product_type,
                            product_name: product_material_name,
                            product_our_part_code: product_material_our_part_code,
                            product_tech_spect: product_material_tech_spect,
                            product_unit_name: product_material_unit_name,
                            product_packing_name: product_packing_name,
                            product_std_weight: product_material_std_weight,
                            product_id: product_material_id,
                            product_unit_id: product_material_unit_id,
                            product_packing_id: product_material_packing_id,
                            ...rest
                        } = dispSchDetailFromFilter;

                        let keysUpdatedForDispSchDetail = {
                            product_type_group, product_type, product_material_name, product_material_tech_spect, product_material_our_part_code,
                            product_material_unit_name, product_packing_name, product_material_std_weight,
                            product_material_id, product_material_unit_id, product_material_packing_id,
                            product_material_print_name: product_material_name,
                            ...rest
                        };

                        // add the new keys which are not received from the filter popup.
                        keysUpdatedForDispSchDetail.product_id = product_material_id
                        keysUpdatedForDispSchDetail.product_our_part_code = product_material_our_part_code
                        keysUpdatedForDispSchDetail.dispatch_return_quantity = 0
                        keysUpdatedForDispSchDetail.dispatch_return_weight = 0
                        keysUpdatedForDispSchDetail.pending_quantity = 0
                        keysUpdatedForDispSchDetail.pending_weight = 0
                        keysUpdatedForDispSchDetail.delayed_days = 0
                        keysUpdatedForDispSchDetail.invoice_quantity = 0
                        keysUpdatedForDispSchDetail.invoice_weight = 0
                        keysUpdatedForDispSchDetail.dispatch_schedule_item_status = 'P'
                        keysUpdatedForDispSchDetail.dispatch_schedule_remark = '';
                        keysUpdatedForDispSchDetail.expected_schedule_date = today();
                        return keysUpdatedForDispSchDetail;
                    });
                let updatedMaterialDetail = [...dispatchDetailsData, ...convertedDataFilter2DispSchDetails];
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedMaterialDetail));
                setDispatchDetailsData(updatedMaterialDetail);

                let updatedArrayIds = updatedMaterialDetail.map((detailsItem) => detailsItem.product_id);
                if (updatedArrayIds.length !== 0) {
                    const formattedIds = updatedArrayIds.map((id, index) => `${id}`);
                    resetGlobalQuery();
                    globalQuery.columns.push("*");
                    globalQuery.table = "smv_product_dynamic_parameters"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "product_id", operator: "IN", values: formattedIds });
                    const getProductProperties = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    // await fnGetProductProperties(getProductProperties);
                }

                return updatedMaterialDetail;

            case 'SalesOrder2DispSchDetails':
                let srNoForSalesOrderBased = 1;
                let expectedSOMaterialScheduleDate;
                let convertedDataSalesOrder2DispSchDetails = [];
                // Iterate the loop on received data and change the keys.
                recievedData?.map((challanDetailFromSalesOrder, index) => {
                    let dispatchQty = parseFloat(challanDetailFromSalesOrder.material_quantity) - parseFloat(challanDetailFromSalesOrder.previous_dispatch_quantity);
                    let dispatchWt = parseFloat(challanDetailFromSalesOrder.material_weight) - parseFloat(challanDetailFromSalesOrder.previous_dispatch_weight);
                    // let dispatchWt = parseFloat(dispatchQty) * parseFloat(challanDetailFromSalesOrder.product_rm_std_weight)
                    // Calculate the delayed days.
                    expectedSOMaterialScheduleDate = new Date(challanDetailFromSalesOrder.material_schedule_date);  // expected date.
                    let delayedDays = Math.floor((currentDate - expectedSOMaterialScheduleDate) / (1000 * 60 * 60 * 24));

                    let requiredJSONForDispSchMaterial = {
                        sr_no: srNoForSalesOrderBased,
                        so_sr_no: challanDetailFromSalesOrder.so_sr_no,
                        customer_order_no: challanDetailFromSalesOrder.customer_order_no,
                        customer_order_Date: challanDetailFromSalesOrder.customer_order_Date,
                        sales_order_no: challanDetailFromSalesOrder.sales_order_no,
                        sales_order_version: challanDetailFromSalesOrder.sales_order_version,
                        sales_order_Date: challanDetailFromSalesOrder.sales_order_date,
                        product_type_group: challanDetailFromSalesOrder.product_type_group,
                        product_material_id: challanDetailFromSalesOrder.product_material_id,
                        product_material_name: challanDetailFromSalesOrder.product_material_name,
                        product_material_print_name: challanDetailFromSalesOrder.product_material_print_name,
                        product_material_tech_spect: challanDetailFromSalesOrder.product_material_tech_spect,
                        product_material_unit_id: challanDetailFromSalesOrder.product_material_unit_id,
                        product_material_unit_name: challanDetailFromSalesOrder.product_material_stock_unit_name,
                        product_material_packing_id: challanDetailFromSalesOrder.product_material_packing_id,
                        product_packing_name: challanDetailFromSalesOrder.product_material_packing_name,
                        hsn_sac_id: challanDetailFromSalesOrder.product_material_hsn_code_id,
                        product_material_hsn_sac_code: challanDetailFromSalesOrder.product_material_hsn_sac_code,
                        hsn_sac_percent: challanDetailFromSalesOrder.product_material_hsn_sac_rate,
                        product_material_std_weight: challanDetailFromSalesOrder.product_material_std_weight,
                        available_stock_qty: 0,
                        max_dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchWt), 4),
                        // item_rate: validateNumberDateInput.current.decimalNumber(JSON.stringify(singleMaterialRate), 4),
                        item_rate: challanDetailFromSalesOrder.so_rate,
                        item_amount: validateNumberDateInput.current.decimalNumber((challanDetailFromSalesOrder.so_rate * parseFloat(dispatchQty)).toString(), 4),
                        item_discount_percent: 0,          // Not applicable for sales-order-based
                        item_discount_amount: 0,          // Not applicable for sales-order-based
                        item_freight_amount: 0,
                        expected_schedule_date: challanDetailFromSalesOrder.material_schedule_date,
                        delayed_days: delayedDays,
                        dispatch_return_quantity: 0,
                        dispatch_return_weight: 0,
                        pending_quantity: 0,
                        pending_weight: 0,
                        invoice_quantity: 0,
                        invoice_weight: 0,
                        pree_close_flag: 'false',
                        dispatch_challan_item_status: "P",
                        dispatch_challan_remark: '',
                        remark: '',
                        production_count_id: challanDetailFromSalesOrder.production_count_id,
                        production_count_name: challanDetailFromSalesOrder.production_count_name,
                    }

                    // send material-item for calculation.
                    convertedDataSalesOrder2DispSchDetails.push(requiredJSONForDispSchMaterial);
                    srNoForSalesOrderBased++;
                });

                if (convertedDataSalesOrder2DispSchDetails.length > 0) {
                    // Get the available stock qty.
                    convertedDataSalesOrder2DispSchDetails = await FnUpdateStockAvailability(convertedDataSalesOrder2DispSchDetails, false);
                }

                setDispatchDetailsData(convertedDataSalesOrder2DispSchDetails);
                return convertedDataSalesOrder2DispSchDetails;

            default:
                break;
        }

    }

    const fillComobos = async () => {
        try {

            resetGlobalQuery();
            globalQuery.columns.push("DISTINCT(set_no)");
            globalQuery.columns.push('customer_order_no');
            globalQuery.table = "xt_sizing_production_stock_details";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "sized_beam_status", operator: "=", value: 'A' });
            globalQuery.orderBy = ['set_no DESC'];
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(setNoOpts => {
                    const supervisorList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        ...setNoOpts.map((opt) => ({ ...opt, value: opt.set_no, label: `${opt.set_no} (${opt.customer_order_no})` })),
                    ]
                    setSetNoOptions(supervisorList);
                });

            resetGlobalQuery();
            globalQuery.columns.push("agent_id", "agent_name");
            globalQuery.table = "cm_agent";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(setNoOpts => {
                    setAgentOptions(setNoOpts);
                });



            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setApproved(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'state_code'];
            globalQuery.table = "cmv_state"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(stateList => {
                    setStateOpts(stateList);
                });

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", 'product_type_short_name'];
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_short_name", operator: "=", value: requestfor });
            const getProductType = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setDispatchScheduleTypeList(getProductType);
            console.log("Product Type List: ", getProductType);
            if (getProductType.length !== 0) {
                const productType = getProductType.find((item) => item.product_type_short_name === requestfor);
                if (productType) {
                    setDispatchChallanType(productType.field_id);
                    console.log(`Dispatch schedule type id: ${productType.id}`)
                    await generateDispatchScheduleNo(productType.product_type_short_name);
                }
            }
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("property_value");
            globalQuery.table = "amv_properties"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "Dispatch Type" });
            const dispatch_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setDispatchTypeList(dispatch_Types)
            if (dispatch_Types.length !== 0) {
                const dispatchType = dispatch_Types.find((item) => item.property_value === requestfor);
                if (dispatchType) {
                    setDispatchType(dispatchType.field_id)
                }
            }

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_gst_no", 'cust_branch_type']
            globalQuery.table = "cmv_customer_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });


            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(custOpts => {
                    // Build the full customer list
                    const customerList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        ...custOpts.map((customer) => ({
                            ...customer,
                            value: customer.field_id,
                            label: customer.field_name
                        })),
                    ];

                    // Set both lists in state
                    setCustomerList(customerList);
                    setConsigneeList(customerList);
                });


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee"
            // globalQuery.conditions.push({ field: "department_id", operator: "=", value: '2' });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                // .then(supervisorsOpts => {
                //     setSupervisorList(supervisorsOpts);
                // });

                .then(supervisorsOpts => {
                    const supervisorList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        // { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                        ...supervisorsOpts.map((supervisor) => ({ ...supervisor, value: supervisor.field_id, label: supervisor.field_name })),
                    ];

                    setSupervisorList(supervisorList);

                });

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnCheckUpdateResponce = async () => {
        try {
            debugger
            //UPDATE
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DispatchSizedYarn/FnShowParticularRecordForUpdate/${idList.dispatch_challan_sized_yarn_id}/${parseInt(COMPANY_ID)}`)
            const response = await apiCall.json();
            console.log("DispatchSizedYarnData", response)
            let { SizedYarnMasterData, SizedYarnDetailsData } = response
            if (response !== null) {

                setSizedYarnMasterData(response.SizedYarnMasterData)
                setDispatchChallanNo(SizedYarnMasterData.dispatch_challan_no)
                setDispatchChallanType(SizedYarnMasterData.dispatch_challan_type_id);
                setDispatchDate(SizedYarnMasterData.dispatch_challan_date);
                setDispatchNoteStatus(keyForViewUpdate === "approve" ? "D" : "P");
                setAgentId(SizedYarnMasterData.agent_id);
                setConsigneeId(SizedYarnMasterData.consignee_id);
                setConsigneeState(SizedYarnMasterData.consignee_state_id)
                await comboOnChange("ConsigneeState");
                setConsigneeCity(SizedYarnMasterData.consignee_city_id)
                await comboOnChange('ConsigneeCity')
                setCustomerName(SizedYarnMasterData.customer_name)
                setCustomerOrderNo(SizedYarnMasterData.customer_order_no);
                setCustomerContactsId(SizedYarnMasterData.customer_contacts_ids)
                setCustomerId(SizedYarnMasterData.customer_id);
                setCustomerState(SizedYarnMasterData.customer_state_id)
                await comboOnChange("CustomerState")
                setCustomerCity(SizedYarnMasterData.customer_city_id)
                await comboOnChange("CustomerCity");
                if (SizedYarnMasterData.dispatch_challan_status === 'D') {
                    setDispatchById(SizedYarnMasterData.approved_by_id);
                } else {
                    setDispatchById(parseInt(UserId))
                }
                setSetNo(SizedYarnMasterData.set_no)
                setDispatchVersion(SizedYarnMasterData.dispatch_challan_version)
                // await comboOnChange('CustomerState')
                // await comboOnChange('ConsigneeState')
                setVehicleNo(SizedYarnMasterData.vehicle_no)
                setYarnCount(SizedYarnMasterData.yarn_count)
                setWeight(SizedYarnMasterData.weight)
                setRate(SizedYarnMasterData.rate)
                setYarnBillNo(SizedYarnMasterData.yarn_bill_no)
                setDriverName(SizedYarnMasterData.driver_name)
                setOtherCondition(SizedYarnMasterData.other_terms_conditions)
                setRemark(SizedYarnMasterData.remark)
                setDispatchDetailsData(SizedYarnDetailsData)
                setCheckedDetailsDataForTotal(SizedYarnDetailsData)

                if (['approve', 'view'].includes(keyForViewUpdate)) {
                    $(".hide_it").hide();
                }
                if(keyForViewUpdate === 'view' && SizedYarnMasterData.dispatch_challan_status === 'D'){
                    $("#printBtnId").show();
                }else{
                    $("#printBtnId").hide();
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    function renderCustomerOrderNoCheckbox(item, index) {
        if (index === 0) {
            return (
                <>
                    <li className="item cust_order_no">
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                id="all_cust_order_no_id"
                                value={index}
                                className="erp_radio_button filterCheckBox"
                                onChange={(event) => checkCustomerOrderNo(event.target.value)}
                                optional='optional'
                                {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                            />
                        </span>
                        <span className="item-text">All</span>
                    </li>
                    <li className="item cust_order_no">
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                name="customerOrderNoCheckBox"
                                value={item.customer_order_no}
                                id={`filterCheck-${item.customer_order_no}`}
                                className="erp_radio_button customerOrderNoCheckBox"
                                onChange={(event) => checkCustomerOrderNo(event.target.value)}
                                optional='optional'
                                {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                            />
                        </span>
                        <span className="item-text">{item.customer_order_no}</span>
                    </li>
                </>
            );
        }
        return (
            <li className="item cust_order_no">
                <span className="checkbox">
                    <input
                        type="checkbox"
                        fieldName={item.customer_order_no}
                        name="customerOrderNoCheckBox"
                        value={item.customer_order_no}
                        id={`filterCheck-${item.customer_order_no}`}
                        className="erp_radio_button customerOrderNoCheckBox"
                        onChange={(event) => checkCustomerOrderNo(event.target.value)}
                        {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                    />
                </span>
                <span className="item-text">{item.customer_order_no}</span>
            </li>
        );
    }

    const comboOnChange = async (key) => {
        debugger
        switch (key) {

            case 'cmb_set_no':

                setIsLoading(true)
                let setNo = setNoComboRef.current
                console.log(setNo)
                //GET
                try {
                    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DispatchSizedYarn/FnGetSizedYarnData/${COMPANY_ID}/${setNo}`)
                    const responce = await apiCall.json();
                    console.log(' Sized Yarn Details : ', responce);

                    setSizedYarnMasterData(responce.SizedYarnMasterData)
                    let masterData = responce.SizedYarnMasterData;
                    let SizedYarnDetailsData = responce.SizedYarnDetailsData;
                    setCustomerName(SizedYarnDetailsData[0]['customer_name'])
                    setCustomerId(masterData.customer_id)
                    setCustomerOrderNo(masterData.customer_order_no)
                    setCustomerState(masterData.customer_state_id)
                    await comboOnChange("CustomerState")
                    setCustomerCity(masterData.customer_city_id)
                    await comboOnChange("CustomerCity");

                    setConsigneeId(masterData.consignee_id)
                    setConsigneeState(masterData.consignee_state_id)
                    await comboOnChange("ConsigneeState");
                    setConsigneeCity(masterData.consignee_city_id)
                    await comboOnChange('ConsigneeCity')

                    setYarnCount(masterData.product_material_name)
                    setRate(masterData.material_rate)
                    setWeight(masterData.material_weight)
                    setAgentId(masterData.agent_id)
                    setCustomerContactsId(masterData.customer_contacts_ids)
                    SizedYarnDetailsData.forEach((data) => {
                        data['beam_id'] = data['beam_no'];
                        delete data['beam_no'];
                    });

                    setDispatchDetailsData(responce.SizedYarnDetailsData);
                    setIsLoading(false)
                }
                catch (error) {
                    console.log('error: ', error);
                    setIsLoading(false)
                }

            case 'dispatchScheduleType':

                let dispatchScheduleType = parseInt($('#cmb_dispatch_challan_type').val());
                setDispatchChallanType(dispatchScheduleType)
                if (dispatchScheduleType !== 0 && !isNaN(dispatchScheduleType)) {
                    $('#error_cmb_dispatch_schedule_type').hide();
                    await generateDispatchScheduleNo();
                }
                // if (dispatchScheduleType === 0) {
                //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                //     setHeaderName('Dispatch Schedule Type')
                //     setShowAddRecModal(true)
                //     setTimeout(() => {
                //         $(".erp_top_Form").css("padding-top", "0px");
                //     }, 100)
                // }
                break;



            case 'Customer':

                // let selectedCustomer = parseInt($('#txt_customer').val());
                let selectedCustomer = customerComboRef.current.value;

                setCustomerName(selectedCustomer)
                if (selectedCustomer !== 0 && !isNaN(selectedCustomer)) {
                    let dispatchType = document.querySelector('input[name="rb_dispatch_order_creation_type"]:checked').value;
                    if (dispatchType === 'S') {
                        debugger
                        // Get customer order nos
                        resetGlobalQuery();
                        globalQuery.columns.push("DISTINCT(customer_order_no)");
                        globalQuery.columns.push("customer_contacts_ids");
                        globalQuery.table = "mtv_sales_order_master_trading_summary"
                        // globalQuery.conditions.push({ field: "sales_order_status", operator: "NOT IN", values: ['X', 'R', 'P'] });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        // globalQuery.conditions.push({ field: "sales_order_type_id", operator: "=", value: document.getElementById("cmb_dispatch_challan_type").value });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                        const customer_order_no_list = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                        let listItems = customer_order_no_list.map(renderCustomerOrderNoCheckbox);
                        setCustomerOrderNoList(listItems)
                        $('#all_cust_order_no_id').prop('checked', false);
                        $('.customerOrderNoCheckBox').prop('checked', false);
                        if (customer_order_no_list.length <= 0) {
                            $('#error_customer_orders_list').show();
                            $('#error_customer_orders_list').text('No customer orders are available...!');
                        } else {
                            $('#error_customer_orders_list').hide();
                        }

                        $('.customerOrderNoCheckBox').prop('checked', false);
                        setCheckedCustomerOrderNoLength(0);
                        setDispatchDetailsData([]);
                        if (!isApprove) {
                            totalDispatchQuantity()
                        }
                    }


                    // Get customer city & state
                    resetGlobalQuery();
                    globalQuery.columns = ["cust_branch_state_id", "cust_branch_city_id"];
                    globalQuery.table = "cmv_customer_summary";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                    const getCustomerDetailsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                    if (getCustomerDetailsApiCall.length > 0) {
                        setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                        await comboOnChange('CustomerState');
                        setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                    } else {
                        setCustomerState('');
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                    }

                    await FnGetCustomersContactPersonsDetails(selectedCustomer);
                    $('#error_customer_contact_persons').hide();
                } else {
                    setCustomerState('');
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                    setCustomerOrderNoList([])
                    setDispatchDetailsData([])
                }

                if (selectedCustomer === 0) {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                setDispatchDetailsData([]);
                setProductTypePropertiesData([]);
                break;

            case 'CustomerState':
                let customerState = parseInt($('#cmb_customer_state_id').val());
                setCustomerState(customerState)
                if (customerState !== 0 && !isNaN(customerState)) {
                    const getCustomerCities = await comboDataFunc.current.fillMasterDataWithOperator("cmv_city", "state_id", "=", JSON.stringify(customerState));
                    setCustomerCityOpts(getCustomerCities);
                    if (getCustomerCities.length < 0) { setCustomerCity(''); }
                } else {
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

            case 'CustomerCity':
                let customerCity = parseInt($('#cmb_customer_city_id').val());
                setCustomerCity(customerCity)
                if (customerCity !== 0 && !isNaN(customerCity)) {
                    $('#error_cmb_customer_city_id').hide()
                }
                if (customerCity === 0) {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Customer City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'Consignee':
                // let selectedConsigneeId = parseInt($('#cmb_consignee_id').val());
                let selectedConsigneeId = consigneeComboRef.current.value;
                setConsigneeId(selectedConsigneeId)

                break;

            case 'ConsigneeState':
                let consigneeState = parseInt($('#cmb_consignee_state_id').val());
                setConsigneeState(consigneeState)
                if (consigneeState !== 0 && !isNaN(consigneeState)) {
                    $('#error_cmb_consignee_state_id').hide()
                    const getConsigneeCitiesApiCall = await comboDataFunc.current.fillMasterDataWithOperator("cmv_city", "state_id", "=", JSON.stringify(consigneeState));
                    setConsigneeCityOpts(getConsigneeCitiesApiCall);
                } else {
                    setConsigneeCityOpts([]);
                    setConsigneeCity('');
                }
                break;

            case 'ConsigneeCity':
                let consigneeCity = parseInt($('#cmb_consignee_city_id').val());
                setConsigneeCity(consigneeCity)
                if (consigneeCity !== 0 && !isNaN(consigneeCity)) {
                    $('#error_cmb_consignee_state_id').hide()
                }
                if (consigneeCity === 0) {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Consignee City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'cmb_dispatch_by_id':
                let dispatchById = dispatchByComboRef.current
                setDispatchById(dispatchById)
                break;
            default:
                break;
        }
    }

    // For update the combobox data after add new record.
    const FnRefreshComboData = async (key) => {
        if (keyForViewUpdate === 'view') {
            return false;
        }
        switch (key) {
            case 'UpdateConsignees':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'is_sez', "cust_branch_gst_no", 'cust_branch_type'];
                globalQuery.table = "cmv_customer_summary"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                // globalQuery.conditions.push({ field: "cust_branch_type", operator: "=", value: 'Consignee' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // const getUpdatedConsigneesData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                comboDataFunc.current.fillFiltersCombo(globalQuery)

                    .then(custOpts => {
                        const customerList = [
                            { value: '', label: 'Select', field_id: '', field_name: '' },
                            { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                            ...custOpts.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                        ];

                        setConsigneeList(customerList);

                    });
                // setConsigneeList(getUpdatedConsigneesData);
                break;

            case 'UpdateCustomers':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_gst_no", 'cust_branch_type']
                globalQuery.table = "cmv_customer_summary"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // const getUpdatedCustomerData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)

                comboDataFunc.current.fillFiltersCombo(globalQuery)

                    .then(custOpts => {
                        const customerList = [
                            { value: '', label: 'Select', field_id: '', field_name: '' },
                            { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                            ...custOpts.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                        ];

                        setCustomerList(customerList);

                    });
                // setCustomerList(getUpdatedCustomerData);
                break;

            case 'UpdateEmployees':

                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                globalQuery.table = "cmv_employee"
                // globalQuery.conditions.push({ field: "department_id", operator: "=", value: '2' });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // const supervisorsOpts = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                // setSupervisorList(supervisorsOpts)
                comboDataFunc.current.fillFiltersCombo(globalQuery)
                    .then(supervisorsOpts => {
                        const supervisorList = [
                            { value: '', label: 'Select', field_id: '', field_name: '' },
                            { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                            ...supervisorsOpts.map((supervisor) => ({ ...supervisor, value: supervisor.field_id, label: supervisor.field_name })),
                        ];

                        setSupervisorList(supervisorList);

                    });
                break;

            default:
                break;
        }

    }

    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Customer City':
                return <FrmCity btn_disabled={true} />;
            case 'Consignee City':
                return <FrmCity btn_disabled={true} />;
            case 'Dispatch Schedule Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            default:
                return null;
        }
    }

    const FnGetCustomersContactPersonsDetailsOld = async (selectedCustomer, dispatchType) => {
        try {
            if (selectedCustomer !== 0 && !isNaN(selectedCustomer)) {
                resetGlobalQuery();

                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id",
                    "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"];

                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(selectedCustomer) });
                const getContactDtlsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                if (getContactDtlsApiCall.length !== 0) {
                    let column = [];
                    let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                    for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                        if (colKey === 0) {
                            column.push({
                                Headers: <div className="col-1"><label>Action</label></div>,
                                accessor: "Action",
                                Cell: row => (
                                    <div className="text-center">
                                        <input type='checkbox' className="option selectCustContactPerson" name="selectCustContactPerson" id={`selectCustContactPerson_${row.original.customer_contact_id}`} contact_personId={row.original.customer_contact_id} value={row.original.customer_contact_id} disabled />
                                    </div>
                                ),
                            });
                        }
                        if (!columnHeads[colKey].includes('customer_id') && !columnHeads[colKey].includes('customer_contact_id') && !columnHeads[colKey].includes('cust_branch_id')) {
                            column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                        }
                    }
                    setCustContactDetailsCols(column);
                    setCustContactDetails(getContactDtlsApiCall);

                    if (dispatchType === 'S') {
                        resetGlobalQuery();
                        globalQuery.columns.push("customer_contacts_ids");
                        globalQuery.table = "mtv_sales_order_master_trading_summary"
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        const existingContactDetails = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                        if (existingContactDetails.length > 0) {
                            for (const details of existingContactDetails) {
                                const element = details.customer_contacts_ids;

                                if (element !== '') {
                                    const contactIdsArray = element.split(":");

                                    for (const contactId of contactIdsArray) {
                                        const selector = `#selectCustContactPerson_${contactId}`;
                                        $(selector).prop('checked', true);
                                    }
                                }
                            }

                        }
                    }

                } else {
                    setCustContactDetailsCols([]);
                    setCustContactDetails([]);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        $('.selectCustContactPerson').prop('checked', false);
        $('#selectAllCustContact').prop('checked', false);
        try {
            if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
                resetGlobalQuery();
                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id", "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"]
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                setCustContactDetails(getContactDtlsApiCall);

                // if (rb_dispatch_order_creation_type === 'S') {
                //     resetGlobalQuery();
                //     globalQuery.columns.push("customer_contacts_ids");
                //     globalQuery.table = "mtv_sales_order_master_trading_summary"
                //     globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerId });
                //     const customerContDetails = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

                //     if (customerContDetails.length > 0) {
                //         const customerContactPersonsIds = customerContDetails[0].customer_contacts_ids.split(":").map(Number);
                //         const rearrangedContactsRows = [
                //             ...getContactDtlsApiCall.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                //             ...getContactDtlsApiCall.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                //         ];
                //         setCustContactDetails(rearrangedContactsRows);
                //         setTimeout(() => {
                //             customerContactPersonsIds.forEach(function (contactId, index) {
                //                 $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
                //             });
                //         }, 600);
                //     }
                // } else {
                //     setCustContactDetails(getContactDtlsApiCall);
                // }

                return getContactDtlsApiCall;
            } else {
                setCustContactDetails([]);
                return [];
            }
        } catch (error) {
            console.log("error: ", error)
            // navigate('/Error')
        }
        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            FnCheckBoxesSelection('PartiallyCustContactSelection');
        });
    }

    const checkDispactDetails = (dispatchDetailsData, creation_type) => {
        // Partially select dispatch detals
        const checkboxes = document.querySelectorAll('.checkdispatchnoteDetails');
        const transactionIdsSet = new Set(dispatchDetailsData.map(item => creation_type === 'S' ? item.sales_order_details_transaction_id : item.sales_order_details_transaction_id));

        checkboxes.forEach(checkbox => {
            const transactionId = checkbox.value;
            if (transactionIdsSet.has(parseInt(transactionId))) {
                checkbox.checked = true;
            }
        });

        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        partiallySelectedDispatch();
        totalDispatchQuantity();
    }

    const selectAllCustomerOrderNo = async (key) => {
        const fliterCheckbox = document.getElementsByName('customerOrderNoCheckBox');
        if (key === 'check') {
            for (let chkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${chkAllCheckbox.id}`).checked = true;
            }
        } else {
            for (let unchkAllCheckbox of fliterCheckbox) {
                if (!unchkAllCheckbox.hasAttribute('disabled')) {
                    document.getElementById(`${unchkAllCheckbox.id}`).checked = false;
                }
            }
        }
    }

    const generateDispatchScheduleNo = async (product_typeShortName) => {
        const ptShortName = product_typeShortName === undefined ? $('#cmb_dispatch_challan_type option:selected').attr('shortname') : product_typeShortName;
        const productTypeId = parseInt($('#cmb_dispatch_challan_type').val());
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo(
            "mt_dispatch_challan_sized_yarn_master",
            "dispatch_challan_no",
            ptShortName,
            "DC",
            "5", "dispatch_challan_type_id", productTypeId
        );

        setDispatchChallanNo(autoNoApiCall);
        return autoNoApiCall;
    };


    const updateDispatchDetailsTblRow = async (rowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        delete event.target.parentElement.dataset.tip;

        let enteredValue = parseInt(event.target.value);
        if (isNaN(enteredValue)) {
            enteredValue = 0;
        }

        switch (clickedColName) {
            case 'expected_dispatch_quantity':
                if (rb_dispatch_order_creation_type === 'S') {
                    // if (enteredValue > (rowData.material_quantity - rowData.dispatched_quantity - rowData.prev_disp_note_qty)) {
                    if (enteredValue > (rowData.material_quantity - rowData.prev_disp_note_qty)) {
                        let actualvalidation = document.querySelector(`#${event.target.id}`);
                        // actualvalidation.parentElement.dataset.tip = `Cannot be greater than ${rowData.material_quantity - rowData.dispatched_quantity - rowData.prev_disp_note_qty}...!`;
                        // actualvalidation.parentElement.dataset.tip = `Cannot be greater than ${rowData.material_quantity - rowData.prev_disp_note_qty}...!`;
                        actualvalidation.parentElement.dataset.tip = `Cannot be greater than order quantity or stock quantity...!`;
                        return false;
                    } else {
                        rowData[clickedColName] = enteredValue;
                        rowData.expected_dispatch_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * rowData.product_material_std_weight), 4);
                        delete event.target.parentElement.dataset.tip;
                    }
                    $(`#checkdispatchnoteDetails_${rowData.sales_order_details_transaction_id}`).prop('checked', true);

                } else {
                    if (enteredValue > rowData.stock_quantity) {
                        let actualvalidation = document.querySelector(`#${event.target.id}`);
                        actualvalidation.parentElement.dataset.tip = `Cannot be greater than stock quantity...!`;
                        return false;
                    } else {
                        rowData[clickedColName] = enteredValue;
                        rowData.expected_dispatch_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * rowData.product_material_std_weight
                        ), 4);
                        delete event.target.parentElement.dataset.tip;
                    }
                }
                break;

            case 'expected_dispatch_weight':
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                break;

            case 'actual_dispatch_quantity':
                if (enteredValue > rowData.expected_dispatch_quantity) {
                    let actualvalidation = document.querySelector(`#${event.target.id}`);
                    actualvalidation.parentElement.dataset.tip = `Cannot be greater than ${rowData.expected_dispatch_quantity}...!`;
                    return false;
                } else {
                    rowData[clickedColName] = enteredValue;
                    rowData.actual_dispatch_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * rowData.product_material_std_weight), 4);
                    delete event.target.parentElement.dataset.tip;

                    // // Update the Packing Details.
                    if (requestfor === "YN") {
                        let updatedPkgDtls = [];
                        let otherPkgDtls = [];
                        let currentMatPkgDtls = [];
                        if (rb_dispatch_order_creation_type === 'S') {
                            currentMatPkgDtls = PackingDetailsList.filter(item => parseInt(item.sales_order_details_transaction_id) === parseInt(rowData.sales_order_details_transaction_id));
                            otherPkgDtls = PackingDetailsList.filter(item => parseInt(item.sales_order_details_transaction_id) !== parseInt(rowData.sales_order_details_transaction_id));
                        } else {
                            currentMatPkgDtls = PackingDetailsList.filter(item => item.product_material_id === rowData.product_material_id);
                            otherPkgDtls = PackingDetailsList.filter(item => item.product_material_id !== rowData.product_material_id);
                        }

                        if (currentMatPkgDtls.length > 0) {
                            let qtyToDisp = enteredValue;
                            currentMatPkgDtls.forEach(item => {
                                if (item.packing_quantity >= qtyToDisp) {
                                    item.item_dispatch_quantity = qtyToDisp;
                                    qtyToDisp = 0;
                                } else {
                                    item.item_dispatch_quantity = item.packing_quantity;
                                    qtyToDisp -= item.packing_quantity;
                                }
                            });
                        }
                        // updatedPkgDtls = [...otherPkgDtls, ...currentMatPkgDtls].sort((a, b) => a.product_material_id - b.product_material_id);
                        updatedPkgDtls = [...otherPkgDtls, ...currentMatPkgDtls]
                        setPackingDetailsList(updatedPkgDtls)
                    }


                }
                break;

            case 'actual_dispatch_weight':
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                break;

            case 'batch_no':
                rowData[clickedColName] = event.target.value;
                break;
            // case 'so_rate':
            //     rowData[clickedColName] = event.target.value;
            //     break;


            case 'dispatch_schedule_remark':
                rowData[clickedColName] = event.target.value;
                break;

            default:
                // Handle default case if needed
                break;
        }

        // if (isApprove) {
        //     if (clickedColName === 'dispatch_schedule_item_status') {
        //         rowData[clickedColName] = event.target.value;
        //     } else if (clickedColName === 'dispatch_schedule_remark') {
        //         rowData[clickedColName] = event.target.value;
        //     }
        // }
        const detailData = [...dispatchDetailsData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        detailData[arrayIndex] = rowData
        setDispatchDetailsData(detailData);
        if (!isApprove) {
            totalDispatchQuantity()
        }
    }

    const getMaterialValues = (material_id) => {
        const expectedQty = parseInt($(`#expected_dispatch_quantity_${material_id}`).val());
        const expectedWt = parseFloat($(`#expected_dispatch_weight_${material_id}`).val());
        const actualQtyValue = parseInt($(`#actual_dispatch_quantity_${material_id}`).val());
        const actualWtValue = parseFloat($(`#actual_dispatch_weight_${material_id}`).val());
        return { expectedQty, expectedWt, actualQtyValue, actualWtValue };
    };

    const totalDispatchQuantity = () => {
        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {
            let dispatch_qty = 0;
            let dispatch_wt = 0;
            let actualdispatch_qty = 0;
            let actualdispatch_wt = 0;

            const processMaterial = (material_id) => {
                const { expectedQty, expectedWt, actualQtyValue, actualWtValue } = getMaterialValues(material_id);

                if (actualQtyValue && !isNaN(actualQtyValue)) {
                    actualdispatch_qty += actualQtyValue;
                }

                if (actualWtValue && !isNaN(actualWtValue)) {
                    actualdispatch_wt += actualWtValue;
                }

                if (expectedQty && !isNaN(expectedQty)) {
                    dispatch_qty += expectedQty;
                }
                dispatch_wt += expectedWt;
            };

            if (document.querySelector('input[name="rb_dispatch_order_creation_type"]:checked').value === 'S') {
                $("input:checkbox[name=checkDispatch]:checked").each(function () {
                    // const material_id = parseInt($(this).val());
                    const material_id = $(this).val();
                    processMaterial(material_id);
                });
            } else {
                const table = document.getElementById('dispatchDetailsTbl');
                if (table) {
                    const tbody = table.querySelector('tbody');
                    const tbodyRows = tbody.getElementsByTagName('tr');
                    for (let i = 0; i < tbodyRows.length; i++) {
                        const row = tbodyRows[i];
                        // const material_id = parseInt(row.getAttribute('materialIds'));
                        const material_id = row.getAttribute('materialIds');
                        processMaterial(material_id);
                    }
                }
            }

            settxt_total_quantity(dispatch_qty);
            settxt_Total_Weight(validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatch_wt), 4));
            settxt_actual_quantity(actualdispatch_qty);
            settxt_actual_weight(validateNumberDateInput.current.decimalNumber(JSON.stringify(actualdispatch_wt), 4));
        }
    };

    const moveToListing = () => {
        let Path = compType === 'Transactions' ? '/Transactions/TDispatchNote/TSizedYarnDispatchNoteListing' : `/Transactions/TDispatchNote/TSizedYarnDispatchNoteListing`;
        navigate(Path);
    }


    async function ShowDispatchNoteDetails(storedDispatchDetailsData) {
        debugger
        try {
            debugger
            setIsLoading(true);
            // await FnCheckCustomerOrderNoApproval();
            //company id
            let json = { 'CustomerOrderNos': [], 'company_id': COMPANY_ID }
            $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                json.CustomerOrderNos.push($(this).val())
            })
            if (json.CustomerOrderNos.length > 0) {
                const formData = new FormData();
                formData.append('customerOrderNos', JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnGetSizedYarnBeams`, requestOptions)
                const response = await apicall.json()
                if (response.length !== 0) {
                    setDispatchDetailsData(response.sizedYarnBeams);
                    alert("response.sizedYarnBeams " + response.sizedYarnBeams);
                } else {
                    setDispatchDetailsData([]);
                }

                if (keyForViewUpdate === '') {
                    // Load the customer's contacts.
                    resetGlobalQuery();
                    // SELECT customer_contacts_ids  from mtv_sales_order_master_trading_summary msomts WHERE customer_order_no = 'CustomerOrd-0057' and is_delete = 0;
                    globalQuery.columns = ['customer_contacts_ids'];
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    //xt_sizing_production_stock_details table

                    // globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: json.cust_order_nos[0] });
                    globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: json.cust_order_nos[0] });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: txt_customer });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                    const getCustContactPersons = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    if (getCustContactPersons.length > 0) {
                        const customerContactPersonsIds = getCustContactPersons[0].customer_contacts_ids.split(":").map(Number);
                        // Rearrange the rows.
                        if (custContactDetails.length > 0) {
                            const rearrangedContactsRows = [
                                ...custContactDetails.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                                ...custContactDetails.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                            ];
                            setCustContactDetails(rearrangedContactsRows);
                        }
                        customerContactPersonsIds.forEach(function (contactId, index) {
                            $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
                        });
                    }


                }
            } else {
                setDispatchDetailsData([]);
                setProductTypePropertiesData([]);
                setPackingDetailsList([]);
            }

        } catch (error) {
            console.log('Error in loading so data: ', error)

        } finally {
            setIsLoading(false);
        }
    }

    const FnUpdateStockAvailability = async (materialDetailsData, setInTable) => {
        let uniqueMaterialIds = [...new Set(materialDetailsData.map(material => material.product_material_id))];

        // Get the available stock qty.
        resetGlobalQuery();
        globalQuery.columns = ["product_material_id", "closing_balance_quantity", 'closing_balance_weight']
        globalQuery.table = "smv_product_stock"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        globalQuery.conditions.push({ field: "product_material_id", operator: "IN", values: [...new Set(uniqueMaterialIds)] });
        let getAvlStockQty = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        // get the available stock-qty and set in details.
        if (setInTable) {
            let materialDetailsUpdatedData = materialDetailsData.map(material => {
                const stock = getAvlStockQty.find(stock => stock.product_material_id === material.product_material_id);
                return stock ? { ...material, available_stock_qty: stock.closing_balance_quantity } : material;
            });
            setDispatchDetailsData(materialDetailsUpdatedData);
            return materialDetailsUpdatedData;

        } else {
            return getAvlStockQty;
        }
    }

    // Hide show customer order no's
    const showContsCheckboxes = () => {
        var checkboxes = document.getElementById("customer-order-ul");
        const items = document.querySelectorAll(".cust_order_no");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }

    function toggleDispatchDetailsChkBoxes() {
        const selectAll = document.getElementById('checkdispatchnoteDetails').checked
        if (selectAll) {
            const dispatchChkBoxes = document.querySelectorAll('input[name=checkDispatch]');
            for (let checkbox of dispatchChkBoxes) {
                checkbox.checked = true;
            }
        } else {
            const uncheckDispatchChkBoxes = document.querySelectorAll('input[name=checkDispatch]:checked');
            for (let checkbox of uncheckDispatchChkBoxes) {
                let dispDtlId = parseInt(checkbox.getAttribute('dispDtlId'));
                if (dispDtlId === 0) {
                    checkbox.checked = false;
                }
            }
        }

        totalDispatchQuantity()
    }

    const partiallySelectedDispatch = () => {
        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=checkDispatch]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=checkDispatch]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('checkdispatchnoteDetails').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('checkdispatchnoteDetails').checked = false;
        }
    }

    const checkCustomerOrderNo = async (customer_order_no) => {
        setIsLoading(true);
        if (parseInt(customer_order_no) === 0) {
            if ($("#all_cust_order_no_id").is(":checked") === false) {
                await selectAllCustomerOrderNo('uncheck');
            } else {
                await selectAllCustomerOrderNo('check');
            }
        }

        await FnCheckCustomerOrderNoApproval()

        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=customerOrderNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=customerOrderNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_cust_order_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_cust_order_no_id').checked = false;
        }

        setCheckedCustomerOrderNoLength(totalChkBoxesChked)
        setIsLoading(false);
    }

    const FnCheckCustomerOrderNoApproval = async () => {

        try {
            let customer_order_nos = []
            $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                customer_order_nos.push($(this).val())
            })
            resetGlobalQuery();
            globalQuery.columns = ["DISTINCT (dispatch_challan_no)", "customer_order_no", "dispatch_note_status"]
            globalQuery.table = "mtv_dispatch_schedule_details_trading"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: customer_order_nos });
            const getScheduleNosForCheckApproval = await comboDataFunc.current.fillFiltersCombo(globalQuery);

            let nonApprovedOrderNos = [];
            // Check if all customer_order_nos are present in getScheduleNosForCheckApproval
            const allCustomerOrderNosPresent = customer_order_nos.map(orderNo => {
                const isPresent = getScheduleNosForCheckApproval.some(schedule => schedule.customer_order_no === orderNo && schedule.dispatch_note_status === 'A')
                if (isPresent) {
                    document.getElementById(`filterCheck-${orderNo}`).checked = false;
                    nonApprovedOrderNos.push(orderNo)
                }
            });

            let custOrderNos = nonApprovedOrderNos + ""
            if (custOrderNos.length !== 0) {
                setInfoMsg("To create new Dispatch Challan, Please Dispatch the approved Dispatch Challan for the following customer order numbers: " + custOrderNos)
                setShowInfoMsgModal(true)
            }


        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const FnValidateDispatchChallan = async () => {
        debugger
        let isDataValid = true;
        //validate
        const selectedCheckBoxes = document.querySelectorAll("input[name='checkDispatch']:checked");
        const selectedBeamIds = Array.from(selectedCheckBoxes).map((checkbox) => parseInt(checkbox.value, 10)); // Ensure proper parsing
        if (keyForViewUpdate === "") {
            if (selectedBeamIds.length === 0) { // Check if no materials are selected
                setErrMsg('Please add at least one material!');
                setShowErrorMsgModal(true);
                isDataValid = false;
            }
        }
        if (keyForViewUpdate === "approve" && cmb_dispatch_by_id === "") {
            setErrMsg('Please select Approved By!');
            setShowErrorMsgModal(true);
            isDataValid = false;
        }
        return isDataValid;

    }

    const createDispatchNote = async (dispatchStatus) => {
        //SAVE
        try {
            setIsLoading(true);
            let dispatchChallanIsValid = await FnValidateDispatchChallan();
            let latestDispatchNo = txt_dispatch_challan_no;
            // if (!isApprove) {
            // checkIsValidate = await FnValidateDispatchNote();
            // }
            if (dispatchChallanIsValid) {
                let json = { 'DispatchYarnMasterData': {}, 'DispatchYarnDetailsData': [], 'commonIds': {} }
                console.log(dispatchDetailsData)

                const masterData = {
                    dispatch_challan_sized_yarn_id: dispatch_challan_sized_yarn_id,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_challan_type_id: cmb_dispatch_challan_type,
                    dispatch_challan_type: dispatchScheduleTypeList.find(
                        (data) => parseInt(data.field_id) === parseInt(cmb_dispatch_challan_type)
                    )?.field_name,
                    dispatch_challan_no: latestDispatchNo,
                    dispatch_challan_version: parseInt(dispatch_challan_version),
                    dispatch_challan_date: txt_dispatch_date,
                    dispatch_challan_status: keyForViewUpdate === "approve" ? "D" : "P",
                    customer_order_no: txt_customer_order_no,
                    customer_id: txt_customer_id,
                    customer_contacts_ids: txt_customer_contacts_ids,
                    customer_state_id: sizedYarnMasterData.customer_state_id,
                    customer_city_id: sizedYarnMasterData.customer_city_id,
                    consignee_id: cmb_consignee_id,
                    consignee_city_id: cmb_consignee_city_id,
                    consignee_state_id: cmb_consignee_state_id,
                    yarn_count: txt_yarn_count,
                    total_ends: sizedYarnMasterData.total_ends,
                    rate: txt_rate,
                    weight: txt_weight,
                    agent_id: sizedYarnMasterData.agent_id,
                    set_no: cmb_set_no,
                    driver_name: txt_driver_name,
                    other_terms_conditions: txt_other_terms_conditions,
                    product_material_id: sizedYarnMasterData.product_material_id,
                    product_material_name: sizedYarnMasterData.product_material_name,
                    remark: txt_remark,
                    approved_by_id: keyForViewUpdate === "approve" ? cmb_dispatch_by_id : "",
                    total_ends: dispatchDetailsData[0].total_ends,
                    yarn_bill_no: txt_yarn_bill_no,
                    vehicle_no: txt_vehicle_no,
                    created_by: UserName,
                    modified_by: UserName,
                    ...(keyForViewUpdate === "approve" && {
                        dispatch_payment_terms : sizedYarnMasterData.dispatch_payment_terms,
                        approved_date: dt_approve_date,
                        dispatch_date: dt_approve_date,
                    }),
                };

                json.DispatchYarnMasterData = masterData;
                console.log("MasterData", masterData)
                let dispatchDetails = [];

                if (keyForViewUpdate === '') {
                    const selectedCheckBoxes = document.querySelectorAll("input[name='checkDispatch']:checked");

                    // Extract values (or beam_id) from selected checkboxes
                    const selectedBeamIds = Array.from(selectedCheckBoxes).map((checkbox) => parseInt(checkbox.value));

                    // Assuming `dispatchDetailsData` contains all possible details
                    dispatchDetails = dispatchDetailsData.filter((details) =>
                        selectedBeamIds.includes(parseInt(details['beam_id']))
                    );
                } else {
                    dispatchDetails = dispatchDetailsData;
                }


                dispatchDetails.forEach(prop => {
                    const detailsObj = {
                        dispatch_challan_sized_yarn_id: dispatch_challan_sized_yarn_id,
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        dispatch_challan_type_id: cmb_dispatch_challan_type,
                        dispatch_challan_type: dispatchScheduleTypeList.find(
                            (data) => parseInt(data.field_id) === parseInt(cmb_dispatch_challan_type)
                        )?.field_name,

                        dispatch_challan_no: latestDispatchNo,
                        dispatch_challan_sized_yarn_details_id: keyForViewUpdate === 'approve' ? prop.dispatch_challan_sized_yarn_details_id : 0,
                        dispatch_challan_version: parseInt(dispatch_challan_version),
                        dispatch_challan_date: txt_dispatch_date,
                        dispatch_challan_status: keyForViewUpdate === "approve" ? "D" : "P",
                        beam_inward_type: prop.beam_inward_type,
                        customer_order_no: txt_customer_order_no,
                        customer_id: txt_customer_id,
                        customer_name: txt_customer,
                        financial_year: FINANCIAL_SHORT_NAME,
                        gross_weight: prop.gross_weight,
                        net_weight: prop.net_weight,
                        product_material_name: prop.product_material_name,
                        remark: txt_remark,
                        set_no: cmb_set_no,
                        beam_id: prop.beam_id,
                        sizing_length: prop.sizing_length,
                        sizing_production_code: prop.sizing_production_code,
                        sizing_production_date: prop.sizing_production_date.split("T")[0],
                        tare_weight: prop.tare_weight,
                        total_ends: prop.total_ends,
                        approved_by_id: keyForViewUpdate === "approve" ? cmb_dispatch_by_id : "",
                        created_by: UserName,
                        modified_by: UserName,
                        ...(keyForViewUpdate === "approve" && {
                            approved_date: dt_approve_date,
                            dispatch_date: dt_approve_date,
                        }),
                    };

                    json.DispatchYarnDetailsData.push(detailsObj)
                    console.log("DispatchYarnDetailsData", detailsObj)
                })

                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.keyviewupdate = keyForViewUpdate === 'approve' ? 'approve' : 'Add';
                json.commonIds.dispatch_challan_sized_yarn_id = dispatch_challan_sized_yarn_id
                // json.commonIds.dispatch_schedule_date = txt_dispatch_date
                // json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log("JSON", json)
                const formData = new FormData();
                formData.append(`DispatchSizingYarnData`, JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DispatchSizedYarn/FnAddUpdateRecord`, requestOptions)
                const response = await apicall.json();

                if (response.success === "0") {
                    setIsLoading(false);
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataFunc.current.evitCache();
                    console.log(evitCache);
                    // setdispatchscheduledetailstransactionId(response.data.dispatch_challan_sized_yarn_id)
                    setSuccMsg(response.message)
                    // if (response.data.hasOwnProperty('dispatch_challan_no')) {
                    setModalOrderDetails(`Dispatch note No: ${latestDispatchNo}`)
                    //     setDispatchChallanNo(response.data.dispatch_challan_no);
                    //     setDispatchVersion(response.data.dispatch_challan_version);
                    // }
                    setShowConfirmationModal(true);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }

    const FnProcessBomfilteredDataElement = (bomfilteredDataElement, latestDispatchNo, dispatchStatus) => {
        let crJson = {};

        crJson['dispatch_schedule_details_transaction_id'] = (dispatch_challan_sized_yarn_id !== 0) ? (bomfilteredDataElement.dispatch_schedule_details_transaction_id || 0) : 0;
        crJson['company_id'] = COMPANY_ID;
        crJson['company_branch_id'] = COMPANY_BRANCH_ID;
        crJson['financial_year'] = FINANCIAL_SHORT_NAME;
        crJson['customer_order_Date'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.customer_order_Date : null;
        crJson['dispatch_schedule_type_id'] = cmb_dispatch_challan_type;
        crJson['sales_order_details_transaction_id'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.sales_order_details_transaction_id : 0;
        crJson['dispatch_challan_type'] = $('#cmb_dispatch_challan_type').find(":selected").text();
        crJson['dispatch_schedule_creation_type'] = rb_dispatch_order_creation_type;
        crJson['dispatch_schedule_date'] = txt_dispatch_date;
        crJson['customer_order_no'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.customer_order_no : null;
        crJson['sales_order_no'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.sales_order_no : null;
        crJson['dispatch_challan_no'] = (dispatch_challan_sized_yarn_id === 0) ? latestDispatchNo : txt_dispatch_challan_no;
        crJson['product_material_unit_id'] = bomfilteredDataElement.product_material_unit_id;
        crJson['product_material_packing_id'] = bomfilteredDataElement.product_material_packing_id;
        crJson['sr_no'] = bomfilteredDataElement.sr_no;
        crJson['product_material_print_name'] = bomfilteredDataElement.product_material_print_name;
        crJson['product_material_tech_spect'] = bomfilteredDataElement.product_material_tech_spect;
        crJson['so_sr_no'] = bomfilteredDataElement.so_sr_no;
        crJson['product_material_id'] = bomfilteredDataElement.product_material_id;
        crJson['expected_dispatch_quantity'] = bomfilteredDataElement.expected_dispatch_quantity;
        crJson['expected_schedule_date'] = bomfilteredDataElement.expected_schedule_date;
        crJson['dispatch_schedule_remark'] = bomfilteredDataElement.dispatch_schedule_remark;
        crJson.actual_dispatch_quantity = bomfilteredDataElement.actual_dispatch_quantity;
        crJson.actual_dispatch_weight = bomfilteredDataElement.actual_dispatch_weight;


        // if ((crJson.actual_dispatch_quantity === '' || crJson.actual_dispatch_quantity === '0' || crJson.actual_dispatch_quantity === undefined) && (dispatchStatus === 'A' || dispatchStatus === 'R')) {
        //     crJson.actual_dispatch_quantity = Math.max(bomfilteredDataElement.stock_quantity, bomfilteredDataElement.expected_dispatch_quantity);
        // } else {
        //     crJson.actual_dispatch_quantity = bomfilteredDataElement.actual_dispatch_quantity;
        // }

        // if ((crJson.actual_dispatch_weight === '' || crJson.actual_dispatch_weight === '0' || crJson.actual_dispatch_weight === undefined) && (dispatchStatus === 'A' || dispatchStatus === 'R')) {
        //     crJson.actual_dispatch_weight = Math.max(bomfilteredDataElement.stock_weight, bomfilteredDataElement.expected_dispatch_weight);
        // } else {
        //     crJson.actual_dispatch_weight = bomfilteredDataElement.actual_dispatch_weight;
        // }

        crJson['expected_dispatch_weight'] = bomfilteredDataElement.expected_dispatch_weight;
        crJson['dispatched_quantity'] = 0;
        crJson['dispatched_weight'] = 0;

        // crJson['dispatched_quantity'] = bomfilteredDataElement.prev_disp_note_qty;
        // crJson['dispatched_weight'] = bomfilteredDataElement.prev_disp_note_wt;  

        crJson['dispatch_schedule_item_status'] = 'A';
        // Below Code Commented because Prashant Sir told there is no required the approval.
        // crJson['dispatch_schedule_item_status'] = (
        //     (bomfilteredDataElement.dispatch_schedule_item_status === 'P' || bomfilteredDataElement.dispatch_schedule_item_status === null) &&
        //     isApprove
        // ) ? dispatchStatus : bomfilteredDataElement.dispatch_schedule_item_st    atus;

        crJson['dispatch_return_weight'] = 0;
        crJson['batch_no'] = bomfilteredDataElement.batch_no;
        crJson['pending_quantity'] = 0;
        crJson['pending_weight'] = 0;
        // crJson['delayed_days'] = 0;
        crJson['delayed_days'] = bomfilteredDataElement.delayed_days;
        crJson['invoice_quantity'] = 0;
        crJson['invoice_weight'] = 0;
        crJson.created_by = UserName;
        crJson.modified_by = dispatch_challan_sized_yarn_id !== 0 ? UserName : null
        return crJson;
    };


    const clearFormFields = () => {
        sessionStorage.removeItem('dispatch_note_no')
    }
    const validateFields = () => {
        validate.current.validateFieldsOnChange('dispatchnoteId')
    }

    const ExportToExcel = () => {
        if (rb_dispatch_order_creation_type === 'S') {
            const checkedCount = $("input:checkbox[name=checkDispatch]:checked").length;
            if (parseInt(checkedCount) !== 0) {
                const checkedValues = $("input:checkbox[name=customerOrderNoCheckBox]:checked").map(function () {
                    return $(this).val();
                }).get();

                const resultString = checkedValues.join(', ');
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }
                jsonToExportExcel.filtrKeyValue['0'] = "Dispatch Schedule Type" + ' : ' + $('#cmb_dispatch_challan_type').find(":selected").text() + "(" + cmb_dispatch_challan_type + ")";
                jsonToExportExcel.filtrKeyValue['1'] = "Creation type : " + $("input[name='rb_dispatch_order_creation_type']:checked").attr("lbl") + "(" + rb_dispatch_order_creation_type + ")";
                jsonToExportExcel.filtrKeyValue['2'] = "Dispatch Schedule No" + ' : ' + txt_dispatch_challan_no;
                jsonToExportExcel.filtrKeyValue['3'] = "Dispatch Version" + ' : ' + dispatch_challan_version;
                jsonToExportExcel.filtrKeyValue['4'] = "Customer" + ' : ' + $('#txt_customer').find(":selected").text() + "(" + txt_customer + ")";
                jsonToExportExcel.filtrKeyValue['5'] = "Customer Order Nos." + ' : ' + '(' + resultString + ')';
                jsonToExportExcel.filtrKeyValue['6'] = "Customer State" + ' : ' + $('#cmb_customer_state_id').find(":selected").text() + "(" + cmb_customer_state_id + ")";
                jsonToExportExcel.filtrKeyValue['7'] = "Customer City" + ' : ' + $('#cmb_customer_city_id').find(":selected").text() + "(" + cmb_customer_city_id + ")";
                jsonToExportExcel.filtrKeyValue['8'] = "Consignee : " + (cmb_consignee_id === '0' || cmb_consignee_id === '' ? '' : $('#cmb_consignee_id').find(":selected").text() + "(" + cmb_consignee_id + ")")
                jsonToExportExcel.filtrKeyValue['9'] = "Consignee State : " + (cmb_consignee_state_id === '0' || cmb_consignee_state_id === '' ? '' : $('#cmb_consignee_state_id').find(":selected").text() + "(" + cmb_consignee_state_id + ")")
                jsonToExportExcel.filtrKeyValue['10'] = "Consignee City : " + (cmb_consignee_city_id === '0' || cmb_consignee_city_id === '' ? '' : $('#cmb_consignee_city_id').find(":selected").text() + "(" + cmb_consignee_city_id + ")")
                jsonToExportExcel.filtrKeyValue['11'] = "Dispatch Date" + ' : ' + txt_dispatch_date;
                jsonToExportExcel.filtrKeyValue['12'] = "Supervisor : " + (cmb_dispatch_by_id === '0' || cmb_dispatch_by_id === '' ? '' : $('#cmb_dispatch_by_id').find(":selected").text() + "(" + cmb_dispatch_by_id + ")")
                jsonToExportExcel.filtrKeyValue['13'] = "Status" + ' : ' + $('#cmb_dispatch_note_status').find(":selected").text() + "(" + cmb_dispatch_note_status + ")";
                jsonToExportExcel.filtrKeyValue['14'] = "Dispatch Note Remark" + ' : ' + txt_note_Remark;
                jsonToExportExcel.filtrKeyValue['15'] = "Other Terms & Conditions" + ' : ' + txt_other_terms_conditions;
                jsonToExportExcel.filtrKeyValue['16'] = "Remark" + ' : ' + txt_remark;
                jsonToExportExcel.filtrKeyValue['17'] = "Active" + ' : ' + rb_is_active;
                jsonToExportExcel.filtrKeyValue['18'] = "Total Qty." + ' : ' + txt_total_quantity;
                jsonToExportExcel.filtrKeyValue['19'] = "Total Weight" + ' : ' + txt_total_weight;
                jsonToExportExcel.filtrKeyValue['20'] = "Actual Qty." + ' : ' + txt_actual_quantity;
                jsonToExportExcel.filtrKeyValue['21'] = "Actual Weight" + ' : ' + txt_actual_weight;

                const editableColumnNames = ["expected_dispatch_quantity", "expected_dispatch_weight", "actual_dispatch_quantity", "actual_dispatch_weight"];
                for (let colIndex = 0; colIndex < colNames.length; colIndex++) {
                    jsonToExportExcel.columns.push({ "Headers": colNames[colIndex], "accessor": colNames[colIndex] })
                    if (editableColumnNames.includes(colNames[colIndex])) {
                        jsonToExportExcel.editable_cols.push(colIndex);
                    }
                }

                $("input:checkbox[name=checkDispatch]:checked").each(function (index, item) {
                    let material = dispatchDetailsData.find(element => element.sales_order_details_transaction_id === parseInt(item.value));
                    material.dispatched_quantity = material.prev_disp_note_qty;
                    material.dispatched_weight = material.prev_disp_note_wt;
                    material.sr_no = index + 1;
                    jsonToExportExcel['allData'][index] = material;
                })
                jsonToExportExcel['headings']['ReportName'] = "Dispatch Challan Sized Yarn Details"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
                exlsExp.current.excel(jsonToExportExcel, txt_dispatch_challan_no + "@" + COMPANY_ID)
            } else {
                setErrMsg('Please select at least one material to dispatch!... ')
                setShowErrorMsgModal(true)
            }
        } else if (rb_dispatch_order_creation_type === 'M') {
            let requiredCols = ['sr_no', 'product_material_name', 'product_material_tech_spect', 'batch_no', 'product_material_std_weight',
                "stock_quantity", "stock_weight", "expected_dispatch_quantity", "expected_dispatch_weight",
                "actual_dispatch_quantity", "actual_dispatch_weight", "product_our_part_code",
                "dispatch_return_quantity", "dispatch_return_weight", "pending_quantity", "pending_weight",
                "delayed_days", "invoice_quantity", "invoice_weight", 'product_material_id', 'product_material_unit_id', 'product_material_packing_id']

            if (dispatchDetailsData.length > 0) {
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }
                jsonToExportExcel.filtrKeyValue['0'] = "Dispatch Schedule Type" + ' : ' + $('#cmb_dispatch_challan_type').find(":selected").text() + "(" + cmb_dispatch_challan_type + ")";
                jsonToExportExcel.filtrKeyValue['1'] = "Creation type : " + $("input[name='rb_dispatch_order_creation_type']:checked").attr("lbl") + "(" + rb_dispatch_order_creation_type + ")";
                jsonToExportExcel.filtrKeyValue['2'] = "Dispatch Schedule No" + ' : ' + txt_dispatch_challan_no;
                jsonToExportExcel.filtrKeyValue['3'] = "Dispatch Version" + ' : ' + dispatch_challan_version;
                jsonToExportExcel.filtrKeyValue['4'] = "Customer" + ' : ' + $('#txt_customer').find(":selected").text() + "(" + txt_customer + ")";
                jsonToExportExcel.filtrKeyValue['5'] = "Customer State" + ' : ' + $('#cmb_customer_state_id').find(":selected").text() + "(" + cmb_customer_state_id + ")";
                jsonToExportExcel.filtrKeyValue['6'] = "Customer City" + ' : ' + $('#cmb_customer_city_id').find(":selected").text() + "(" + cmb_customer_city_id + ")";
                jsonToExportExcel.filtrKeyValue['7'] = "Consignee : " + (cmb_consignee_id === '0' || cmb_consignee_id === '' ? '' : $('#cmb_consignee_id').find(":selected").text() + "(" + cmb_consignee_id + ")")
                jsonToExportExcel.filtrKeyValue['8'] = "Consignee State : " + (cmb_consignee_state_id === '0' || cmb_consignee_state_id === '' ? '' : $('#cmb_consignee_state_id').find(":selected").text() + "(" + cmb_consignee_state_id + ")")
                jsonToExportExcel.filtrKeyValue['9'] = "Consignee City : " + (cmb_consignee_city_id === '0' || cmb_consignee_city_id === '' ? '' : $('#cmb_consignee_city_id').find(":selected").text() + "(" + cmb_consignee_city_id + ")")
                jsonToExportExcel.filtrKeyValue['10'] = "Dispatch Date" + ' : ' + txt_dispatch_date;
                jsonToExportExcel.filtrKeyValue['11'] = "Supervisor : " + (cmb_dispatch_by_id === '0' || cmb_dispatch_by_id === '' ? '' : $('#cmb_dispatch_by_id').find(":selected").text() + "(" + cmb_dispatch_by_id + ")")
                jsonToExportExcel.filtrKeyValue['12'] = "Status" + ' : ' + $('#cmb_dispatch_note_status').find(":selected").text() + "(" + cmb_dispatch_note_status + ")";
                jsonToExportExcel.filtrKeyValue['13'] = "Dispatch Note Remark" + ' : ' + txt_note_Remark;
                jsonToExportExcel.filtrKeyValue['14'] = "Other Terms & Conditions" + ' : ' + txt_other_terms_conditions;
                jsonToExportExcel.filtrKeyValue['15'] = "Remark" + ' : ' + txt_remark;
                jsonToExportExcel.filtrKeyValue['16'] = "Active" + ' : ' + rb_is_active;
                jsonToExportExcel.filtrKeyValue['17'] = "Total Qty." + ' : ' + txt_total_quantity;
                jsonToExportExcel.filtrKeyValue['18'] = "Total Weight" + ' : ' + txt_total_weight;
                jsonToExportExcel.filtrKeyValue['19'] = "Actual Qty." + ' : ' + txt_actual_quantity;
                jsonToExportExcel.filtrKeyValue['20'] = "Actual Weight" + ' : ' + txt_actual_weight;

                const editableColumnNames = ["expected_dispatch_quantity", "expected_dispatch_weight", "actual_dispatch_quantity", "actual_dispatch_weight"];
                for (let colIndex = 0; colIndex < requiredCols.length; colIndex++) {
                    jsonToExportExcel.columns.push({ "Headers": requiredCols[colIndex], "accessor": requiredCols[colIndex] })
                    if (editableColumnNames.includes(requiredCols[colIndex])) {
                        jsonToExportExcel.editable_cols.push(colIndex);
                    }
                }

                // set table data.   // Create a new object with the updated key name
                dispatchDetailsData.map((dispatchDetail, Index) => {
                    dispatchDetail.sr_no = Index + 1;
                    jsonToExportExcel['allData'][Index] = dispatchDetail;
                });

                jsonToExportExcel['headings']['ReportName'] = "Dispatch Challan Sized Yarn Details"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
                exlsExp.current.excel(jsonToExportExcel, txt_dispatch_challan_no + "@" + COMPANY_ID)
            } else {
                setErrMsg('Please add at least one material to dispatch!... ')
                setShowErrorMsgModal(true)
            }
        }

    }

    //checkbox data functionality

    const checkedDispatchDetails = (event) => {
        let checkedBeamsNos = [];

        const selectedCheckBoxes = document.querySelectorAll("input[name='checkDispatch']:checked");

        selectedCheckBoxes.forEach((checkbox) => {
            checkedBeamsNos.push(checkbox.value);
        });

        const checkedArray = dispatchDetailsData.filter((row) =>
            checkedBeamsNos.includes(row.beam_id)
        );

        console.log(checkedArray);

        setCheckedDetailsDataForTotal(checkedArray)
    };


    const removeMaterial = (materialId) => {
        if (!isApprove && keyForViewUpdate !== 'view') {
            const filterdedData = dispatchDetailsData.filter(item => item.product_material_id !== materialId);
            setDispatchDetailsData(filterdedData)
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(filterdedData));

            if (PackingDetailsList.length > 0) {
                const updatedPackingDetailsList = PackingDetailsList.filter(item => item.product_material_id !== materialId);
                setPackingDetailsList(updatedPackingDetailsList);
            }
        }
    }

    //Fn to render Details Table
    const renderDispatchNoteTable = useMemo(() => {
        return <>
            {
                dispatchDetailsData.length > 0
                    ? <Table className="erp_table" id='dispatchDetailsTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                {<th className={`erp_table_th hide_it`} style={{ width: "60px" }} >Action</th>}
                                <th className={`erp_table_th`} style={{ width: "60px" }} >Sr. No.</th>
                                <th className="erp_table_th" >Beam No</th>
                                <th className="erp_table_th" >Length</th>
                                <th className="erp_table_th" >Gross Weight</th>
                                <th className="erp_table_th" >Tare Weight</th>
                                <th className="erp_table_th" >Net Weight</th>
                                <th className="erp_table_th" >Total Ends</th>
                                <th className="erp_table_th" >Sizing Production Code</th>
                                <th className="erp_table_th" >Customer Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dispatchDetailsData?.map((dispatchItem, Index) =>
                                    <tr rowIndex={Index} hsnId={dispatchItem.hsn_code_id}>
                                        <td className="erp_table_td hide_it">
                                            <input
                                                disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                type="checkbox"
                                                className="checkdispatchnoteDetails"
                                                onChange={checkedDispatchDetails}
                                                name="checkDispatch"
                                                id={`checkdispatchnoteDetails_${dispatchItem.beam_id}`}
                                                value={dispatchItem.beam_id}
                                                label={dispatchItem.customer_order_no}

                                            />
                                        </td>


                                        <td className="erp_table_td">{Index + 1}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.beam_inward_type}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.sizing_length}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.gross_weight}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.tare_weight}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.net_weight}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.total_ends}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.sizing_production_code}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.customer_name}</td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td disabled={['view', 'approve'].includes(keyForViewUpdate)} className="erp_table_td hide_it"></td>
                                <td className="erp_table_td"><b>Total</b></td>
                                <td className={`erp_table_td `}></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.sizing_length || 0), 0)}</b></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.gross_weight || 0), 0)}</b></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.tare_weight || 0), 0)}</b></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.net_weight || 0), 0)}</b></td>
                                <td className={`erp_table_td `}></td>
                                <td className={`erp_table_td `}></td>
                                <td className={`erp_table_td `}></td>
                            </tr>
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [dispatchDetailsData, checkedDetailsDataForTotal]);


    // ********************************************* Dispatch Schedule Document Upload Functionality Starts. *****************************************************
    function FnCheckBoxesSelection(key) {
        switch (key) {
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'selectAllPackingRecords':
                $('.selectInspectionRecord').prop('checked', $('#selectAllPackingRecords').is(":checked"));
                break;
            case 'PartiallyselectPackingRecord':
                $('#selectAllPackingRecords').prop('checked', $('input:checkbox.selectInspectionRecord:checked').length === $('input:checkbox.selectInspectionRecord').length);
                break;
            default:
                break;
        }
    }

    const excludedcustFields = ['is_delete', 'is_active', 'created_on', 'created_by', 'modified_on', 'modified_by', 'deleted_by',
        'deleted_on', 'document_id', 'company_id'];

    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_dispatch_challan_no !== 0 ? txt_dispatch_challan_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setUploadedDocData(resp.content);
                } else {
                    setUploadedDocData([]);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='document-table' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        uploadedDocData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">{docItem.document_registration_no}</td>
                                <td className="erp_table_td">{docItem.document_registration_date}</td>
                                <td className="erp_table_td">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td">{docItem.document_path}</td>
                                <td className="erp_table_td">{docItem.remark}</td>
                                <td className="erp_table_td">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}> {docItem.file_name} </Link>
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [uploadedDocData]);

    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // ********************************************* Dispatch Schedule Document Upload Functionality Ends. *****************************************************

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnDeleteRecord/${idList.dispatch_challan_version}/${COMPANY_ID}/${UserName}?dispatch_challan_no=${idList.dispatch_challan_no}`, method)
            const responce = await deleteApiCall.json();
            if (responce.success == '1') {
                setShowDeleteModal(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------


    // Invoice Printing 
    const sizedYarnDispatchContent = {
        company: {
            company: '',
            company_branch: '',
            company_cell_no: '',
            company_emailId: '',
            company_website: '',
            company_gst_no: '',
            company_branch_no: '',
            company_address: '',
            company_pincode: '',
        },

        dispatchDetails: {
            dispatch_challan_no: '',
            dispatch_schedule_date: '',
            dispatch_challan_version: '',
            dispatch_schedule_creation_type_desc: '',
            dispatch_challan_type: '',
        },

        agentDetails: {
            agent_name: '',
            agent_address1: '',
            agent_cell_no: ''
        },

        customerDetails: {
            customer: '',
            address: '',
            state: '',
            contact: '',
            customer_email: '',
            customer_city: ''
        },

        consigneeDetails: {
            consignee_name: '',
            address: '',
            state: '',
            contact: '',
            consignee_email: '',
            consignee_city_name: ''
        },


        footer: {

        },

        sizedYarnDetailsData: []


    };

    const printInvoice = async (openPrintModal) => {
        debugger

        setIsLoading(true);

        let MasterData = sizedYarnMasterData;
        let DetailsData = dispatchDetailsData;

        // Company Deatils
        sizedYarnDispatchContent.company.company = MasterData.company_name
        sizedYarnDispatchContent.company.company_branch = MasterData.company_branch_name
        sizedYarnDispatchContent.company.company_cell_no = MasterData.company_cell_no
        sizedYarnDispatchContent.company.company_emailId = MasterData.company_emailId
        sizedYarnDispatchContent.company.company_website = MasterData.company_website
        sizedYarnDispatchContent.company.company_gst_no = MasterData.company_gst_no
        sizedYarnDispatchContent.company.company_pan_no = MasterData.company_pan_no
        sizedYarnDispatchContent.company.company_address = MasterData.company_address
        sizedYarnDispatchContent.company.company_pincode = MasterData.company_pincode

        //Agent Details
        sizedYarnDispatchContent.agentDetails.agent_name = MasterData.agent_name;
        sizedYarnDispatchContent.agentDetails.agent_address1 = MasterData.agent_address1;

        //Customer Deatils
        sizedYarnDispatchContent.customerDetails.customer = MasterData.customer_name
        sizedYarnDispatchContent.customerDetails.address = MasterData.cust_branch_address1
        sizedYarnDispatchContent.customerDetails.state = MasterData.customer_state_name
        sizedYarnDispatchContent.customerDetails.contact = MasterData.customer_phone
        sizedYarnDispatchContent.customerDetails.customer_email = MasterData.customer_email
        sizedYarnDispatchContent.customerDetails.customer_city = MasterData.customer_city_name

        //Consignee Deatils
        sizedYarnDispatchContent.consigneeDetails.consignee_name = MasterData.consignee_name
        sizedYarnDispatchContent.consigneeDetails.address = MasterData.consignee_address
        sizedYarnDispatchContent.consigneeDetails.state = MasterData.consignee_state_name
        sizedYarnDispatchContent.consigneeDetails.consignee_email = MasterData.consignee_email
        sizedYarnDispatchContent.consigneeDetails.consignee_city_name = MasterData.consignee_city_name

        //Challan Details
        sizedYarnDispatchContent.dispatchDetails.customer_order_no = MasterData.customer_order_no;
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_no = MasterData.dispatch_challan_no
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_date = validateNumberDateInput.current.formatDateToDDMMYYYY(MasterData.dispatch_challan_date)
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_version = MasterData.dispatch_challan_version
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_type = MasterData.dispatch_challan_type;
        // sizedYarnDispatchContent.dispatchDetails.dispatch_schedule_creation_type_desc = MasterData.dispatch_schedule_creation_type_desc

        // sizedYarnDispatchContent.footer.approved_by = MasterData.approved_by_name
        // sizedYarnDispatchContent.footer.supervisor = MasterData.dispatch_supervisor_name
        sizedYarnDispatchContent.footer.other_terms_conditions = MasterData.other_terms_conditions
        sizedYarnDispatchContent.footer.remark = MasterData.remark

        sizedYarnDispatchContent.footer.yarn_count = MasterData.yarn_count
        sizedYarnDispatchContent.footer.product_material_name = MasterData.product_material_name
        sizedYarnDispatchContent.footer.total_ends = MasterData.total_ends
        sizedYarnDispatchContent.footer.set_no = MasterData.set_no

        sizedYarnDispatchContent.footer.weight = MasterData.weight
        sizedYarnDispatchContent.footer.rate = MasterData.rate
        sizedYarnDispatchContent.footer.dispatch_payment_terms = MasterData.dispatch_payment_terms;



        sizedYarnDispatchContent.footer.vehicle_no = MasterData.vehicle_no;
        sizedYarnDispatchContent.footer.yarn_bill_no = MasterData.yarn_bill_no;
        sizedYarnDispatchContent.footer.driver_name = MasterData.driver_name;


        sizedYarnDispatchContent.sizedYarnDetailsData = DetailsData;

        if (openPrintModal) {
            navigate('/Invoice', { state: { invoiceData: sizedYarnDispatchContent, navigationLink: '/Transactions/TDispatchNote/TSizedYarnDispatchNoteEntry', invoiceType: 'DSY', title: 'Dispatched SIzed Yarn', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });

        } else {
            return renderToString(<SizedYarnDispatch invoiceContent={sizedYarnDispatchContent} />);
        }
    };

    function FnFormatDateTime(dateString) {
        if (dateString === null || dateString === undefined || dateString === '') { return ''; }

        let dateParts = dateString.split(' ');
        let date = dateParts[0];
        let time = dateParts[1];

        let dateComponents = date.split('-');
        let year = dateComponents[0];
        let month = dateComponents[1];
        let day = dateComponents[2];

        let timeComponents = time.split(':');
        let hour = parseInt(timeComponents[0]);
        let minute = timeComponents[1];
        let second = timeComponents[2];

        let amOrPm = (hour < 12) ? 'AM' : 'PM';
        if (hour == 0) {
            hour = 12;
        } else if (hour > 12) {
            hour -= 12;
        }
        let formattedDate = day + '/' + month + '/' + year;
        let formattedTime = hour + ':' + minute + ':' + second + ' ' + amOrPm;
        return formattedDate + ' ' + formattedTime;
    }

    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout >
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}


                <div className='card mt-4 p-1'>
                    <div className='card-header text-center py-0 mb-2'>
                        {/* //changes by tushar */}
                        <label className='erp-form-label-lg text-center'>Dispatch Challan Sized Yarn {requestTypeHeading()} {actionType} </label>
                    </div>

                    <form id="dispatchnoteId">
                        <div className="row">
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Dispatch Challan Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_dispatch_challan_type" className="form-select form-select-sm" value={cmb_dispatch_challan_type} onChange={() => { comboOnChange('dispatchScheduleType'); validateFields(); }} disabled={true}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {dispatchScheduleTypeList.length !== 0 ? (
                                                <>
                                                    {dispatchScheduleTypeList.map(scheduleType => (
                                                        // changes by tushar
                                                        <option key={scheduleType.field_id} value={scheduleType.field_id} shortname={scheduleType.product_type_short_name} > {scheduleType.field_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_dispatch_schedule_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">Challan no., Ver. & Date <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_dispatch_challan_no" className="erp_input_field" value={txt_dispatch_challan_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_dispatch_schedule_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='dispatch_challan_version' className="erp_input_field text-end" value={dispatch_challan_version} disabled />
                                                <MDTypography variant="button" id="error_dispatch_schedule_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='txt_dispatch_date' className="erp_input_field" min={currentDate} value={txt_dispatch_date} onChange={e => { setDispatchDate(e.target.value); validateFields(); }} disabled />
                                                <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Set No<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-9 col-10'>

                                        <Select ref={setNoComboRef}
                                            options={setNoOptions}
                                            inputId="cmb_set_no" // Provide the ID for the input box
                                            value={setNoOptions.find(option => option.value === cmb_set_no)}
                                            onChange={(selectedOpt) => {
                                                setSetNo(selectedOpt.value);
                                                setNoComboRef.current = selectedOpt.value;
                                                comboOnChange('cmb_set_no')
                                                validateFields();
                                            }}
                                            placeholder="Search for a Set No..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <div className="col-12">
                                                <Form.Control type="text" id="txt_customer" className="erp_input_field" value={txt_customer} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer Order No<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <div className="col-12">
                                                <Form.Control type="text" id="txt_dispatch_challan_no" className="erp_input_field" value={txt_customer_order_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_dispatch_schedule_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer State & City </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} disabled optional="optional">
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {stateOpts?.map(state => (
                                                        <option value={state.field_id}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} disabled optional="optional">
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {customerCityOpts?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {keyForViewUpdate !== '' &&
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Approved By<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-7'>
                                            <Select ref={dispatchByComboRef}
                                                options={cmb_supervisorList}
                                                isDisabled
                                                inputId="cmb_dispatch_by_id" // Provide the ID for the input box
                                                value={cmb_supervisorList.find(option => option.value === cmb_dispatch_by_id)}
                                                onChange={(selectedOpt) => {
                                                    setDispatchById(selectedOpt.value)
                                                    dispatchByComboRef.current = selectedOpt.value;
                                                    comboOnChange('cmb_dispatch_by_id');
                                                    validateFields();
                                                }}
                                                placeholder="Search for a Employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names

                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_dispatch_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* {keyForViewUpdate==="" && <div className='col-12 col-md-1 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateEmployees'); }} />
                                        </div>} */}
                                        <div className="col-sm-2">
                                            <Form.Control type="date" id='txt_dispatch_date' className="erp_input_field" min={currentDate} value={dt_approve_date} onChange={e => { setApproveDate(e.target.value); validateFields(); }} disabled />
                                            <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>}

                            </div>

                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Consignee </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_consignee_id" className="form-select form-select-sm" value={cmb_consignee_id} onChange={() => { comboOnChange('Consignee'); validateFields(); }} optional="optional">
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {consigneeList?.map(consignee => (
                                                <option value={consignee.field_id}>{consignee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2 refreshIconCol'>
                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateConsignees'); }} />
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Consignee</Form.Label>
                                    </div>
                                    <div className='col-sm-9'>

                                        <Select ref={consigneeComboRef}
                                            options={consigneeList}
                                            isDisabled={true}
                                            inputId="cmb_consignee_id" // Provide the ID for the input box
                                            value={consigneeList.find(option => option.value === cmb_consignee_id)}
                                            onChange={(selectedOpt) => {
                                                setConsigneeId(selectedOpt.value);
                                                consigneeComboRef.current = selectedOpt;
                                                comboOnChange('Consignee')
                                                validateFields();
                                            }}
                                            placeholder="Search for a Consignee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={() => { comboOnChange('ConsigneeState'); validateFields(); }} optional="optional" disabled={true}>
                                                    <option value="">Select</option>
                                                    {stateOpts?.map(state => (
                                                        <option value={state.field_id}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={() => { comboOnChange('ConsigneeCity'); validateFields(); }} optional="optional" disabled={true}>
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {consigneeCityOpts?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Yarn Count</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_yarn_count' className="erp_input_field" value={txt_yarn_count} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_txt_yarn_count" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Rate</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_rate' className="erp_input_field" value={txt_rate} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_rate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Weight (KG)</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id='txt_weight' className="erp_input_field" value={txt_weight} optional='optional' onChange={(e) => { setWeight(e.target.value) }} disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                        <MDTypography variant="button" id="error_txt_weight" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                {/*

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Other Terms Conditions</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_other_terms_conditions" className="erp_txt_area" value={txt_other_terms_conditions} onInput={e => { setOtherCondition(e.target.value); }} maxlength="1000" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_remark" className="erp_txt_area" value={txt_remark} onInput={e => { setRemark(e.target.value); }} maxlength="1000" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>
                                </div>

                              */}

                                <div className="row">
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <hr />

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey={`0`}>
                        <Accordion.Header className="erp-form-label-md">Sized Yarn Dispatch Details</Accordion.Header>
                        <Accordion.Body className="">
                            <div className="row">
                                <div className={`table-responsive ${dispatchDetailsData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                    <>{renderDispatchNoteTable}</>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


                <hr />
                <div className="card">
                    <div className="card-header main_heding text-start">
                        <label className='erp-form-label-lg'>Sized Yarn Challan Totals</label>
                    </div>
                    <div className="card-body">
                        <form id="GRNFooterFormId">
                            <div className="row">
                                <div className="col-md-6 col-12 erp_form_col_div">
                                    {/* first column */}

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Vehicle no</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Driver name</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_driver_name" className="erp_input_field" value={txt_driver_name} onChange={(e) => { setDriverName(e.target.value); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_driver_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Yarn Bill No</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_yarn_bill_no" className="erp_input_field" value={txt_yarn_bill_no} onChange={(e) => { setYarnBillNo(e.target.value); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_yarn_bill_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6 col-12 erp_form_col_div">

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Agent</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={() => { comboOnChange('ConsigneeState'); validateFields(); }} optional="optional" disabled={true}>
                                                <option value="">Select</option>
                                                {agentOptions?.map(state => (
                                                    <option value={state.agent_id}>{state.agent_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_txt_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Other terms & Conditions</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_other_terms_conditions" className="erp_input_field" value={txt_other_terms_conditions} optional='optional' onChange={(e) => { setOtherCondition(e.target.value); }} disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row mb-1'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value); validateFields(); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>

                    {/* <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                        onClick={() => setShowDeleteModal(true)} >Delete</MDButton> */}

                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={() => createDispatchNote(!isApprove ? 'Add' : 'A')}
                        fontWeight="regular">{actionLabel}</MDButton>

                    {/* <MDButton type="button" className={`erp-gb-button ms-2 ${isApprove ? 'display' : 'd-none'}`}
                        variant="button" onClick={() => createDispatchNote('R')}
                        fontWeight="regular">Reject</MDButton> */}

                    {/* <MDButton type="button" id="export-btn" className={`erp-gb-button ms-2 ${dispatch_challan_sized_yarn_id === 0 ? 'display' : 'd-none'}`} variant="button" onClick={ExportToExcel} fontWeight="regular">Export</MDButton> */}
                    <MDButton className={`erp-gb-button ms-1`} id="printBtnId" variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp;
                    {/* <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === "" ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm}>Upload Document</MDButton>&nbsp; */}
                    {/* <MDButton className={`erp-gb-button ${keyForViewUpdate === 'view' && cmb_dispatch_note_status === 'A' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printGatePassSlip(true)}>Print Gate Pass <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp; */}

                </div >

                {/* Add new Record Popup */}
                {showAddRecModal ?
                    <>
                        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                            <Modal.Body className='erp_city_modal_body'>
                                <div className='row'>
                                    <div className='col-12 align-self-end'>
                                        <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button>
                                    </div>
                                </div>
                                {displayRecordComponent()}
                            </Modal.Body>
                        </Modal >
                    </>
                    : null
                }

                {/* Generic Response Message Modals */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                <InfoModal closeModal={() => handleCloseInfoModal()} show={[showInfoMsgModal, infoMsg]} />

                {/* This Modal is used to print the dispatch-note */}
                <Modal show={showConfirmationModal} onHide={(e) => { FnCloseConfirmationModal(); }} backdrop="static" keyboard={false} centered>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={(e) => { FnCloseConfirmationModal(); }}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_success_icon'><RxCheckCircled /></span>
                        <h6>{succMsg}</h6>
                        <h6>{modalOrderDetails}</h6>
                        <Modal.Footer className='erp-modal-footer'>
                            {/* <Button variant="success" className="erp-gb-button" onClick={(e) => { printInvoice(true); }}> Print </Button> */}
                            <Button variant="success" className="erp-gb-button ms-2" onClick={(e) => { FnCloseConfirmationModal(); }}> OK </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal >

                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* </div> */}

            </DashboardLayout>
        </>
    )
}