import React, { useState, useEffect, useRef, useMemo } from "react";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';

function ImmediatePunchReport() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME, COMPANY_ADDRESS } = configConstants;
    const navigate = useNavigate();

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const exlsExp = useRef();

    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let shiftsComboRef = useRef(null);
    let cmb_department_id_ref = useRef(null);
    let subDepartmentComboRef = useRef(null);


    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    // combo's options hooks;
    const defaultOptions = { value: '0', label: 'All', two_day_shift: '', field_name: 'All' };
    const dptdefaultOptions = { department_id: 0, value: 'All', label: 'All' };
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [shiftOpts, setShiftOpts] = useState([defaultOptions]);

    const immidiatePuchDate = new Date().toISOString().split('T')[0];

    // Shift management data hooks;
    const [employeePunchingDetails, setEmployeePunchingDetails] = useState([]);
    const [employeeShiftHeaders, setEmployeeShiftHeaders] = useState([]);

    // Input field's Hooks;
    const [cmb_employee_type, setEmployeeType] = useState('');
    const [cmb_shift, setShift] = useState('0');
    const [dt_att_date, setAttDate] = useState('');

    const [chk_punch_type, setPunchType] = useState(true);
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [cmb_departmentId, setDepartmentId] = useState(0);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [cmb_sub_department_id, setSubDepartmentId] = useState(0);
    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [staff_strength, setStaffStrength] = useState(0);

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => { setShowSuccessMsgModal(false); }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    useEffect(() => {
        const loadDataOnload = async () => {
            if (shiftsComboRef)
                shiftsComboRef.current = defaultOptions;
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, [])

    const FnFillComobos = async () => {
        try {
            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeTypeGroup' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    const emplTypes = [
                        { value: '', label: 'Select', field_id: '', field_name: '', },
                        ...rcvdEmplTypes.map((emplType) => ({ ...emplType, value: emplType.field_id, label: emplType.field_name, })),
                    ];
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load employee types
            // resetGlobalQuery();
            // globalQuery.columns = ["department_id", "department_name"];
            // globalQuery.table = "cm_department"
            // globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            // comboDataFunc.current.fillFiltersCombo(globalQuery)
            //     .then(rcvdDepartment => {
            //         const departments = [
            //             // { value: '', label: 'Select', department_id: '', department_name: '', },
            //             { value: 0, label: 'All', department_id: '', department_name: 'All' },
            //             ...rcvdDepartment.map((dept) => ({ ...dept, value: dept.department_id, label: dept.department_name, })),
            //         ];
            //         setDepartmentOpts(departments);
            //     });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group", "department_std_staff_strength", "department_std_worker_strength"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,

                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,

                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All', department_std_staff_strength: 0, department_std_worker_strength: 0 });
                    setDepartmentOpts(mainDepartments);
                    // subDepartments.unshift({ value: 'All', label: 'All' });
                    // setSubDepartmentOpts(subDepartments);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigator('/Error')
        }
    }

    const FnCombosOnChange = (key, value) => {
        try {
            switch (key) {
                case 'EmployeeType':
                    const employee_type = employeeTypesComboRef.current.value;
                    setStaffStrength(0);
                    if (employee_type !== '') {
                        //Hide the error msg
                        let errorElement = document.getElementById("error_cmb_employee_type");
                        errorElement.style.display = "none";

                        // Load shifts
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name', 'two_day_shift', 'start_time', 'end_time']
                        globalQuery.table = "cmv_shift"
                        globalQuery.conditions = [
                            { field: "employee_type_group", operator: "=", value: employeeTypesComboRef.current.field_name },
                        ];
                        comboDataFunc.current.fillFiltersCombo(globalQuery).then((workingShifts) => {
                            const shiftsList = [
                                { value: '0', label: 'All', two_day_shift: '', field_name: 'All' },
                                ...workingShifts.map((shifts) => ({
                                    ...shifts,
                                    value: shifts.field_id,
                                    label: shifts.field_name + ` [${shifts.start_time} - ${shifts.end_time}]`,   // Without Am/Pm
                                    two_day_shift: shifts.two_day_shift
                                })),
                            ];
                            setShiftOpts(shiftsList);
                        });
                    }

                    setShift('0')
                    if (shiftsComboRef.current) shiftsComboRef.current = defaultOptions;
                    // if (subDepartmentComboRef.current) subDepartmentComboRef.current = defaultOptions;
                    // if (cmb_department_id_ref.current) cmb_department_id_ref.current = defaultOptions;

                    break;
                case 'AttDate':
                    if (value !== '')
                        document.getElementById('error_dt_att_date').style.display = 'none';

                    setAttDate(value);
                    break;
                case 'Shift':
                    const shift = shiftsComboRef.current.value
                    if (shift !== '')
                        document.getElementById('error_cmb_shift').style.display = 'none';
                    break;
                case "Department":
                    debugger
                    setStaffStrength(0);
                    setEmployeePunchingDetails([]);
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId(0);
                    let selectedDepartment = cmb_department_id_ref.current.department_id;
                    let empStaff_Strength = cmb_department_id_ref.current.department_std_staff_strength;
                    let empWorker_Strength = cmb_department_id_ref.current.department_std_worker_strength;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: 0, value: 'All', label: 'All', department_std_staff_strength: 0, department_std_worker_strength: 0 });
                    setSubDepartmentOpts(subDepartmentList);
                    const empl_type = employeeTypesComboRef.current.field_name;
                    setStaffStrength(empl_type === "Staffs" ? empStaff_Strength : empWorker_Strength)
                    break;
                case "subDepartment":
                    debugger
                    setStaffStrength(0);
                    setEmployeePunchingDetails([]);
                    let selectedDepTS = cmb_department_id_ref.current.department_id;

                    const subDepartmentLists = allDepartmentsList.filter(department =>
                        (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                    );
                    subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All', department_std_staff_strength: 0, department_std_worker_strength: 0 });
                    setSubDepartmentOpts(subDepartmentLists);
                    let Staff_Strength = subDepartmentComboRef.current.department_std_staff_strength;
                    let Worker_Strength = subDepartmentComboRef.current.department_std_worker_strength;
                    let empl_Type = employeeTypesComboRef.current.field_name;
                    setStaffStrength(empl_Type === "Staffs" ? Staff_Strength : Worker_Strength)
                    if (subDepartmentComboRef.current.department_id === '') {
                        let empStaff_Strength = cmb_department_id_ref.current.department_std_staff_strength;
                        let empWorker_Strength = cmb_department_id_ref.current.department_std_worker_strength;
                        setStaffStrength(empl_Type === "Staffs" ? empStaff_Strength : empWorker_Strength)
                    }
                    break;
                default:
                    break;
            }

            setEmployeePunchingDetails([]) // clear employee punching details on any field change

        } catch (error) {
            console.log('error', error);
        }
    }

    const validateShiftManagementDetails = () => {
        if (cmb_employee_type === '') {
            let errorElement = document.getElementById("error_cmb_employee_type");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_employee_type").focus();
            return false;
        }

        if (cmb_shift === '') {
            let errorElement = document.getElementById("error_cmb_shift");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_shift").focus();
            return false;
        }

        if (dt_att_date === '') {
            let errorElement = document.getElementById("error_dt_att_date");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("dt_att_date").focus();
            return false;
        }

        return true;
    }


    // fn to farmat date into string
    const FnFormatDate = (date) => {
        if (date !== null && date !== "") {
            return new Date(date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }).split('/').reverse().join('-'); // dd-mm-yyyy format
        }
    };


    const FnShowSchedulerData = async () => {
        setIsLoading(true);
        try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/shiftManagement/FnShowSchedulerData`
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
      }
    const FnShowAttendanceDetails = async () => {
        debugger
        setIsLoading(true)
        try {
            let selectedDepartment = cmb_department_id_ref.current?.department_id || null;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
            if (selectedDepartment === null) {
                setDepartmentId('All')
                FnCombosOnChange("subDepartment");
            }

            if (selectedSubDepartment === null) {
                setSubDepartmentId('All')
            }
            // Validate required fields
            const validate = validateShiftManagementDetails();

            if (validate) {
                const data = {
                    company_id: COMPANY_ID,
                    employee_type_group: employeeTypesComboRef.current.field_name,
                    shift_name: shiftsComboRef.current.field_name,
                    two_day_shift: shiftsComboRef.current.two_day_shift,
                    attendance_date: FnFormatDate(dt_att_date),
                    department_id: cmb_department_id_ref.current.department_id,
                    sub_department_id: subDepartmentComboRef.current?.department_id

                }
                //  if (subDepartmentComboRef.current) subDepartmentComboRef.current = defaultOptions;
                // if (cmb_department_id_ref.current) cmb_department_id_ref.current = defaultOptions;
                // const responseForScheduler = await fetch(
                //     `${process.env.REACT_APP_BASE_URL}/api/shiftManagement/FnShowSchedulerData`
                // );

                

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }

                const getEmployeeDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shiftManagement/FnShowEmployeesDetails`, requestOptions)
                const response = await getEmployeeDetails.json();
                if (response.success === 1) {
                    // setEmployeePunchingDetails(response.content)
                    // setEmployeeShiftHeaders(response.columns)

                    const transformedData = response.content.map(item => ({
                        ...item,
                        min_att_time: item.min_att_time === null ? 'AB' : item.min_att_time
                    }));

                    setEmployeePunchingDetails(transformedData);
                    const desiredColumns = [
                        'sr_no',
                        'employee_name',
                        'department_name',
                        // 'employee_code',
                        'shift_name',
                        'min_att_time'
                    ];

                    // Filter response.columns to include only desired columns
                    const filteredColumns = response.columns.filter(column =>
                        desiredColumns.includes(column.accessor)
                    );

                    // Set the filtered columns
                    setEmployeeShiftHeaders(filteredColumns);

                } else {
                    setEmployeePunchingDetails([])
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                }

            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }

    const FnExportShiftWisePunchDetails = async () => {
        debugger
        try {
            setIsLoading(true)
            if (employeePunchingDetails.length !== 0) {
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] } // , 25 for remark;

                let filtrKeyValue = {};

                filtrKeyValue['0'] = "Employee Type : " + employeeTypesComboRef.current.field_name
                filtrKeyValue['1'] = "Shift : " + shiftsComboRef.current.field_name

                // if (cmb_departmentId !== 0)
                // filtrKeyValue['3'] = "Department Name : " + cmb_department_id_ref.current.department_name
                let departmentName = cmb_department_id_ref.current?.label || '';
                if (departmentName === '' || departmentName === 0 || departmentName === undefined) {
                    departmentName = 'All';
                }

                filtrKeyValue['2'] = "Department Name : " + departmentName;
                // let subdepartmentName = subDepartmentComboRef.current.department_name;
                let subdepartmentName = subDepartmentComboRef.current?.label || '';
                if (subdepartmentName === '' || subdepartmentName === 0 || subdepartmentName === undefined) {
                    subdepartmentName = 'All';
                }

                filtrKeyValue['3'] = "SubDepartment Name : " + subdepartmentName;
                filtrKeyValue['4'] = "Punch Date : " + FnFormatDate(dt_att_date)
                if (staff_strength !== 0) {
                    filtrKeyValue['5'] = "Employee Strength : " + (staff_strength || 0)
                } else {
                    filtrKeyValue['5'] = "Employee Strength : 0"
                }
                jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

                // set columns name.
                // const srNoHeader = { Headers: 'Sr No', accessor: 'sr_no' };
                // jsonToExportExcel.columns = [srNoHeader, ...employeeShiftHeaders];

                jsonToExportExcel.columns = [
                    { Headers: 'Sr No', accessor: 'sr_no' },
                    { Headers: 'Department Name', accessor: 'department_name' },
                    { Headers: 'Sub Department Name', accessor: 'sub_department_name' },
                    { Headers: 'Employee Name', accessor: 'employee_name' },
                    { Headers: 'Shift Name', accessor: 'shift_name' },
                    { Headers: 'In Time', accessor: 'min_att_time' }
                ]
                // employeePunchingDetails.map((punchDetails, Index) => {
                //     const updatedObj = {
                //         ...punchDetails,
                //         sr_no: Index + 1,
                //         shift_name: `${punchDetails.shift_name} ${punchDetails.shift_start_end_time}`
                //     };
                //     jsonToExportExcel['allData'][Index] = updatedObj;
                // });

                const updatedEmployeePunchingDetails = [...employeePunchingDetails];

                // Calculate present and absent counts
                const presentCount = updatedEmployeePunchingDetails.filter(item => item.min_att_time !== 'AB').length;
                const absentCount = updatedEmployeePunchingDetails.filter(item => item.min_att_time === 'AB').length;
                const totalCount = updatedEmployeePunchingDetails.length;
                // Prepare the summary row
                const summaryRow = {
                    department_name: 'Summary',
                    sub_department_name: `${staff_strength ? `Emp.Strength: ${staff_strength || 0}, ` : ''}  Total: ${totalCount},Present: ${presentCount}, Absent: ${absentCount}`
                }; 

                // Append the summary row to the updated array
                updatedEmployeePunchingDetails.push(summaryRow);


                // Assign updated objects to jsonToExportExcel['allData'] at specific index
                updatedEmployeePunchingDetails.forEach((punchDetails, index) => {
                    let shift_name = '';
                    if (punchDetails.shift_name !== undefined && punchDetails.shift_start_end_time !== undefined) {
                        shift_name = `${punchDetails.shift_name} ${punchDetails.shift_start_end_time}`;
                    }
                    const updatedObj = {
                        ...punchDetails,
                        sr_no: index + 1,
                        // shift_name: `${punchDetails.shift_name} ${punchDetails.shift_start_end_time}`
                        shift_name: shift_name
                    };
                    jsonToExportExcel['allData'][index] = updatedObj;
                });


                jsonToExportExcel['headings']['ReportName'] = "Immediate Punch Report"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = COMPANY_ADDRESS

                await exlsExp.current.excel(jsonToExportExcel, shiftsComboRef.current.label + '-' + FnFormatDate(dt_att_date))
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }


    const goBack = () => {
        navigate("/Dashboard")
    }


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintImmediatePunch = useReactToPrint({
        content: () => {
            debugger
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            // Remove the print button from the cloned content

            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: "Immediate Punch Report"
    });
    function formatDate(date) {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Immediate Punch Details
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>
                            {/* <div className='erp-invoice-print-label-lg text-center'>
                                For Month: {salaryMonthComboRef.current?.label}   Year: {salaryYearComboRef.current?.value}
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'></div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Employee Type : <span className="erp-invoice-print-label">{
                                employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                    `${employeeTypesComboRef.current?.label}`
                                )
                            }</span></dt>

                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                Department Name: <span className="erp-invoice-print-label">{cmb_department_id_ref.current?.label === 'Select' || cmb_department_id_ref.current?.label === undefined || cmb_department_id_ref.current?.label === '' ? 'All' : cmb_department_id_ref.current?.label}
                                </span></dt>

                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Shift :<span className="erp-invoice-print-label"> {
                                // shiftsComboRef.current?.label !== 'All' && shiftsComboRef.current?.label !== '' && (
                                `${shiftsComboRef.current?.label}`
                                // )
                            }</span></dt>
                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                Sub Department Name: <span className="erp-invoice-print-label">{subDepartmentComboRef.current?.label === 'Select' || subDepartmentComboRef.current?.label === undefined || subDepartmentComboRef.current?.label === '' ? 'All' : subDepartmentComboRef.current?.label}
                                </span></dt>

                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Punch Date : <span className="erp-invoice-print-label">{formatDate(dt_att_date)}</span></dt>
                        </div>
                        {staff_strength !== 0 && (
                            <div className="col-sm-6">
                                <dt className="erp-invoice-print-label-md-lg">
                                    Employee Strength : <span className="erp-invoice-print-label">{staff_strength || 0}
                                    </span></dt>

                            </div>
                        )}

                    </div>
                </div>
            </>
        );
    };

    const FnRenderReportTbl = useMemo(() => {
        if (employeePunchingDetails.length === 0) { return null; }
        // Calculate Present and Absent counts
        // const totalPresent = employeePunchingDetails.reduce((total, item) => total + (item.min_att_time ? 1 : 0), 0);
        // const totalAbsent = employeePunchingDetails.length - totalPresent;
        const totalPresent = employeePunchingDetails.reduce((total, item) => total + (item.min_att_time !== 'AB' ? 1 : 0), 0);
        const totalAbsent = employeePunchingDetails.length - totalPresent;
        const totalCount = employeePunchingDetails.length; // Total count

        return (
            <>
                <style>
                    {`
                            @page { 
                                size: A4 portrait;
                                margin: 2%;
                            }
                            .erp_table {
                                font-size: 3px !important; /* Default font size */
                            }
                                .erp-invoice-print-label-md-lg , .erp-invoice-print-label{
                                font-size: 14px !important;

                                }
                            .erp_table_th, .erp_table_td {
                                border: 1px solid black;
                                padding-top: 2px !important;
                                padding-bottom: 2px !important;
                            }
                        `}
                </style>


                <Table className="erp_table" id='PunchDtlsTbl' style={{ whiteSpace: 'normal' }} responsive striped>
                    <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" >Sr. No.</th>
                            <th className="erp_table_th align-middle col-2" >Department Name</th>
                            <th className="erp_table_th align-middle col-2" >Sub Department Name</th>
                            <th className="erp_table_th align-middle col-4">Employee Name</th>
                            <th className="erp_table_th align-middle col-2">Shift</th>
                            <th className="erp_table_th align-middle col-2">Punch</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            employeePunchingDetails.map((item, index) => (
                                <tr key={index} style={{ height: "25px" }}>
                                    <td className="erp_table_td">{index + 1}</td>
                                    <td className="erp_table_td">{item.department_name}</td>
                                    <td className="erp_table_td">{item.sub_department_name}</td>
                                    <td className="erp_table_td">{item.employee_name}</td>
                                    <td className="erp_table_td">{item.shift_name + ' [' + item.shift_start_end_time + ']'}</td>
                                    <td className="erp_table_td">{item.min_att_time}</td>
                                </tr>
                            ))
                        }
                        <tr className="summary-row table-light" style={{ height: "25px", fontWeight: "bold" }}>
                            <th className="erp_table_th" colSpan="4">Summary </th>
                            <th className="erp_table_th" colSpan="2">   {staff_strength !== 0 ? `Emp.Strength: ${staff_strength || 0}, ` : ''}
                                Total: {totalCount}, Present: {totalPresent}, Absent: {totalAbsent}</th>

                        </tr>   
                    </tbody>
                </Table>
            </>
        )
    }, [employeePunchingDetails]);

    // ********* PrintOut Functionalities Ends. ****************************************
    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    null}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-md-lg">Immediate Punch Report</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}

                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md"> Employee Type <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type" // Provide the ID for the input box
                                            value={employeeTypesOpts.find(option => option.value === cmb_employee_type)}
                                            onChange={(selectedOpt) => {
                                                setEmployeeType(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnCombosOnChange('EmployeeType')
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label-md"> Shift </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={shiftsComboRef}
                                            options={shiftOpts}
                                            inputId="cmb_shift" // Provide the ID for the input box
                                            value={shiftOpts.find(option => option.value === cmb_shift)}
                                            onChange={(selectedOpt) => {
                                                setShift(selectedOpt.value);
                                                shiftsComboRef.current = selectedOpt;
                                                FnCombosOnChange('Shift')
                                            }}
                                            placeholder="Search for a shift..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Department</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Select
                                            ref={cmb_department_id_ref}
                                            options={departmentOpts}
                                            // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            value={departmentOpts.length > 1 ? departmentOpts.find(option => option.value === cmb_departmentId) : null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                cmb_department_id_ref.current = selectedOpt;
                                                FnCombosOnChange("Department");
                                            }}
                                            placeholder="Search for a Department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            // disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("subDepartment");
                                                // FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">Punch Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            selected={dt_att_date}
                                            id="dt_att_date"
                                            onChange={(date) => FnCombosOnChange('AttDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional" maxDate={immidiatePuchDate}
                                        />
                                        <MDTypography variant="button" id="error_dt_att_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                    </div>
                                </div>
                                {staff_strength !== 0 && staff_strength !== '' && (
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label"> Employee Strength</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="staff_strength" className="erp_input_field" value={staff_strength || 0} onChange={e => { setStaffStrength(e.target.value); }} maxLength="255" optional="optional" disabled />
                                            <MDTypography variant="button" id="error_txt_strenght" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                )}
                            </div>


                        </div>

                        {/*Second row */}
                        {/* <div className="row"> */}
                        {/* <div className='row'>
                            <div className="col-sm-4">
                                <div className="col-sm-3 erp_form_col_div">


                                </div>



                            </div>
                            <div className="col-sm-4">
                            </div>
                            <div className="col-sm-4"></div>
                        </div> */}
                        {/* </div> */}

                        <div className="row mt-1">
                            <div className="col text-center">
                                <MDButton type="button" className="erp-gb-button" onClick={FnShowAttendanceDetails} variant="button" fontWeight="regular" >Show</MDButton>
                                <MDButton type="button" className="ms-2 erp-gb-button" onClick={FnExportShiftWisePunchDetails} variant="button" fontWeight="regular" disabled={employeePunchingDetails.length === 0}>Export To Excel</MDButton>
                                </div>
                                <div className="col-auto"><MDButton  type="button" className="erp-gb-button" onClick={FnShowSchedulerData} variant="button" fontWeight="regular">Scheduler</MDButton>

                            </div>
                        </div>

                        <hr />


                        <div className="row px-lg-2 d-block">
                            <div className="col-lg-12 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-md-lg">Employee's Punching Details</label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">

                                        <div className="row print-content" ref={printComponentRef} >
                                            {FnRenderReportTbl}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>
                                {
                                    employeePunchingDetails.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2 print-button"

                                                onClick={(e) => { FnPrintImmediatePunch(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}


                </div>
                {/* Card Ends*/}
            </DashboardLayout>

            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        </>
    )
}

export default ImmediatePunchReport


