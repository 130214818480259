import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import $ from 'jquery';

import { Form, Table, Card } from "react-bootstrap";
import MDTypography from 'components/MDTypography';
import MDButton from "components/MDButton";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import FrmValidations from 'FrmGeneric/FrmValidations';
import ReactPaginate from "react-paginate";

import { useNavigate } from "react-router-dom";
import MaterialSearchBar from "FrmGeneric/MaterialSearchBar";

import { CircularProgress } from "@mui/material"; //Loader
import Select from 'react-select'; //Searchable Select Box
function FrmStockAgingReport() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, COMPANY_ADDRESS } = configConstants;

    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);

    const [isLoading, setIsLoading] = useState(false);


    //Master Hooks
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [subDepartmentGroupOptions, setSubDepartmentGroupOptions] = useState([]);
    const [cmb_department_id, setDepartmentId] = useState('');
    const cmb_department_id_ref = useRef()
    const [cmb_subdepartment_group_id, setSubDepartmentGroupId] = useState('');
    const cmb_subdepartment_group_id_ref = useRef()
    const [agingDtlTblData, setAgingDtlTblData] = useState([]);
    const [by_material, setByMaterial] = useState(false);
    const [search_material_id, setSearchMaterialId] = useState('');
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    // const [cmb_product_type_id, setProduct_type_id] = useState('0');
    const [cmb_product_type_id, setProduct_type_id] = useState(COMPANY_ID === 1 ? 2 : COMPANY_ID === 2 ? 12 : null);


    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
        // { label: "All", value: 0 },
    ]
    const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);
    let productTypeComboRef = useRef(null);

    //Returning to Dashboard
    const goBack = () => { navigate("/Dashboard") }
    useEffect(() => {
        const loadDataOnload = async () => {
            try {
                setIsLoading(true);
                await FnFillComboBoxes();
                await FnShowData(0, entriesPerPage);

            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        loadDataOnload()

    }, [])


    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        setcurrentPage(0)
        await FnShowData(0, count);
    }

    const handlePageClick = async (pageNo) => {
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        let offset = currentPage * entriesPerPage;
        await FnShowData(offset, entriesPerPage);
    }


    const FnFillComboBoxes = async () => {
        try {

            resetGlobalQuery();
            globalQuery.columns = ['product_type_id', 'product_type_name', 'product_type_short_name'];
            globalQuery.table = "sm_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const productTypeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const typesOpts = [
                { value: '0', label: 'All', centerShortName: '' },
                ...productTypeList.map((productType) => ({ ...productType, value: productType.product_type_id, label: `${productType.product_type_name}` })),
            ];
            setProductTypeOptions(typesOpts);


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name',]
            globalQuery.conditions = [
                { field: "department_type", operator: "=", value: "M" },
                { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] },
                { field: "is_delete", operator: "=", value: 0 },
            ]
            globalQuery.table = "cmv_department";
            const deptOptions = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const departmentOptions = [
                { value: '', label: 'Select' },
                // { value: '0', label: 'Add New Record+' },
                ...deptOptions.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
            ];
            setDepartmentOptions(departmentOptions);

        } catch {

        }
    };

    const comboBoxesOnChange = async (key) => {
        try {
            switch (key) {
                case 'Department':
                    let department_val = cmb_department_id_ref.current.value;
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'parent_department_id', 'parent_department']
                    globalQuery.conditions = [
                        { field: "department_type", operator: "=", value: "S" },
                        { field: "parent_department_id", operator: "=", value: parseInt(department_val) },
                        { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] },
                        { field: "is_delete", operator: "=", value: 0 },
                    ]
                    globalQuery.table = "cmv_department";
                    let subdeptOptions = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    const subdepartmentOptions = [
                        { value: '', label: 'Select' },
                        // { value: '0', label: 'Add New Record+' },
                        ...subdeptOptions.map((department) => ({
                            ...department, value: department.field_id, label: department.field_name,
                            parent_department_id: department.parent_department_id, parent_department: department.parent_department
                        })),
                    ];
                    setSubDepartmentGroupOptions(subdepartmentOptions);

                    break;
            }
        } catch {

        }
    }

    const FnChkIsValidFilters = () => {
        const fromDateObj = new Date($("#dt_from_date").val());
        const toDateObj = new Date($("#dt_to_date").val());

        let isValid = true;
        if (!fromDateObj) {
            $('#error_dt_from_date').text('From Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }
        if (!toDateObj) {
            $('#error_dt_to_date').text('To Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }
        if (fromDateObj && toDateObj && fromDateObj > toDateObj) {
            $('#error_dt_from_date').text('From Date cannot be greater than To Date.').show();
            isValid = false;
        } else if (fromDateObj && toDateObj) {
            $('#error_dt_from_date').hide();
        }
        return isValid;
    };

    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_code'];

    const getMaterialBySearch = async (selectedData) => {
        debugger
        if (selectedData !== null) {
            try {
                setIsLoading(true)
                let { product_id, product_name, product_code } = selectedData[0];
                let material_name = product_name;
                let material_code = product_code;
                let material_id = product_id;
                console.log("searched Material:  ", material_name, ' : ', material_code, ' : ', material_id);
                debugger
                if (by_material) {
                    setSearchMaterialId(product_id)
                    await FnShowData(PageCurrent, entriesPerPage, product_id);
                    $('#error_selectMaterialRecords_id').hide();;
                } else {
                    $('#error_selectMaterialRecords_id').text('Please fill checkbox first').show();
                }
            } catch (error) {
                console.error("Error in Search Material: ", error);
                setSearchMaterialId('')
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        } else {
            setAgingDtlTblData([]);
        }
    }

    const FnShowData = async (page, pageSize, product_id = '') => {
        try {
            setIsLoading(true)
            let commonIds = {};
            // // Common Ids 
            commonIds.company_id = COMPANY_ID
            // commonIds.from_date = dt_from_date
            // commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            // commonIds.category1_id = cmb_product_category1_id.toString();
            // commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = product_id === '' ? search_material_id : product_id;
            // commonIds.godown_id = cmb_godown_id.toString();
            // commonIds.godown_section_id = cmb_godown_section_id.toString();
            // commonIds.godown_section_beans_id = cmb_godown_section_bean_id.toString();
            // console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getAgingStockDetails/${page}/${pageSize}`, requestOptions)
            const responce = await apiCall.json();
            console.log("Raw material Stock Report : ", responce);
            if (responce.success = 1) {
                const total = responce.total_count;
                setpageCount(parseInt(pageSize) !== 0 ? Math.ceil(total / pageSize) : 1);
                setAgingDtlTblData(responce.agingStockDetailsData)
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    const FnRenderAggingDetailsTbl = useMemo(() => {
        return (
            <>
                <Table className="erp_table" style={{ whiteSpace: 'normal', tableLayout: 'auto' }} >
                    <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }}>
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th" rowSpan="2">SR NO</th>
                            {/* <th className="erp_table_th" rowSpan="2">UNIT</th>
                            <th className="erp_table_th" rowSpan="2">DEPARTMENT</th>
                            <th className="erp_table_th" rowSpan="2">SUB-DEPARTMENT</th> */}
                            <th className="erp_table_th" rowSpan="2">MATERIAL CODE</th>
                            <th className="erp_table_th" rowSpan="2">MATERIAL NAME</th>
                            {/* <th className="erp_table_th" rowSpan="2">ITEM ID</th> */}

                            <th className="erp_table_th" colSpan="2">BELOW 30 DAYS</th>
                            <th className="erp_table_th" colSpan="2">31 -60 DAYS</th>
                            <th className="erp_table_th" colSpan="2">61 -180 DAYS </th>
                            <th className="erp_table_th" colSpan="2">181 -365 DAYS</th>
                            <th className="erp_table_th" colSpan="2">ABOVE ONE YEAR</th>
                            <th className="erp_table_th" colSpan="2">TOTAL</th>


                        </tr>
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th" >{cmb_product_type_id === 2 ? "QUANTITY" : cmb_product_type_id === 12 ? "NO.OF CONES" : ""} </th>
                            <th className="erp_table_th" >AMOUNT</th>
                            <th className="erp_table_th" >{cmb_product_type_id === 2 ? "QUANTITY" : cmb_product_type_id === 12 ? "NO.OF CONES" : ""} </th>
                            <th className="erp_table_th" >AMOUNT</th>
                            <th className="erp_table_th" >{cmb_product_type_id === 2 ? "QUANTITY" : cmb_product_type_id === 12 ? "NO.OF CONES" : ""} </th>
                            <th className="erp_table_th" >AMOUNT</th>
                            <th className="erp_table_th" >{cmb_product_type_id === 2 ? "QUANTITY" : cmb_product_type_id === 12 ? "NO.OF CONES" : ""} </th>
                            <th className="erp_table_th" >AMOUNT</th>
                            <th className="erp_table_th" >{cmb_product_type_id === 2 ? "QUANTITY" : cmb_product_type_id === 12 ? "NO.OF CONES" : ""} </th>
                            <th className="erp_table_th" >AMOUNT</th>
                            <th className="erp_table_th" >{cmb_product_type_id === 2 ? "QUANTITY" : cmb_product_type_id === 12 ? "NO.OF CONES" : ""} </th>
                            <th className="erp_table_th" >AMOUNT</th>
                        </tr>
                    </thead>


                    <tbody>
                        {agingDtlTblData.length === 0 ? (
                            <tr>
                                <td colSpan="18" className="text-left">
                                    <Card id="NoRcrdId">
                                        <Card.Body>No records found...</Card.Body>
                                    </Card>
                                </td>
                            </tr>
                        ) : (
                            agingDtlTblData.map((agingRcd, index) => (
                                <tr key={index}>
                                    <td className="erp_table_td text-end">{index + 1}</td>
                                    {/* <td className="erp_table_td">{agingRcd.unit}</td>
                                    <td className="erp_table_td">{agingRcd.department_name}</td>
                                    <td className="erp_table_td">{agingRcd.sub_department_name}</td> */}
                                    <td className="erp_table_td">{agingRcd.product_rm_code}</td>

                                    <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{agingRcd.product_rm_name}</td>
                                    {/* <td className="erp_table_td">{agingRcd.product_rm_id}</td> */}

                                    {/* Quantity columns */}

                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.below_30_days || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.below_30_days_amount || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.between_31_and_60_days || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.between_31_and_60_days_amount || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.between_61_and_180_days || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.between_61_and_180_days_amount || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.between_181_and_365_days || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.between_181_and_365_days_amount || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.above_one_year || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.above_one_year_amount || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.total_closing_balance_quantity || 0)}</td>
                                    <td className="erp_table_td text-end">{Math.abs(agingRcd.total_amount || 0)}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table >
            </>
        )
    }, [agingDtlTblData])


    //Export Functionality 
    const FnExportToExcel = async () => {
        // Means No Data For Export. 
        if (agingDtlTblData && Object.keys(agingDtlTblData).length === 0) { return null; }

        setIsLoading(true);
        try {
            let commonIds = {};
            // Common Ids 
            debugger
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_to_date
            commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            // commonIds.category1_id = cmb_product_category1_id.toString();
            // commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = search_material_id;
            // commonIds.godown_id = cmb_godown_id.toString();
            // commonIds.godown_section_id = cmb_godown_section_id.toString();
            commonIds.COMPANY_NAME = COMPANY_NAME
            commonIds.COMPANY_ADDRESS = COMPANY_ADDRESS
            commonIds.ReportName = "Aging Stock Report"
            commonIds.SheetName = "Aging Report"
            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getAgingStockDetailsExportExcel`, requestOptions)
            // Ensure the response is ok (status 200)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Get the response as a blob (binary large object)
            const blob = await response.blob();
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([blob]));
            // Create a link element to download the file
            const link = document.createElement('a');
            link.href = url;
            // Set the name of the file
            link.setAttribute('download', 'aging_report.xlsx'); // Set your preferred file name
            // Append the link to the document body
            document.body.appendChild(link);
            // Trigger the download by simulating a click
            link.click();
            // Clean up by removing the link from the document
            document.body.removeChild(link);
            console.log("File downloaded successfully");
        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }
    // ********* ExportToExcel Functionalities Ends. ****************************************
    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />


                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Stock Aging Report</label>
                    </div>

                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <form id='stockAgingReportId'>
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); FnChkIsValidFilters() }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters() }} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            <div className='col-sm-4 erp_form_col_div'>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={productTypeComboRef}
                                            inputId="cmb_product_type_id"
                                            value={productTypeOptions.find(option => option.value === cmb_product_type_id) || null}
                                            options={productTypeOptions}
                                            onChange={(selectedOption) => {
                                                setProduct_type_id(selectedOption.value)
                                                productTypeComboRef.current = selectedOption;
                                                // comboOnChange('category1');
                                            }}
                                            placeholder="Search for product type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            isDisabled={true}

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                                {/* <div className='col-sm-4 erp_form_col_div'> */}
                                <div className={`row`}>
                                    <div className="col-sm-4">
                                        <input type='checkbox' class="me-1" name="selectMaterialRecords" id="selectMaterialRecords" onChange={(e) => { e.target.checked ? setByMaterial(true) : setByMaterial(false); setSearchMaterialId('') }} /> <label class="erp-form-label pb-1">Search By Material </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <MaterialSearchBar getSearchData={getMaterialBySearch} formIdForValidate="" requiredCols={requiredCols} product_type_id={cmb_product_type_id} />
                                        <MDTypography variant="button" id="error_selectMaterialRecords_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {/* </div> */}

                                </div>
                                {/* <div className='row  mb-1'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-6'>
                                        <Select ref={cmb_department_id_ref}
                                            options={departmentOptions}
                                            inputId="cmb_department_id" // Provide the ID for the input box
                                            value={departmentOptions.length > 1 ? departmentOptions.find(option => option.value === cmb_department_id) : null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                cmb_department_id_ref.current = selectedOpt;
                                                comboBoxesOnChange("Department");
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row  mb-1'>

                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Sub-Department
                                           
                                        </Form.Label>

                                    </div>
                                    <div className='col-sm-6'>
                                        <Select ref={cmb_subdepartment_group_id_ref}
                                            options={subDepartmentGroupOptions}
                                            inputId="cmb_subdepartment_group_id" // Provide the ID for the input box
                                            value={subDepartmentGroupOptions.length > 1 ? subDepartmentGroupOptions.find(option => option.value === cmb_subdepartment_group_id) : null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentGroupId(selectedOpt.value)
                                                cmb_subdepartment_group_id_ref.current = selectedOpt;
                                                // comboBoxesOnChange("SubDepartment");
                                            }}
                                            placeholder="Search for a Sub-Department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_subdepartment_group_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>*/}

                            </div>
                        </div>
                    </form>

                    <div className="row text-center mt-3">
                        <div className=''>
                            <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" variant="button" onClick={() => FnShowData(0, entriesPerPage)} fontWeight="regular">Show Data</MDButton>
                        </div>
                    </div>

                    <hr />
                    <div className="row px-lg-2 d-block">
                        {/* <div className="card p-0"> */}
                        {/* <div className="card-header py-0 main_heding mb-0">
                                <label className="erp-form-label-md-lg">Stock Aging Details</label>
                            </div> */}
                        {/* <div className="card-body p-0 print-content">
                                {FnRenderAggingDetailsTbl}
                            </div> */}
                        {
                            !agingDtlTblData || agingDtlTblData.length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                :
                                <>

                                    <div className="row py-1">
                                        <div className="col-sm-6 page_entries">
                                            <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>
                                            <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                                                {pageEntriesOptions.map(pageEntriesOptions => (
                                                    <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                                                ))}
                                            </Form.Select>
                                        </div>

                                        <div className="col-sm-6 text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }}
                                            >Export Excel</MDButton>

                                        </div>
                                    </div>
                                    <div>

                                        {FnRenderAggingDetailsTbl}

                                        {pageCount !== 1 ?
                                            <ReactPaginate
                                                className='erp_pagination'
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link erp-gb-button"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link erp-gb-button"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link erp-gb-button"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"} /> : null}
                                    </div>
                                </>
                        }
                        {/* </div> */}
                    </div>

                </div>

            </DashboardLayout >
        </>
    )
}

export default FrmStockAgingReport