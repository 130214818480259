import React, { useState } from 'react'
import { useEffect, useRef, useMemo } from 'react';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import { Spinner, Table } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import ExcelExport from "Features/Exports/ExcelExport";
import ErrorModal from "components/Modals/ErrorModal"

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { CircularProgress } from "@material-ui/core";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

function FrmSizedBeamsReport() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, SHORT_COMPANY, UserName, UserId, FINANCIAL_SHORT_NAME } = configConstants;

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const formatToDDMMYYYY = (date) => {
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
    };

    const [toggleCustDate, setToggleCustDate] = useState(false)
    const [to_stock_date, SetToStockDt] = useState(today());
    const [from_stock_date, SetFromStockDt] = useState(today());
    const [isLoading, setIsLoading] = useState(false);
    const [cmb_sales_job_type, setSalesOrderJob] = useState('');
    const [cmb_beam_status, setBeamStatus] = useState('Available');
    const [jobTypeArr, setJobTypeArr] = useState([]);

    //show error msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [sizedBeamReportData, setSizedBeamReportData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        try {
            const fetchJobTypes = async () => {
                // setIsLoading(true);
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
                globalQuery.table = "amv_properties";
                globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getJobTypeAPICall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setJobTypeArr(getJobTypeAPICall);

                setIsLoading(true);
                resetGlobalQuery();
                globalQuery.columns.push('*');
                globalQuery.table = "xtv_sizing_production_stock_details";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                if (toggleCustDate) {
                    if (from_stock_date === to_stock_date) {
                        globalQuery.conditions.push({ field: "sizing_production_date", operator: "=", value: from_stock_date });
                    } else {
                        globalQuery.conditions.push({ field: "sizing_production_date", operator: ">=", value: from_stock_date });
                        globalQuery.conditions.push({ field: "sizing_production_date", operator: "<=", value: to_stock_date });
                    }
                }
                // globalQuery.conditions.push({ field: "sizing_production_date", operator: "<=", value: to_stock_date });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "sized_beam_status_desc", operator: "=", value: cmb_beam_status })
                const getAPICallForSizedBeamStockData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setSizedBeamReportData(getAPICallForSizedBeamStockData);
                setIsLoading(false)
            };

            fetchJobTypes(); //Trigger the function
        } catch (error) {
        } finally {
            setIsLoading(false);
        }

    }, []);




    //useRef Hooks
    const comboDataAPiCall = useRef();
    const navigate = useNavigate();
    const exlsExp = useRef();


    const renderSizedBeamStockTable = useMemo(() => {
        return (
            <Table>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th" style={{ width: "40px" }}>Sr No.</th>
                        <th className="erp_table_th" style={{ width: "80px" }}>Prod. Date</th>
                        <th className="erp_table_th" style={{ width: "70px" }}>Count</th>
                        <th className="erp_table_th" style={{ width: "70px" }}>Total Ends</th>
                        <th className="erp_table_th" style={{ width: "70px" }}>Beam No</th>
                        <th className="erp_table_th" style={{ width: "60px" }}>Length</th>
                        <th className="erp_table_th" style={{ width: "60px" }}>Set No.</th>
                        <th className="erp_table_th" style={{ width: "75px" }}>Job Type</th>
                        <th className="erp_table_th" style={{ width: "80px" }}>Beam Status</th>
                        <th className="erp_table_th" style={{ width: "110px" }}>Customer Name</th>
                        <th className="erp_table_th" style={{ width: "80px" }}>Sizing Production Code</th>
                    </tr>
                </thead>

                {sizedBeamReportData && sizedBeamReportData.length > 0 ? (
                    sizedBeamReportData.map((sizedBeamDetails, indexOfItem) => (
                        <tr key={indexOfItem} rowindex={indexOfItem}>
                            <td className="erp_table_td">{indexOfItem + 1}</td>
                            <td className="erp_table_td">{sizedBeamDetails.sizing_production_date}</td>
                            <td className="erp_table_td">{sizedBeamDetails.product_material_name}</td>
                            <td className="erp_table_td">{sizedBeamDetails.total_ends}</td>
                            <td className="erp_table_td">{sizedBeamDetails.beam_inward_type}</td>
                            <td className="erp_table_td">{sizedBeamDetails.sizing_length}</td>
                            <td className="erp_table_td">{sizedBeamDetails.set_no}</td>
                            <td className="erp_table_td">{sizedBeamDetails.job_type}</td>
                            <td className="erp_table_td">{sizedBeamDetails.sized_beam_status_desc}</td>
                            <td className="erp_table_td">{sizedBeamDetails.customer_name}</td>
                            <td className="erp_table_td">{sizedBeamDetails.sizing_production_code}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="11" className="erp_table_td" style={{ textAlign: 'center' }}>No Data Found</td>
                    </tr>
                )}

            </Table>
        )
    }, [sizedBeamReportData])


    const addSizingBeamInwards = async () => {
        try {
            setIsLoading(true);
            const parsedFromDate = new Date(from_stock_date);
            const parsedToDate = new Date(to_stock_date);
            let job_type = $("#cmb_sales_job_type option:selected").text();

            if (parsedFromDate <= parsedToDate) {
                // Hide error if dates are valid
                $("#error_to_stock_date").hide();

                resetGlobalQuery();
                globalQuery.columns.push('*');
                globalQuery.table = "xtv_sizing_production_stock_details";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

                // Add job type condition
                if (cmb_sales_job_type !== null && job_type !== 'All') {
                    globalQuery.conditions.push({ field: "job_type", operator: "=", value: job_type });
                }
                if (cmb_beam_status !== '' && cmb_beam_status !== 'All') {

                    globalQuery.conditions.push({ field: "sized_beam_status_desc", operator: "=", value: cmb_beam_status });
                }

                // Exclude deleted records
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                // Add date conditions
                if (toggleCustDate) {
                    if (parsedToDate.getTime() === parsedFromDate.getTime()) {
                        globalQuery.conditions.push({ field: "sizing_production_date", operator: "=", value: from_stock_date });
                    } else {
                        globalQuery.conditions.push({ field: "sizing_production_date", operator: ">=", value: from_stock_date });
                        globalQuery.conditions.push({ field: "sizing_production_date", operator: "<=", value: to_stock_date });
                    }
                }

                // Fetch data and update state
                const prodSizedBeamStockAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setSizedBeamReportData(prodSizedBeamStockAPICall);
            } else {
                // Show error if dates are invalid
                $("#error_to_stock_date").text("Enter From & To dates properly...").show();
            }

        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    let requiredColsForExport = ['sr_no',
        "sizing_production_date",
        "product_material_name",
        "total_ends",
        "beam_inward_type",
        "sizing_length",
        "set_no",
        "job_type",
        "sized_beam_status_desc",
        "customer_name",
        "sizing_production_code",
    ];

    const ExportToExcel = () => {
        if (sizedBeamReportData.length !== 0) {
            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }   // [5, 6, 8] 
            let getDate=()=>{
                if(from_stock_date!==to_stock_date){
                    return formatToDDMMYYYY(from_stock_date) + " - " + formatToDDMMYYYY(to_stock_date)
            }
            return formatToDDMMYYYY(from_stock_date)
        }
            jsonToExportExcel.filtrKeyValue['0'] = "For Date" + ' : ' + getDate();
            debugger
            jsonToExportExcel.filtrKeyValue['1'] = `Job Type : ${cmb_sales_job_type===""?"All":cmb_sales_job_type}`;
            jsonToExportExcel.filtrKeyValue['2'] = "Beam Status" + ' : ' + cmb_beam_status;
            for (let col = 0; col < requiredColsForExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": requiredColsForExport[col], "accessor": requiredColsForExport[col] })
            }
            sizedBeamReportData.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });
            jsonToExportExcel['headings']['ReportName'] = "Sized Beam Stock Report"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, `Sized Beam Stock Report ${from_stock_date}-${to_stock_date}`)
        } else {
            setErrMsg('No Data Found...!')
            setShowErrorMsgModal(true)
        }
    }

    return (
        <>
            <ExcelExport ref={exlsExp} />
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Sized Beam Stock Report</label>
                    </div>
                    <div className="row ms-1 mt-3">
                        <div className="col-1">
                            <span className="erp-form-label">Custom Date</span>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center">
                            <Form.Check
                                className="erp_radio_button"
                                type="switch"
                                value={toggleCustDate}
                                checked={toggleCustDate}
                                onClick={() => setToggleCustDate(!toggleCustDate)}
                            />
                        </div>
                    </div>
                    <div className="row ms-1 mt-3">
                        <div className="col-sm-3 erp_form_col_div ">
                            <div className='row'>
                                <div className='col-sm-4 pb-3'>
                                    <Form.Label className="erp-form-label">Job Type </Form.Label>
                                </div>
                                <div className='col-sm-6'>
                                    <select id="cmb_sales_job_type" className="form-select form-select-sm" value={cmb_sales_job_type} onChange={(e) => { setSalesOrderJob(e.target.value); }} optional='optional' >
                                        <option value="" >All</option>
                                        {jobTypeArr?.map(jobtype => (
                                            <option value={jobtype.field_name}>{jobtype.field_name}</option>
                                        ))}
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_sales_job_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 erp_form_col_div ">
                            <div className='row'>
                                <div className='col-sm-4 pb-3'>
                                    <Form.Label className="erp-form-label">Beam Status </Form.Label>
                                </div>
                                <div className='col-sm-6'>
                                    <select id="cmb_beam_status" className="form-select form-select-sm" value={cmb_beam_status} onChange={(e) => { setBeamStatus(e.target.value); }} optional='optional' >
                                        {/* <option value="" >Select</option> */}
                                        <option value="All" >All</option>
                                        <option value="Available" >Available</option>
                                        <option value="Dispatched" >Dispatched</option>
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_beam_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                        </div>

                        {toggleCustDate &&
                            <div className="col-sm-3 erp_form_col_div ">
                                <div className="row">
                                    <div className="col-sm-4 pb-3">
                                        <Form.Label className="erp-form-label">From Date  </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id="from_stock_date" className="erp_input_field" value={from_stock_date} onChange={e => { SetFromStockDt(e.target.value); }} />
                                        <MDTypography variant="button" id="error_from_stock_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                    </div>

                                </div>
                            </div>
                        }
                        {toggleCustDate &&
                            <div className="col-sm-3 erp_form_col_div ">
                                <div className="row">
                                    <div className="col-sm-4 pb-3">
                                        <Form.Label className="erp-form-label">To Date  </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id="to_stock_date" className="erp_input_field" value={to_stock_date} onChange={e => { SetToStockDt(e.target.value); }} />
                                    </div>
                                    <MDTypography variant="button" id="error_to_stock_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />

                                </div>
                            </div>
                        }

                        <div className="card-footer pb-2 text-center">
                            <MDButton type="submit" onClick={addSizingBeamInwards} className={`erp-gb-button`} variant="button"
                                fontWeight="regular">Show</MDButton>
                            <MDButton type="button" id="export-btn" className={`erp-gb-button  erp_MLeft_btn `} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                        </div >
                    </div>

                    <hr />

                    <div className="col-sm-12 px-lg-2 d-block">
                        <div className="col-lg-12 col-12 erp_form_col_div">
                            <div className="card">
                                <div className="card-header py-0 main_heading mb-0">
                                    <label className="erp-form-label-md-lg">Beam Inward Details</label>
                                </div>
                                <div className="card-body p-0">{renderSizedBeamStockTable}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="card-footer pb-2 text-center">
                    <MDButton type="button" className="erp-gb-button ms-1" onClick={() => {
                        const path = '/Masters';
                        navigate(path);
                    }} variant="button"
                        fontWeight="regular">Back</MDButton>
                </div >
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>
        </>
    )
}

export default FrmSizedBeamsReport
