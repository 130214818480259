import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $, { error } from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FiDownload } from "react-icons/fi";

// React icons
import { MdRefresh, MdDelete } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";


//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { Link } from "react-router-dom";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";

// Import for the searchable combo box.
import Select from 'react-select';
import { renderToString } from "react-dom/server";
import { use } from "i18next";

export default function FrmMaterialLoanRequisitionEntry() {
  const configConstants = ConfigConstants();
  const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, DEPARTMENT_ID, } = configConstants;

  const { state } = useLocation();
  const { idList, keyForViewUpdate, compType } = state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);
  let docGroup = "Material Loan"

  // For navigate
  const navigate = useNavigate();

  // UseRefs
  const validate = useRef();
  const comboDataAPiCall = useRef();
  const generateAutoNoAPiCall = useRef();
  const exlsExp = useRef();
  const validateNumberDateInput = useRef();
  const navigator = useNavigate();



  // for today date formatted
  const todayDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date().toISOString().split('T')[0];

  //form combo options
  const [companyOptions, setcompanyOptions] = useState([])
  const [fromDepartmentOptions, setFromDepartmentOptions] = useState([])
  const [fromSubDepartmentOptions, setFromSubDepartmentOptions] = useState([])
  const [requisitionOptions, setRequisitionOptions] = useState([])
  const [toDepartmentOptions, setToDepartmentOptions] = useState([])
  const [toSubDepartmentOptions, setToSubDepartmentOptions] = useState([])
  const [receivedOptions, setReceivedOptions] = useState([])
  const [from_cost_centerOptions, setFromCostCenterOptions] = useState([])
  const [to_cost_centerOptions, setToCostCenterOptions] = useState([])


  const [cmb_requisition_by_id, setRequisitionBy] = useState('')
  const [cmb_received_by_id, setReceivedBy] = useState([])

  //Form Fields
  const [materialId, setMaterialId] = useState('')
  const [txt_loan_no, setLoanNo] = useState('')
  const [txt_loan_version, setLoanVersion] = useState('1')
  const [dt_loan_date, setLoanDate] = useState(todayDate)
  const [loanIssue_master_transaction_id, setLoanIssueMasterTransaction_id] = useState(0);
  const [cmb_from_comapany_id, setFromCompanyId] = useState(keyForViewUpdate === "" ? COMPANY_ID : '');
  const [cmb_to_comapany_id, setToCompanyId] = useState('');
  const [cmb_from_department_id, setFromDepartmentId] = useState(keyForViewUpdate === "" ? DEPARTMENT_ID : '');
  const [cmb_to_department_id, setToDepartmentId] = useState('');
  const [cmb_from_Sub_department_id, setFromSubDepartmentId] = useState('');
  const [cmb_to_Sub_department_id, setToSubDepartmentId] = useState('');
  const [txt_balance_quantity, setBalanceQuantity] = useState(0);
  const [txt_requested_quantity, setRequestedQuantity] = useState(0);
  const [cmb_loan_status, setLoanStatus] = useState('P');
  const [cmb_from_cost_center, setFromCostCenter] = useState('');
  const [cmb_to_cost_center, setToCostCenter] = useState('');







  const [materialIndentDetailsData, setMaterialIndentDetailsData] = useState([])
  const [showBomFilterForm, setShowBomFilterForm] = useState(false);



  useEffect(async () => {
    setIsLoading(true);
    try {
      FnSetFieldUserAccess();
      removeSessions('onload');
      await fillCombos();
      if (idList === '' || idList === undefined) {
        await GenerateLoanNo();
      } else {
        await FnCheckUpdateResponse();
      }
      FnSetFieldUserAccess();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [])
  // delete popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');
  const [modalOrderDetails, setModalOrderDetails] = useState('')

  const closeConfirmationModal = async () => {
    setShowSuccessMsgModal(false)
    moveToListing();
  };
  const [showAddRecIndentIssueModal, setshowAddRecIndentIssueModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')
  // Document Form
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = async () => {
    await showDocumentRecords();
    setShowDocumentForm(false)
  };
  const viewDocumentForm = () => {
    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
      setShowDocumentForm(true);
    }
  }

  // doc list
  const [docData, setDocumentData] = useState([]);

  // Show ADD record Modals
  const closeRecordAddIndentIssueModal = async () => {
    // switch (modalHeaderName) {
    //   case 'Indent By':
    //     comboOnChange('departmentId')
    //     break;
    //   case 'Department':
    //     const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
    //     setFromDepartmentOptions(departmentIdApiCall)
    //     break;
    //   case 'Sub Department':
    //     resetGlobalQuery();
    //     globalQuery.columns.push("field_id");
    //     globalQuery.columns.push("field_name");
    //     globalQuery.table = "cmv_department"
    //     globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
    //     globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: cmb_department });
    //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //     const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    //     // setSubDepartmentOptions(subDepartmentApiCall)
    //     break;
    //   case 'Cost Center':
    //     try {
    //       resetGlobalQuery();
    //       globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
    //       globalQuery.table = "fmv_cost_center";
    //       globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    //       globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //       const profitCenterList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
    //       const costCenterOpts = [
    //         { value: '', label: 'Select', centerShortName: '' },
    //         { value: '0', label: 'Add New Record+', centerShortName: '' },
    //         ...profitCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, })),
    //       ];
    //       setCostCenterOptions(costCenterOpts);

    //     } catch (error) {
    //       console.log("error : ", error)
    //     }
    //     break;
    //   default:
    //     break;
    // }
    setshowAddRecIndentIssueModal(false);

  }
  // const deleteRecords = async () => {
  //   try {
  //     const method = { method: 'DELETE' }
  //     const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnDeleteRecord/${txt_material_issue_version}/${COMPANY_ID}/${UserName}?issue_no=${txt_material_issue_no}`, method)
  //     const responce = await deleteApiCall.json();
  //     console.log("indent issue Delete entry: ", responce);
  //     if (responce.success == '1') {
  //       setShow(false)
  //       moveToListing();
  //     }
  //   } catch (error) {
  //     console.log("error: ", error)
  //   }
  // }


  // -------------------------------End Common terms -------------------------------------------------------------------------

  const fillCombos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name'];
      globalQuery.table = "cmv_company";
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        .then(getCompanies => {
          setcompanyOptions(getCompanies)
          console.log("Company List: ", getCompanies);
        });

      // Costcenter List
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
      globalQuery.table = "fmv_cost_center";
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: cmb_from_comapany_id });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        .then((getCostCenterList) => {
          const costCenterOpts = [
            { value: '', label: 'Select', centerShortName: '' },
            // { value: '0', label: 'Add New Record+', centerShortName: '' },
            ...getCostCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, })),
          ];
          setFromCostCenterOptions(costCenterOpts);
          setToCostCenterOptions(costCenterOpts)
        })
      if (keyForViewUpdate === "") {
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_department";
        globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: cmb_from_comapany_id });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const get_departmentList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        const options = [
          { value: '', label: 'Select', },
          // { value: '0', label: 'Add New Record+' },
          ...get_departmentList.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
        ];
        setFromDepartmentOptions(options)
        // await comboOnChange('from_department')
        // setFromDepartmentId(DEPARTMENT_ID)

        // resetGlobalQuery();
        // globalQuery.columns.push("field_id");
        // globalQuery.columns.push("field_name");
        // globalQuery.table = "cmv_department"
        // globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
        // globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: DEPARTMENT_ID });
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: cmb_to_comapany_id });
        // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        // const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        // setFromSubDepartmentOptions(subDepartmentApiCall)

      }
    } catch (error) {
      console.log("error: ", error)
    }
  }
  const FnSetFieldUserAccess = () => {
    if (keyForViewUpdate != '') {
      switch (keyForViewUpdate) {
        case 'view':
          $('input[type="checkbox"]').prop('readonly', true);
          $("input[type=radio]").attr('disabled', true);
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select").attr("disabled", "disabled");
          $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
          break;

        case 'issue':
          $("input[type=radio]").attr('disabled', true);
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select").attr("disabled", "disabled");
          $('.issueField').prop('disabled', false);
          $('table .issueField').removeAttr("disabled");
          break;

        case 'delete':
          $('input[type="checkbox"]').prop('readonly', true);
          $("input[type=radio]").attr('disabled', true);
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select").attr("disabled", "disabled");
          $('#viewdocument-id').hide();
          $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
          break;

        case 'return':
          $('input[type="checkbox"]').prop('readonly', true);
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select").attr("disabled", "disabled");
          $('table .returnField').removeAttr("disabled");
          $('#viewdocument-id').hide();
          break;
      }
    }
  }


  //fn to get po auto no
  const GenerateLoanNo = async () => {
    const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_material_loan_master", "loan_no", "", 'LN', "5");
    if (keyForViewUpdate === '') {
      setLoanNo(autoNoApiCall);
    }
    return autoNoApiCall;
  };
  //-------------------------------------- Combo changes ------------------------------------------------------------
  const comboOnChange = async (key) => {

    switch (key) {
      case 'from_company':
        var fromCompanyValue = document.getElementById('cmb_from_comapany_id').value;
        setFromCompanyId(fromCompanyValue)
        if (fromCompanyValue !== "" && fromCompanyValue !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_department"
          globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: fromCompanyValue });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const get_departmentList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          const options = [
            { value: '', label: 'Select', },
            { value: '0', label: 'Add New Record+' },
            ...get_departmentList.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name, })),
          ];
          setFromDepartmentOptions(options)
          setFromDepartmentId('')
          setFromSubDepartmentOptions([])
          setFromSubDepartmentId('')
          setRequisitionOptions([])
          setRequisitionBy('')
        } else {
          const options = [
            { value: '', label: 'Select', },
            { value: '0', label: 'Add New Record+' },
          ];
          setFromDepartmentOptions(options)
          setFromDepartmentId('')
          setFromSubDepartmentOptions([])
          setFromSubDepartmentId('')
          setRequisitionOptions([])
          setRequisitionBy('')
        }
        break;
      case 'from_department':
        let fromDepartmentValue = formDepartmentComboRef.current.value || '';
        setFromDepartmentId(fromDepartmentValue)
        if (fromDepartmentValue === "0") {
          setHeaderName('Department')
          setshowAddRecIndentIssueModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        }
        if (fromDepartmentValue !== "" && fromDepartmentValue !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_department"
          globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
          globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: fromDepartmentValue });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: document.getElementById('cmb_from_comapany_id').value });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          setFromSubDepartmentOptions(subDepartmentApiCall)
          setRequisitionOptions([])
          setFromSubDepartmentId('')
          setRequisitionBy('')

        } else {
          setFromSubDepartmentOptions([])
          setFromSubDepartmentId('')
          setRequisitionOptions([])
          setRequisitionBy('')
        }
        break;
      case 'fromSubDepartment':

        var subdepartmentIdVal = document.getElementById('cmb_from_Sub_department_id').value;
        setFromSubDepartmentId(subdepartmentIdVal)
        if (subdepartmentIdVal === "0") {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Sub Department')
          setshowAddRecIndentIssueModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        }
        if (subdepartmentIdVal !== "" && subdepartmentIdVal !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_employee";
          globalQuery.conditions.push({ field: "department_id", operator: "=", value: formDepartmentComboRef.current.value });
          globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentIdVal });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: document.getElementById('cmb_from_comapany_id').value });
          const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          const fromDepartmentOptions = [
            { value: '', label: 'Select', },
            ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
          ];
          setRequisitionOptions(fromDepartmentOptions);
          setRequisitionBy('')

        } else {
          const options = [
            { value: '', label: 'Select', },
            { value: '0', label: 'Add New Record+' },
          ];
          setRequisitionOptions(options)
          setRequisitionBy('')
        }
        break;

      case 'from_cost_center':
        let costcenter = fromCostCenterComboRef.current.value || '';
        setFromCostCenter(costcenter)
        $('#error_cmb_from_cost_center').hide();
        if (costcenter === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Cost Center')
          setshowAddRecIndentIssueModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(0).css("padding-top", "0px");
          }, 200)
        }
        break;
      case 'to_cost_center':
        let tocostcenter = toCostCenterComboRef.current.value || '';
        setToCostCenter(tocostcenter)
        $('#error_cmb_to_cost_center').hide();
        if (tocostcenter === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Cost Center')
          setshowAddRecIndentIssueModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(0).css("padding-top", "0px");
          }, 200)
        }
        break;
      case 'to_company':
        var toCompanyValue = document.getElementById('cmb_to_comapany_id').value;
        setToCompanyId(toCompanyValue)
        if (toCompanyValue !== "" && toCompanyValue !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_department"
          globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: toCompanyValue });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const get_departmentList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          const options = [
            { value: '', label: 'Select', },
            { value: '0', label: 'Add New Record+' },
            ...get_departmentList.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
          ];
          setToDepartmentOptions(options)
          setToDepartmentId('')
          setToSubDepartmentOptions([])
          setToSubDepartmentId('')
          setReceivedOptions([])
          setReceivedBy('')
        } else {
          const options = [
            { value: '', label: 'Select', },
            { value: '0', label: 'Add New Record+' },
          ];
          setToDepartmentOptions(options)
          setToDepartmentId('')
          setToSubDepartmentOptions([])
          setToSubDepartmentId('')
          setReceivedOptions([])
          setReceivedBy('')
        }
        break;

      case 'to_department':

        let toDepartmentValue = toDepartmentComboRef.current.value || '';
        setToDepartmentId(toDepartmentValue)
        if (toDepartmentValue === "0") {
          setHeaderName('Department')
          setshowAddRecIndentIssueModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        }
        if (toDepartmentValue !== "" && toDepartmentValue !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_department"
          globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
          globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: toDepartmentValue });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: document.getElementById('cmb_to_comapany_id').value });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          setToSubDepartmentOptions(subDepartmentApiCall)
          setToSubDepartmentId('')
          setReceivedOptions([])
          setReceivedBy('')

        } else {
          setToSubDepartmentOptions([])
          setToSubDepartmentId('')
          setReceivedOptions([])
          setReceivedBy('')
        }
        break;
      case 'toSubDepartment':

        var tosubdepartmentIdVal = document.getElementById('cmb_to_Sub_department_id').value;
        setToSubDepartmentId(tosubdepartmentIdVal)
        if (tosubdepartmentIdVal === "0") {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Sub Department')
          setshowAddRecIndentIssueModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        }
        if (tosubdepartmentIdVal !== "" && tosubdepartmentIdVal !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_employee";
          globalQuery.conditions.push({ field: "department_id", operator: "=", value: toDepartmentComboRef.current.value });
          globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: tosubdepartmentIdVal });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: document.getElementById('cmb_to_comapany_id').value });
          const receivedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          const getReceivedOptions = [
            { value: '', label: 'Select', },
            ...receivedbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
          ]
          setReceivedOptions(getReceivedOptions);
          setReceivedBy('')
        } else {
          const options = [
            { value: '', label: 'Select', },
            // { value: '0', label: 'Add New Record+' },
          ];
          setReceivedOptions(options)
          setReceivedBy('')
        }
        break;
      default:
        break;
    }
  }

  const displayRecordIndentIssueComponent = () => {
    switch (modalHeaderName) {
      case 'Indent By':
        return <FrmMEmployeesEntry />;

      case 'Department':
        return <FrmDepartmentEntry />;

      case 'Material Type':
        return <FrmMProductTypeEntry />;

      case 'Sub Department':
        const props = {}
        // const props = { btn_disabled: true, departmentType: "S", parent_department: cmb_department, parentDepIs_disable: true, deptTypeIs_disable: true };
        return <FrmDepartmentEntry {...props} />;

      case 'Cost Center':
        return <MCostCenterEntry btn_disabled={true} />;
      default:
        return null;
    }
  }



  //--------------------------------------------------------Start Details section----------------------------------------------------------------------

  const handleCloseProductModalForm = () => { setShowBomFilterForm(false) };


  const removeSessions = (reloadData) => {
    sessionStorage.removeItem(`comboFilterData`)
    sessionStorage.removeItem('isComboFilterExist')
    sessionStorage.removeItem('smv_product_type');
    sessionStorage.removeItem('smv_product_material_type');
    sessionStorage.removeItem('smv_product_material_grade');
    sessionStorage.removeItem('smv_product_material_shape');
    if (reloadData === 'onload') {
      sessionStorage.removeItem('filteredMaterialData')
    }
    for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
      sessionStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
    }

    localStorage.removeItem('smv_product_material_type');
    localStorage.removeItem('smv_product_material_grade');
    localStorage.removeItem('smv_product_material_shape');
    localStorage.removeItem('smv_product_type');

    localStorage.removeItem('smv_product_material_typeName');
    localStorage.removeItem('smv_product_material_gradeName');
    localStorage.removeItem('smv_product_material_shapeName');
    localStorage.removeItem('smv_product_typeName');
  }

  const categoryDisplay = () => {

    const catCount = sessionStorage.getItem('compRMCatCount')
    for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
      $('.cat' + catCountIndex + 'Id').show();
    }
  }

  const FnClearFormFields = async () => {

    sessionStorage.removeItem('filteredMaterialData')
    setMaterialIndentDetailsData([])
    // setFromDepartmentOptions([])
    setFromSubDepartmentOptions([])
    setRequisitionOptions([])
    setToDepartmentOptions([])
    setToSubDepartmentOptions([])
    setReceivedOptions([]) //  requested to options employees



    formDepartmentComboRef.current.value = "";
    toDepartmentComboRef.current.value = "";
    receivedByComboRef.current.value = "";
    requestedByComboRef.current.value = "";
    // setFromCompanyId('')
    setFromDepartmentId('')
    setFromSubDepartmentId('')
    setToCompanyId('')
    setToDepartmentId('')
    setToSubDepartmentId('')
    setReceivedBy('')
    setRequisitionBy('')
    setMaterialId('')
    setBalanceQuantity(0)
    setRequestedQuantity(0)
    setFromCostCenter('')
    setToCostCenter('')
  }

  const moveToListing = () => {
    const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/MaterialLoanRequisition/FrmMaterialLoanRequisitionList/reg' : `/Transactions/TPurchaseOrder/MaterialLoanRequisition/FrmMaterialLoanRequisitionList`;
    navigate(Path);
  }

  const refreshData = async (key) => {

    switch (key) {
      // case 'get_employee':
      //   resetGlobalQuery();
      //   globalQuery.columns.push("field_id");
      //   globalQuery.columns.push("field_name");
      //   globalQuery.table = "cmv_employee"
      //   globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department });
      //   globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: cmb_sub_department });
      //   globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      //   globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
      //   globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //   const getEmployeesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      //   const indentedByOpts = [
      //     { value: '', label: 'Select', },
      //     { value: '0', label: 'Add New Record+', },
      //     ...getEmployeesApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
      //   ];
      //   setRequisitionOptions(indentedByOpts);
      //   break;
      // case 'get_department':
      //   const customerOrderIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
      //   const options = [
      //     { value: '', label: 'Select' },
      //     { value: '0', label: 'Add New Record+' },
      //     ...customerOrderIdApiCall.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name, })),
      //   ];
      //   setFromDepartmentOptions(options);
      //   break;
      default:
        break;
    }
  }

  $(document).on('click', '.remove_input_zero', function () {
    if ($(this).val() === "0") {
      $(this).val("")
    }
  });
  $(document).on('mouseup mousedown', function (e) {
    let inputBoxes = $(".remove_input_zero");
    inputBoxes.each(function () {
      if ($(this).val() === "") {
        $(this).val("0");
      }
    });
  });



  //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
  const FnLoadAccordionData = async (eventKey) => {
    switch (eventKey) {
      case 'documentList':
        await showDocumentRecords();
        break;
      default:
        break;
    }
  }
  //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
  // Fn for get document list
  const showDocumentRecords = async () => {
    try {
      if (COMPANY_ID !== null) {
        const res = await fetch(
          // `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null}`
        );
        const resp = await res.json();
        if (resp.content.length > 0) {
          setDocumentData(resp.content);
        }
      }

    } catch (error) {
      console.log("error: ", error)
    }
  }

  // Fn for get document table
  const renderDocumentTable = useMemo(() => {
    return <>
      <Table className="erp_table " id='document-table' responsive bordered striped>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Group Id</th>
            <th className="erp_table_th">Document Group</th>
            <th className="erp_table_th">Document Name</th>
            <th className="erp_table_th">Registration No</th>
            <th className="erp_table_th">Registration Date</th>
            <th className="erp_table_th">Renewal Date</th>
            <th className="erp_table_th">Document Path</th>
            <th className="erp_table_th">Remark</th>
            <th className="erp_table_th">File Name</th>
          </tr>
        </thead>
        <tbody>
          {
            docData.map((docItem, Index) =>
              <tr rowIndex={Index}>
                <td className="erp_table_td"> {docItem.group_id} </td>
                <td className="erp_table_td">{docItem.document_group}</td>
                <td className="erp_table_td">{docItem.document_name}</td>
                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_registration_date)}</td>
                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_renewal_date)}</td>
                <td className="erp_table_td ">{docItem.document_path}</td>
                <td className="erp_table_td ">{docItem.remark}</td>
                <td className="erp_table_td">
                  <MDTypography
                    component="label"
                    className="erp-form-label"
                    variant="button"
                    id="logoFile"
                    fontWeight="regular"
                    color="info"
                    onClick={() => fetchDocument(docItem)}
                    style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
                    onMouseEnter={(e) => e.target.style.color = 'info'} // Change color on hover
                    onMouseLeave={(e) => e.target.style.color = 'initial'} // Restore original color when not hovering
                  >
                    {docItem.file_name}
                  </MDTypography>
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </>
  }, [docData]);

  // Fn for get document download
  const fetchDocument = async (docItem) => {
    try {
      const formData = new FormData()
      const data = {
        document_group: docItem.documentGroup,
        group_id: docItem.group_id,
        document_path: docItem.document_path
      }
      formData.append(`getFile`, JSON.stringify(data))
      const requestOptions = {
        method: 'POST',
        body: formData
      };

      const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
      const blob = await getDocApiCall.blob()

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${docItem.file_name}`,);
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);


    } catch (error) {
      console.log("error: ", error)
    }
  }

  //key for get opration
  const ActionType = () => {
    switch (keyForViewUpdate) {
      case 'approve':
        return '(Approval)';
      case 'update':
        return '(Modification)';
      case 'view':
        return '(View)';
      case 'issue':
        return '(Issue)';
      case 'delete':
        return '(Delete)';
      case 'return':
        return '(Return)';
      default:
        return '(Creation)';
    }
  }

  const validateFields = () => {
    validate.current.validateFieldsOnChange('loan_issue_form_id')
  }

  let requiredCols = ['product_material_name', 'product_material_id', 'product_type_group', 'stock_quantity', 'stock_weight', 'product_unit_name', 'product_material_std_weight', 'godown_name', 'godown_section_name', 'godown_section_beans_name',
    'godown_id', 'godown_section_id', 'godown_section_beans_id', 'product_material_unit_id'
  ];




  const handleCloseBomFilterForm = async () => {
    try {
      debugger
      setIsLoading(true)
      const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
      if (getData.length !== 0) {
        let material_id = getData[0].product_material_id;
        setMaterialId(material_id)
        let balance_quantity = getData[0].stock_quantity;
        if (balance_quantity !== null && balance_quantity !== "") {
          setBalanceQuantity(balance_quantity)
          setRequestedQuantity(0)
        } else {
          setBalanceQuantity(0)
          setRequestedQuantity(0)
        }
      }

      // resetGlobalQuery();
      // globalQuery.columns.push("sum(product_material_grn_accepted_quantity) - sum(product_material_issue_quantity) as balance_quantity");
      // globalQuery.table = "stv_indent_details"
      // globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: material_id });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: cmb_to_comapany_id, });//show company Stock of requested company
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // let getIndentDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      // console.log("getIndentDetails: ", getIndentDetails);
      // if (getIndentDetails.length !== 0) {
      //   let balance_quantity = getIndentDetails[0].balance_quantity;
      //   if (balance_quantity !== null && balance_quantity !== "") {
      //     setBalanceQuantity(balance_quantity)
      //     setRequestedQuantity(0)
      //   } else {
      //     setBalanceQuantity(0)
      //     setRequestedQuantity(0)
      //   }
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }


  const fnManageIssueStatus = (detailData) => {
    const item = detailData.find(item => item.loan_item_status === 'MI' || item.loan_item_status === 'IR');
    if (item) {
      setLoanStatus(item.loan_item_status);
    } else {
      setLoanStatus('R');
    }

  };
  const handleIssueDetailsInputChange = (currentRowData, event) => {
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
    let focusedInputField = document.querySelector('#' + event.target.id);
    let clickedColName = event.target.getAttribute('Headers');
    let { product_material_requested_quantity, product_material_std_weight, product_material_issue_quantity, prev_product_material_return_quantity } = currentRowData;
    let enteredValue = event.target.value;
    if (isNaN(enteredValue)) {
      enteredValue = 0;
    } else if (enteredValue != 0 || enteredValue != "") {
      delete focusedInputField.parentElement.dataset.tip;
    }
    switch (clickedColName) {
      case 'product_material_issue_quantity':
        if (enteredValue !== "") {
          if (parseInt(enteredValue) > parseInt(product_material_requested_quantity)) {
            focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${product_material_requested_quantity}...!`;;
            return false;
          } else {
            currentRowData[clickedColName] = enteredValue;
            currentRowData.product_material_issue_weight = validateNumberDateInput.current.decimalNumber(enteredValue * product_material_std_weight, 4);
            delete focusedInputField.parentElement.dataset.tip;
          }
        } else {
          currentRowData[clickedColName] = enteredValue;
          focusedInputField.parentElement.dataset.tip = `Please Enter Quantity...!`;;
        }
        break;
      case 'issue_remark':
        currentRowData[clickedColName] = event.target.value;

        break;
      case 'godown_id':
        debugger
        let product_material_id = currentRowData.product_material_id;
        let selectedGodownIDs = '';
        let checkedLenght = 0;
        $(`input:checkbox[name=chk_godown_id_${product_material_id}]:checked`).each(function () {
          let godownID = parseInt($(this).val());
          if (selectedGodownIDs !== '') {
            selectedGodownIDs += ',';
          }
          selectedGodownIDs += godownID;
          checkedLenght += 1;
        })
        currentRowData[clickedColName] = selectedGodownIDs;
        delete event.target.parentElement.parentElement.parentElement.dataset.tip
        $(`#godown_selectedCount_id_${product_material_id}`).text(``);
        $(`#godown_selectedCount_id_${product_material_id}`).text(`${checkedLenght} selected`);
        break;
      case 'product_material_return_quantity':
        if (enteredValue !== "") {
          let maxreturn_qty = parseFloat(product_material_issue_quantity) - parseFloat(prev_product_material_return_quantity);
          if (parseFloat(enteredValue) > maxreturn_qty) {
            focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${maxreturn_qty}...!`;;
            return false;
          } else {
            currentRowData[clickedColName] = enteredValue;
            currentRowData.product_material_return_weight = validateNumberDateInput.current.decimalNumber(enteredValue * product_material_std_weight, 4);
            delete focusedInputField.parentElement.dataset.tip;

            if ((parseFloat(enteredValue) + parseFloat(prev_product_material_return_quantity)) === parseFloat(product_material_issue_quantity)) {
              currentRowData.loan_item_status = 'R';
            } else {
              currentRowData.loan_item_status = 'IR';
            }
          }
        } else {
          currentRowData[clickedColName] = enteredValue;
          focusedInputField.parentElement.dataset.tip = `Please Enter Quantity...!`;;
        }
        break;
      default:
        break;
    }
    const detailData = [...materialIndentDetailsData]
    detailData[arrayIndex] = currentRowData;
    setMaterialIndentDetailsData(detailData);
    if (keyForViewUpdate === "return") {
      fnManageIssueStatus(detailData);
    }
  }

  // Get godown List material wise
  const FnshowGodownsForMaterial = (issueItem, product_material_id) => {
    if (issueItem.godownOptions.dataArray.length !== 0) {
      let listItems = issueItem.godownOptions.dataArray.map((item, index) => {
        return (
          <label class="checkbox"><input type="checkbox" className="issueField" value={item.godown_id} id={`chk_godown_id_${product_material_id}_${item.godown_id}`} Headers='godown_id'
            name={`chk_godown_id_${product_material_id}`} closing_balance_quantity={item.closing_balance_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} />
            <span className="pl-2">{`${item.godown_name} Balance: ${item.closing_balance_quantity}`}</span></label>
        );
      });
      // $(`#godown_selectedCount_id_${product_material_id}`).text(`0 selected`);
      return listItems;
    } else {
      return (
        <div>
          <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
        </div>
      );
    }
  }

  //details table
  const renderDetailsTable = useMemo(() => {
    return <>
      <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Sr.no</th>
            <th className="erp_table_th">Material Name</th>
            {/* <th className={`erp_table_th `} style={{ textAlign: 'center', paddingRight: "190px" }}>Indent No</th> */}
            <th className="erp_table_th">Balance Quantity</th>
            <th className="erp_table_th">Requested Quantity</th>
            <th className="erp_table_th">Issue Quantity</th>
            {/* <th className={`erp_table_th ${keyForViewUpdate === "issue" || keyForViewUpdate === "return" || (keyForViewUpdate === "view" && cmb_loan_status !== "P") ? 'display' : 'd-none'}`} style={{ textAlign: 'center', paddingRight: "100px" }}>Material Godown</th> */}
            <th className="erp_table_th">Issue Remark</th>
            <th className={`erp_table_th ${keyForViewUpdate === "return" ? 'display' : 'd-none'}`}>Prev Return Quantity</th>
            <th className="erp_table_th">Return Quantity</th>
            <th className={`erp_table_th `} style={{ textAlign: 'center', paddingRight: "100px" }}>Item Status</th>
            <th className="erp_table_th">Balance Weight</th>
            <th className="erp_table_th">Requested Weight</th>
            <th className="erp_table_th">Issue Weight</th>
            <th className="erp_table_th">Return Weight</th>
            <th className="erp_table_th">Std Weight</th>
          </tr>
        </thead>
        <tbody>
          {materialIndentDetailsData.map((issueItem, Index) =>
            <tr rowindex={Index} className="sticky-column">
              <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
              <td className="erp_table_td "> {issueItem.product_material_name} </td>
              {/* <td className="erp_table_td "> {issueItem.indent_no} </td> */}
              <td className={`erp_table_td text-end sticky-column`}>{issueItem.product_material_balance_quantity}</td>
              <td className="erp_table_td text-end">
                {/* {keyForViewUpdate === '' ?
                  <input type="text" id={`product_material_requested_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0" max_indent_quantity={issueItem.max_indent_quantity}
                    value={issueItem.product_material_requested_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='product_material_requested_quantity' />
                  : */}
                {issueItem.product_material_requested_quantity}
                {/* } */}
              </td>
              <td className="erp_table_td text-end">
                {keyForViewUpdate === 'issue' ?
                  <input type="text" id={`product_material_issue_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                    value={issueItem.product_material_issue_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='product_material_issue_quantity' />
                  :
                  issueItem.product_material_issue_quantity
                }
              </td>
              {/* {keyForViewUpdate === "issue" || keyForViewUpdate === "return" || (keyForViewUpdate === "view" && cmb_loan_status !== "P") ?
                <td className={`erp_table_td`}>
                  <div className={`erp_dropdown`} onClick={(e) => { FnToggleDropdown('displayGodownList', `${issueItem.product_material_id}`) }}>
                    <span className={`form-select form-select-s`} id={`godown_selectedCount_id_${issueItem.product_material_id}`}> </span>
                    <div class="erp_dropdown-content" id={`godown_id_${issueItem.product_material_id}`}>
                      {FnshowGodownsForMaterial(issueItem, issueItem.product_material_id)}
                    </div>
                  </div>
                </td>
                :
                null
              } */}
              <td className="erp_table_td">
                {keyForViewUpdate === 'issue' ?
                  <input type="text" id={`issue_remark_${issueItem.product_material_id}`} className="erp_input_field mb-0 issueField"
                    value={issueItem.issue_remark} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='issue_remark' />
                  :
                  issueItem.issue_remark
                }
              </td>
              <td className={`erp_table_td text_end ${keyForViewUpdate === "return" ? 'display' : 'd-none'}`}>{issueItem.prev_product_material_return_quantity}</td>
              <td className="erp_table_td text-end">
                {keyForViewUpdate === 'return' ?
                  <input type="text" id={`product_material_return_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end  mb-0 returnField"
                    value={issueItem.product_material_return_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='product_material_return_quantity' />
                  :
                  issueItem.product_material_return_quantity
                }
              </td>
              <td className="erp_table_td">
                <select id={`loan_item_status_${issueItem.product_material_id}`} disabled value={issueItem.loan_item_status}
                  className="form-select form-select-sm mb-0" Headers='loan_item_status' onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }}>
                  <option value="P">Pending</option>
                  <option value="A">Approved</option>
                  <option value="I">Partial Issue</option>
                  <option value="MI">Material Issued</option>
                  <option value="C">Completed</option>
                  <option value="R">Received</option>
                  <option value="IR">Partial Received</option>
                </select>
              </td>
              <td className="erp_table_td text-end">{issueItem.product_material_balance_weight}</td>
              <td className="erp_table_td text-end">{issueItem.product_material_requested_weight}</td>
              <td className={`erp_table_td text-end `}>{issueItem.product_material_issue_weight}</td>
              <td className={`erp_table_td text-end `}>{issueItem.product_material_return_weight}</td>
              <td className={`erp_table_td text-end `}>{issueItem.product_material_std_weight}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>

  }, [materialIndentDetailsData])




  // calculate quantities and get indent details
  const FnCalculatePurchaseDetails = async () => {
    debugger;
    let headerDataIsValid = await validate.current.validateForm("loan_issue_form_id");
    if (headerDataIsValid) {
      let totalIssueQuantity = $('#txt_requested_quantity').val() || 0;
      totalIssueQuantity = parseFloat(totalIssueQuantity);
      try {
        setIsLoading(true)
        if (materialId !== "") {
          // resetGlobalQuery();
          // globalQuery.columns.push("*");
          // globalQuery.table = "stv_indent_details";
          // globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: materialId });
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: cmb_to_comapany_id });
          // globalQuery.conditions.push({ field: "indent_item_status", operator: "IN", values: ["I", "G"] });
          // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

          // let indentDetailsRecords = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          // console.log("indentDetailsRecords: ", indentDetailsRecords);

          const indentDetailsRecords = JSON.parse(sessionStorage.getItem('filteredMaterialData'))
          console.log("indentDetailsRecords: ", indentDetailsRecords);
          setMaterialIndentDetailsData(prevArray => {
            // if material get added so remove existing material indent details
            const filteredArray = prevArray.filter(prevItem => { return materialId !== prevItem.product_material_id; });

            let calculatedindentDetails = [];
            for (let i = 0; i < indentDetailsRecords.length; i++) {
              let item = indentDetailsRecords[i];
              item.product_material_issue_quantity = 0;
              item.product_material_issue_weight = 0;
              item.product_material_return_quantity = 0;
              item.product_material_return_weight = 0;
              // item.product_material_name = item.product_material_name;
              // item.product_std_weight = item.product_material_std_weight;
              item.product_material_balance_quantity = item.stock_quantity;
              item.product_material_balance_weight = item.product_material_balance_quantity * item.product_material_std_weight;

              if (totalIssueQuantity > 0 && item.product_material_balance_quantity > 0) {
                if (totalIssueQuantity > item.product_material_balance_quantity) {
                  item.product_material_requested_quantity = item.product_material_balance_quantity;
                  item.product_material_requested_weight = item.product_material_requested_quantity * item.product_material_std_weight;
                  totalIssueQuantity -= item.product_material_balance_quantity;
                } else {
                  item.product_material_requested_quantity = totalIssueQuantity;
                  item.product_material_requested_weight = item.product_material_requested_quantity * item.product_material_std_weight;
                  totalIssueQuantity = 0;
                }
                calculatedindentDetails.push(item);
              }
            }
            return [...filteredArray, ...calculatedindentDetails];
          });
        } else {
          setMaterialIndentDetailsData([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false)
      }
    }

  };
  // Function to handle validate numeric inputs
  const validateMaterialDetails = (quantity) => {
    const numCheck = /^\d{0,18}(?:\.\d{0,4})?$/;
    if (!numCheck.test(quantity)) {
      return false;
    } else {
      return true;
    }
  }

  // Validate total issue quantity
  const FnvalidateTotalIssueQuantity = (issueQuantity) => {
    try {
      const parsedIssueQuantity = parseFloat(issueQuantity);
      if (isNaN(parsedIssueQuantity) || parsedIssueQuantity <= 0) {
        throw new Error('Please enter a valid number greater than 0!');
      }
      if (parsedIssueQuantity > parseFloat(txt_balance_quantity)) {
        throw new Error('Issue quantity cannot exceed the balance quantity!');
      }
      // Clear any previous error messages
      $('#error_txt_requested_quantity').hide().text('');

      // If all validations pass, update state or perform other actions
      setRequestedQuantity(parsedIssueQuantity);
    } catch (error) {
      // Display error message
      $('#error_txt_requested_quantity').text(error.message).show();
      setRequestedQuantity(0);
    }
  };
  //-------------------------------- validate before save ------------------------------------------------------


  const FnValidateLoanIssue = async () => {

    debugger
    let headerDataIsValid = await validate.current.validateForm("loan_issue_form_id");
    if (!headerDataIsValid) { return false; }
    let issueDetailsIsValid = true;
    let addedIssuematerial = $('#issue-details-tbl tbody tr').length;
    if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
      setErrMsg('Please Add Atleast One Material Detail.');
      setShowErrorMsgModal(true);
      return issueDetailsIsValid = false;
    }
    // else {
    //   return issueDetailsIsValid;
    // } 
    else {
      const tableRows = $('#issue-details-tbl tbody tr');
      issueDetailsIsValid = true;
      tableRows.each(function () {
        if (keyForViewUpdate === "issue") {
          const issueQty = parseFloat($(this).find('input[id^="product_material_issue_quantity_"]').val());
          // const indentDetailsId = $(this).find('.erp_dropdown-content').attr('id').split('_').pop();
          // const checkboxes = $(`#godown_id_${indentDetailsId} input[type="checkbox"]:checked`);

          if (isNaN(issueQty) || issueQty === "" || issueQty === "0") {
            $(this).find(`input[id^='product_material_issue_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
            $(this).find(`input[id^='product_material_issue_quantity_']`).focus();
            setOpenIssueDetailsAccord(true)
            issueDetailsIsValid = false;
            return false;
          }
          // else if (issueQty !== 0) {
          //   if (checkboxes.length === 0) {
          //     $(this).find('.erp_dropdown').attr('data-tip', 'Please check at least one godown...!');
          //     $(this).find('.erp_dropdown').focus();
          //     setOpenIssueDetailsAccord(true);
          //     issueDetailsIsValid = false;
          //     return false;
          //   }
          // }
        } if (keyForViewUpdate === "return") {
          const returnQty = parseFloat($(this).find('input[id^="product_material_return_quantity_"]').val());

          if (isNaN(returnQty) || returnQty === "" || returnQty === "0") {
            $(this).find(`input[id^='product_material_return_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
            $(this).find(`input[id^='product_material_return_quantity_']`).focus();
            setOpenIssueDetailsAccord(true)
            issueDetailsIsValid = false;
            return false;
          }
        }
      });
      return issueDetailsIsValid;
    }
  }


  //-------------------------- Save Json ------------------------------------------------------------------

  const addLoanIssue = async (status) => {
    debugger
    try {
      let checkIsValidate = true;
      if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
        checkIsValidate = await FnValidateLoanIssue();
      }
      if (checkIsValidate === true) {
        setIsLoading(true)
        let lastLoanIssueNo = txt_loan_no;
        if (keyForViewUpdate === "") {
          lastLoanIssueNo = await GenerateLoanNo();
        }
        let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }

        // TransHeaderData
        const loanIssueMasterFormData = {
          material_loan_master_id: keyForViewUpdate !== "" ? loanIssue_master_transaction_id : 0,
          // company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          loan_no: lastLoanIssueNo,
          loan_date: dt_loan_date,
          loan_version: txt_loan_version,
          from_company_id: cmb_from_comapany_id,
          from_company_branch_id: '',
          from_department_id: cmb_from_department_id,
          from_sub_department_id: cmb_from_Sub_department_id,
          requested_by_id: cmb_requisition_by_id,
          to_company_id: cmb_to_comapany_id,
          to_company_branch_id: '',
          to_department_id: cmb_to_department_id,
          to_sub_department_id: cmb_to_Sub_department_id,
          received_by_id: cmb_received_by_id,
          material_loan_status: cmb_loan_status,
          from_cost_center_id: cmb_from_cost_center,
          to_cost_center_id: cmb_to_cost_center,
          created_by: UserName,
          modified_by: idList !== "" ? UserName : null,
          // is_active: '1',
        }
        json.TransHeaderData = loanIssueMasterFormData;

        for (let issueItem = 0; issueItem < materialIndentDetailsData.length; issueItem++) {
          const indentIssueDetailsRow = materialIndentDetailsData[issueItem];

          const IndentIssueRow = {};
          IndentIssueRow['company_id'] = COMPANY_ID
          // IndentIssueRow['company_branch_id'] = COMPANY_BRANCH_ID
          IndentIssueRow['financial_year'] = FINANCIAL_SHORT_NAME
          IndentIssueRow['material_loan_details_id'] = keyForViewUpdate !== "" ? indentIssueDetailsRow.material_loan_details_id : 0
          IndentIssueRow['loan_no'] = lastLoanIssueNo
          IndentIssueRow['loan_date'] = dt_loan_date
          IndentIssueRow['loan_version'] = txt_loan_version
          IndentIssueRow['indent_no'] = ''
          IndentIssueRow['indent_date'] = ''
          IndentIssueRow['indent_version'] = ''
          IndentIssueRow['indent_details_id'] = ''
          IndentIssueRow['product_material_id'] = indentIssueDetailsRow.product_material_id
          IndentIssueRow['product_material_unit_id'] = indentIssueDetailsRow.product_material_unit_id
          IndentIssueRow['product_material_balance_quantity'] = indentIssueDetailsRow.product_material_balance_quantity
          IndentIssueRow['product_material_balance_weight'] = indentIssueDetailsRow.product_material_balance_weight
          IndentIssueRow['product_material_requested_quantity'] = indentIssueDetailsRow.product_material_requested_quantity
          IndentIssueRow['product_material_requested_weight'] = indentIssueDetailsRow.product_material_requested_weight
          IndentIssueRow['product_material_issue_quantity'] = indentIssueDetailsRow.product_material_issue_quantity
          IndentIssueRow['product_material_issue_weight'] = indentIssueDetailsRow.product_material_issue_weight
          IndentIssueRow['loan_item_status'] = indentIssueDetailsRow.loan_item_status
          IndentIssueRow['issue_remark'] = indentIssueDetailsRow.issue_remark
          IndentIssueRow['material_loan_master_id'] = keyForViewUpdate !== "" ? loanIssue_master_transaction_id : 0
          IndentIssueRow['created_by'] = keyForViewUpdate === "" ? UserName : indentIssueDetailsRow.created_by
          IndentIssueRow['modified_by'] = keyForViewUpdate !== "" ? UserName : null
          IndentIssueRow['issue_batch_no'] = indentIssueDetailsRow.issue_batch_no
          IndentIssueRow['godown_id'] = indentIssueDetailsRow.godown_id
          IndentIssueRow['product_material_std_weight'] = indentIssueDetailsRow.product_material_std_weight
          IndentIssueRow['product_material_return_quantity'] = (parseFloat(indentIssueDetailsRow.prev_product_material_return_quantity) + parseFloat(indentIssueDetailsRow.product_material_return_quantity));
          IndentIssueRow['product_material_return_weight'] = (parseFloat(indentIssueDetailsRow.prev_product_material_return_weight) + parseFloat(indentIssueDetailsRow.product_material_return_weight));
          // IndentIssueRow['godown_section_id'] = indentIssueDetailsRow.godown_section_id
          // IndentIssueRow['godown_section_beans_id'] = indentIssueDetailsRow.godown_section_beans_id
          json.TransDetailData.push(IndentIssueRow);
        }
        // Common Ids
        json.commonIds.company_id = COMPANY_ID
        json.commonIds.loan_no = keyForViewUpdate === '' ? lastLoanIssueNo : txt_loan_no
        json.commonIds.financial_year = FINANCIAL_SHORT_NAME
        console.log(json);
        const formData = new FormData();
        formData.append(`PtMaterialLoanData`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        let addOrIssued = "add";
        if (status === 'MI') {
          addOrIssued = "issue";
        } else if (status === "R") {
          addOrIssued = "return";
        }

        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtMaterialLoanDetails/FnAddUpdateRecord/${addOrIssued}`, requestOptions)
        const responce = await apicall.json();

        if (responce.success === "0") {
          $('#save-btn').prop("disable", true);
          $('#issue-btn').prop("disable", true);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          if (responce.data.hasOwnProperty('loan_no') && responce.data.hasOwnProperty('loan_version')) {
            setModalOrderDetails(`Loan No: ${responce.data.loan_no} and Version: ${responce.data.loan_version}`)
          }
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
        }
        setIsLoading(false)
      }
    } catch (error) {
      console.log("error: ", error)
    } finally {
      setIsLoading(false)
    }

  }
  // ------------------------------------------- set update data ----------------------------------------------------


  const FnCheckUpdateResponse = async () => {

    try {
      debugger
      if (idList !== '') {

        setLoanNo(idList.issue_no)
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtMaterialLoanDetails/FnShowAllDetailsAndMastermodelRecords/${idList.loan_version}/${idList.financial_year}/${COMPANY_ID}?loan_no=${idList.loan_no}`)
        const responce = await apiCall.json();
        console.log('Material loan data: ', responce);
        let loanMasterdata = responce.loanIssueMasterRecords;
        if (loanMasterdata !== null && loanMasterdata !== "") {
          let loanStatus = loanMasterdata.material_loan_status;
          setLoanIssueMasterTransaction_id(loanMasterdata.material_loan_master_id)
          setLoanNo(loanMasterdata.loan_no)
          setLoanVersion(loanMasterdata.loan_version)
          setLoanDate(loanMasterdata.loan_date)
          setFromCompanyId(loanMasterdata.from_company_id)
          await comboOnChange("from_company")
          formDepartmentComboRef.current.value = loanMasterdata.from_department_id
          setFromDepartmentId(loanMasterdata.from_department_id)
          await comboOnChange("from_department")
          setFromSubDepartmentId(loanMasterdata.from_sub_department_id)
          await comboOnChange("fromSubDepartment")
          requestedByComboRef.current.value = loanMasterdata.requested_by_id
          setRequisitionBy(loanMasterdata.requested_by_id)
          setFromCostCenter(loanMasterdata.from_cost_center_id)
          setToCompanyId(loanMasterdata.to_company_id)
          await comboOnChange("to_company")
          toDepartmentComboRef.current.value = loanMasterdata.to_department_id
          setToDepartmentId(loanMasterdata.to_department_id)
          await comboOnChange("to_department")
          setToSubDepartmentId(loanMasterdata.to_sub_department_id)
          await comboOnChange("toSubDepartment")
          receivedByComboRef.current.value = loanMasterdata.received_by_id
          setReceivedBy(loanMasterdata.received_by_id)
          setToCostCenter(loanMasterdata.to_cost_center_id)
          if (keyForViewUpdate === "issue") {
            setLoanStatus('MI')
          } else if (keyForViewUpdate === "return") {
            setLoanStatus('R')
          } else {
            setLoanStatus(loanMasterdata.material_loan_status)
          }


          if (responce.loanIssueDetailsRecords != null) {
            debugger
            let updatedLoanDetailsDataArray = await Promise.all(responce.loanIssueDetailsRecords.map(async item => {
              // const godownOptions = {
              //   dataArray: []    // new array for if there is combo 
              // };
              // if (keyForViewUpdate === "issue" || keyForViewUpdate === "return" || (keyForViewUpdate === "view" && loanStatus !== "P")) {
              //   resetGlobalQuery();
              //   globalQuery.columns = ['godown_name', 'closing_balance_quantity', 'godown_id'];
              //   globalQuery.table = "smv_product_rm_stock_summary"
              //   globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
              //   globalQuery.conditions.push({ field: "product_rm_id", operator: "=", value: item.product_material_id });
              //   globalQuery.conditions.push({ field: "closing_balance_quantity", operator: ">", value: 0 });
              //   globalQuery.conditions.push({ field: "company_id", operator: "=", value: loanMasterdata.to_company_id });
              //   let getGodownDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

              //   if (keyForViewUpdate === "view" && loanStatus !== "P") {
              //     let godownIDs = item.godown_id.split(",");
              //     const godownData = getGodownDetailsApiCall.filter(item => godownIDs.includes(item.godown_id.toString()));
              //     if (godownData.length > 0) {
              //       // If any matching data is found, push it into dataArray
              //       godownData.forEach(data => {
              //         godownOptions.dataArray.push(data);
              //       });
              //     }
              //   } else {
              //     godownOptions.dataArray = getGodownDetailsApiCall;

              //   }
              // }
              // Customizing item based on keyForViewUpdate
              const customizedItem = {
                ...item,
                prev_product_material_return_quantity: 0,
                prev_product_material_return_weight: 0,
                // godownOptions: keyForViewUpdate === "return" || keyForViewUpdate === "issue" || (keyForViewUpdate === "view" && loanStatus !== "P") ? godownOptions : null
              };
              if (keyForViewUpdate === 'issue') {
                let balance_qty = parseFloat(item.product_material_requested_quantity);
                customizedItem.loan_item_status = "MI";

                //to show issue qty 
                if (balance_qty > parseFloat(item.product_material_stock_quantity)) {
                  customizedItem.product_material_issue_quantity = parseFloat(item.product_material_stock_quantity);
                } else if (balance_qty === parseFloat(item.product_material_stock_quantity)) {
                  customizedItem.product_material_issue_quantity = balance_qty;
                } else {
                  customizedItem.product_material_issue_quantity = balance_qty;
                }
                // Calculate issue quantity weight
                customizedItem.product_material_issue_weight = customizedItem.product_material_issue_quantity !== 0 ?
                  customizedItem.product_material_issue_quantity * item.product_material_std_weight : 0;
                customizedItem.issue_item_status = "MI";

              } else if (keyForViewUpdate === 'return') {
                debugger
                customizedItem.loan_item_status = "R";
                customizedItem.prev_product_material_return_quantity = item.product_material_return_quantity;
                customizedItem.prev_product_material_return_weight = item.product_material_return_weight;
                customizedItem.product_material_return_quantity = item.product_material_issue_quantity - item.product_material_return_quantity;
                customizedItem.product_material_return_weight = item.product_material_issue_weight - item.product_material_return_weight;
              }

              return customizedItem;
            }));
            setMaterialIndentDetailsData(updatedLoanDetailsDataArray)
            // if ((keyForViewUpdate === 'view' && loanStatus !== "P")) {
            //   updatedLoanDetailsDataArray.forEach((item) => {
            //     if (item.godownOptions !== null && item.godownOptions !== "") {
            //       isGodownCheckBoxChk(item.godownOptions, item.product_material_id);
            //     } else {
            //       $(`#godown_selectedCount_id_${item.product_material_id}`).text(`0 selected`)
            //     }
            //   })
            // }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Define a function to determine whether a checkbox should be checked
  const isGodownCheckBoxChk = (godownOptions, product_material_id) => {
    debugger
    const checkboxes = document.querySelectorAll(`input[type="checkbox"][name=chk_godown_id_${product_material_id}]`);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    godownOptions.dataArray.forEach(function (item, index) {
      $(`#chk_godown_id_${product_material_id}_${item.godown_id}`).prop('checked', true);
    });
    const checkedLenght = $(`input[type="checkbox"][name=chk_godown_id_${product_material_id}]:checked`).length;
    $(`#godown_selectedCount_id_${product_material_id}`).text(``);
    $(`#godown_selectedCount_id_${product_material_id}`).text(`${checkedLenght} selected`);
  };

  function FnToggleDropdown(opendropdown, product_material_id) {
    switch (opendropdown) {
      case 'displayGodownList':
        var element = document.getElementById(`godown_id_${product_material_id}`);
        if (element.style.display === 'none' || element.style.display === '') {
          element.style.display = 'block';
        } else {
          element.style.display = 'none';
        }
        break;
      default:
        break;
    }
  }


  // ------------------------ Changes for the Searchable Combos. --------------------------
  let formDepartmentComboRef = useRef(null);
  let toDepartmentComboRef = useRef(null);
  let fromCostCenterComboRef = useRef(null);
  let toCostCenterComboRef = useRef(null);
  let requestedByComboRef = useRef(null);
  let receivedByComboRef = useRef(null);

  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <ExcelExport ref={exlsExp} />
      <GenerateTAutoNo ref={generateAutoNoAPiCall} />
      <FrmValidations ref={validate} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <DashboardLayout>
        <>
          {isLoading ?
            <div className="spinner-overlay"  >
              <div className="spinner-container">
                <CircularProgress color="primary" />
                <span>Loading...</span>
              </div>
            </div> :
            ''}

          <div className='card p-1'>
            <div className='card-header text-center py-0'>
              <label className='erp-form-label-lg main_heding'> Material Loan {ActionType()}</label>
            </div>
            <form id="loan_issue_form_id">
              <div className="row p-1">
                {/* //first column */}
                <div className="col-sm-6 erp_form_col_div">
                  <div className='row'>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Loan No,Version & Date</Form.Label>
                    </div>
                    <div className='col-sm-9 col-12'>
                      <div className="row">
                        <div className='col-12 col-md-6 pe-md-0'>
                          <Form.Control type="text" id="txt_loan_no" className="erp_input_field" value={txt_loan_no} optional='optional' disabled />
                        </div>
                        <div className="col-12 col-md-2 pt-md-0 pt-3">
                          <Form.Control type="text" id='txt_loan_version' className="erp_input_field" value={txt_loan_version} optional='optional' disabled />
                        </div>
                        <div className="col-12 col-md-4 pt-md-0 pt-3">
                          <Form.Control type="date" id='dt_loan_date' className="erp_input_field" value={dt_loan_date} onChange={e => { setLoanDate(e.target.value); validateFields(); }} min={currentDate} disabled />
                          <MDTypography variant="button" id="error_dt_loan_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">From Comapany<span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col-sm-9 col-12'>
                      <select id="cmb_from_comapany_id" className="form-select form-select-sm" value={cmb_from_comapany_id} onChange={() => { comboOnChange('from_company'); validateFields(); }}  >
                        <option value="">Select</option>
                        {companyOptions.length !== 0 ? (
                          <>
                            {companyOptions.map(comapnyItem => (
                              <option key={comapnyItem.field_id} value={comapnyItem.field_id}>  {comapnyItem.field_name}  </option>
                            ))}
                          </>
                        ) : null}
                      </select>
                      <MDTypography variant="button" id="error_cmb_from_comapany_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className={`row`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">From Department<span className="required">*</span></Form.Label>
                    </div>
                    <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10 mb-1`}>
                      <Select ref={formDepartmentComboRef}
                        options={fromDepartmentOptions}
                        isDisabled={['view', 'approve', 'update'].includes(keyForViewUpdate)}
                        inputId="cmb_from_department_id"
                        value={fromDepartmentOptions.find(option => option.value === cmb_from_department_id) || null}
                        onChange={(selectedOpt) => {
                          setFromDepartmentId(selectedOpt.value);
                          formDepartmentComboRef.current = selectedOpt;
                          comboOnChange('from_department');
                          validateFields();
                        }}
                        placeholder="Search for a department..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_from_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    {/* <div className={`col-sm-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'issue' ? 'd-none' : 'display'} col-2 refreshIconCol`}>
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_customer') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div> */}
                  </div>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <Form.Label className="erp-form-label">From Sub Department <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_from_Sub_department_id" className="form-select form-select-sm" value={cmb_from_Sub_department_id} onChange={() => { comboOnChange('fromSubDepartment'); validateFields(); }}>
                        <option value="">Select</option>
                        {/* <option value="0">Add New Record+</option> */}
                        {fromSubDepartmentOptions?.map(departmentId => (
                          <option value={departmentId.field_id}>{departmentId.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_from_Sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Requested By <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-8 col-10 mb-1'>
                      <Select ref={requestedByComboRef}
                        options={requisitionOptions}
                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                        inputId="cmb_requisition_by_id" // Provide the ID for the input box
                        value={requisitionOptions.find(option => option.value === cmb_requisition_by_id) || null}
                        onChange={(selectedOpt) => {
                          setRequisitionBy(selectedOpt.value);
                          requestedByComboRef.current = selectedOpt;
                          comboOnChange('requisition_by');
                          validateFields();
                        }}
                        placeholder="Search for indented by..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_requisition_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    {/* <div className="col-sm-1 col-2">
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={` ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_employee') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-sm-3 col-12">
                      <Form.Label className="erp-form-label">From Cost Center <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col mb-1">
                      <Select ref={fromCostCenterComboRef}
                        options={from_cost_centerOptions}
                        inputId="cmb_from_cost_center" // Provide the ID for the input box
                        value={from_cost_centerOptions.find(option => option.value == cmb_from_cost_center) || null}
                        onChange={(selectedOpt) => {
                          setFromCostCenter(selectedOpt.value);
                          fromCostCenterComboRef.current = selectedOpt;
                          comboOnChange('from_cost_center')
                          validateFields();
                        }}
                        placeholder="Search for a from cost center..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_from_cost_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>



                  {/* <div className={`row ${keyForViewUpdate !== "" && keyForViewUpdate !== "delete" ? 'display' : 'd-none'}`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Approved By</Form.Label>
                    </div>
                    <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                      <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} optional='optional'
                        onChange={(e) => { validateFields(); setApprovedById(e.target.value) }} disabled>
                        <option value="">Select</option>
                        {employeeOptions.length !== 0 ? (
                          <>
                            {employeeOptions?.map(acceptedById => (
                              <option value={acceptedById.field_id}>{acceptedById.field_name}</option>
                            ))}
                          </>
                        ) : null}
                      </select>
                      <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className={`row ${keyForViewUpdate !== "" && keyForViewUpdate !== "delete" ? 'display' : 'd-none'}`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Approved Date</Form.Label>
                    </div>
                    <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                      <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApproved_date(e.target.value); validateFields(); }} min={currentDate} optional='optional' />
                      <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                </div>
                {/* //second column */}
                <div className="col-sm-6 erp_form_col_div">
                  <div className="row">
                    <div className="col-sm-3">
                      <Form.Label className="erp-form-label">Loan Status</Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_loan_status " className="form-select form-select-sm" value={cmb_loan_status} onChange={(e) => { setLoanStatus(e.target.value); }} disabled >
                        <option value="P">Pending</option>
                        <option value="A">Approved</option>
                        <option value="C">Completed</option>
                        <option value="I">Partial Issue</option>
                        <option value="R">Received</option>
                        <option value="MI">Material Issue</option>
                        <option value="IR">Partial Received</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">To Comapany<span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col-sm-9 col-12'>
                      <select id="cmb_to_comapany_id" className="form-select form-select-sm" value={cmb_to_comapany_id} onChange={() => { comboOnChange('to_company'); validateFields(); }} >
                        <option value="">Select</option>
                        {companyOptions.length !== 0 ? (
                          <>
                            {companyOptions.map(comapnyItem => (
                              <option key={comapnyItem.field_id} value={comapnyItem.field_id}>  {comapnyItem.field_name}  </option>
                            ))}
                          </>
                        ) : null}
                      </select>
                      <MDTypography variant="button" id="error_cmb_to_comapany_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className={`row`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">To Department<span className="required">*</span></Form.Label>
                    </div>
                    <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10 mb-1`}>
                      {/* <select id="cmb_to_department_id" className="form-select form-select-sm" value={cmb_to_department_id} onChange={() => { comboOnChange('to_department'); validateFields(); }} disabled>
                        <option value="">Select</option>
                        {toDepartmentOptions.length !== 0 ? (
                          <>
                            {toDepartmentOptions.map(comapnyItem => (
                              <option key={comapnyItem.field_id} value={comapnyItem.field_id}>  {comapnyItem.field_name}  </option>
                            ))}
                          </>
                        ) : null}
                      </select> */}
                      <Select ref={toDepartmentComboRef}
                        options={toDepartmentOptions}
                        isDisabled={['view', 'approve', 'update'].includes(keyForViewUpdate)}
                        inputId="cmb_to_department_id"
                        value={toDepartmentOptions.find(option => option.value === cmb_to_department_id) || null}
                        onChange={(selectedOpt) => {
                          setToDepartmentId(selectedOpt.value);
                          toDepartmentComboRef.current = selectedOpt;
                          comboOnChange('to_department');
                          validateFields();
                        }}
                        placeholder="Search for a department..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_to_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    {/* <div className={`col-sm-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'} col-2 refreshIconCol`}>
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_department') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div> */}
                  </div>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <Form.Label className="erp-form-label">To Sub Department <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_to_Sub_department_id" className="form-select form-select-sm" value={cmb_to_Sub_department_id} onChange={() => { comboOnChange('toSubDepartment'); validateFields(); }}>
                        <option value="">Select</option>
                        {/* <option value="0">Add New Record+</option> */}
                        {toSubDepartmentOptions?.map(departmentId => (
                          <option value={departmentId.field_id}>{departmentId.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_to_Sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className={`row`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Requested To <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-8 col-10 mb-1'>
                      <Select ref={receivedByComboRef}
                        options={receivedOptions}
                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                        inputId="cmb_received_by_id" // Provide the ID for the input box
                        value={receivedOptions.find(option => option.value === cmb_received_by_id) || null}
                        onChange={(selectedOpt) => {
                          setReceivedBy(selectedOpt.value);
                          receivedByComboRef.current = selectedOpt;
                          comboOnChange('received_by');
                          validateFields();
                        }}
                        placeholder="Search for received by..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_requisition_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    {/* <div className="col-sm-1 col-2">
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={` ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_employee') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-sm-3 col-12">
                      <Form.Label className="erp-form-label">To Cost Center <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col mb-1">
                      <Select ref={toCostCenterComboRef}
                        options={from_cost_centerOptions}
                        inputId="cmb_to_cost_center" // Provide the ID for the input box
                        value={from_cost_centerOptions.find(option => option.value == cmb_to_cost_center) || null}
                        onChange={(selectedOpt) => {
                          setToCostCenter(selectedOpt.value);
                          toCostCenterComboRef.current = selectedOpt;
                          comboOnChange('to_cost_center')
                          validateFields();
                        }}
                        placeholder="Search for a to cost center..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_to_cost_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <hr />

          <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Material Details</Accordion.Header>
              <Accordion.Body>
                <div className={`row ${keyForViewUpdate === "" ? 'display' : 'd-none'}`}>
                  <div className="col-sm-4 col-12">
                    <div className={`row`}>
                      <div className="col-md-8 col-12">
                        <SearchItemFilter getSearchData={handleCloseBomFilterForm} formIdForValidate="loan_issue_form_id" requiredCols={requiredCols} company_id={cmb_to_comapany_id} />
                        <MDTypography variant="button" id="error_txt_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-1 col-12'>
                    <Form.Label className="erp-form-label">Total Balance Quantity</Form.Label>
                  </div>
                  <div className={`col-sm-2 col-10`}>
                    <Form.Control type="text" id='txt_balance_quantity' className="erp_input_field" value={txt_balance_quantity} optional='optional' disabled />
                    <MDTypography variant="button" id="error_txt_balance_quantity" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col-sm-1 col-12">
                    <Form.Label className="erp-form-label">Total Request Quantity<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col-sm-2 col-12">
                    <Form.Control type="text" id='txt_requested_quantity' className="erp_input_field" value={txt_requested_quantity} onChange={(e) => { FnvalidateTotalIssueQuantity(e.target.value) }} />
                    <MDTypography variant="button" id="error_txt_requested_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col-sm-1 col-12 pt-md-0 pt-2 float-start">
                    <MDButton type="button" id="calculate_btn_id" className={`erp-gb-button erp_MLeft_btn`} variant="button" onClick={() => { if (txt_requested_quantity !== "") { FnCalculatePurchaseDetails() } }} fontWeight="regular">Add</MDButton>
                  </div>
                </div>


                <div className="erp-Mt-10">
                  {materialIndentDetailsData.length !== 0 ?
                    <>
                      {renderDetailsTable}
                    </> :
                    <div className='row  text-center'>
                      <div className="col-12 ">
                        <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                      </div>
                    </div>
                  }
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion >

          {keyForViewUpdate === 'view' ?
            <>
              <hr />
              <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                <Accordion.Item eventKey="documentList">
                  <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                  <Accordion.Body>
                    {docData.length !== 0 ? (
                      renderDocumentTable
                    ) : (
                      <div className='row text-center'>
                        <div className="col-12">
                          <span className="erp_validation text-center" fontWeight="regular" color="error">
                            No Records Found...
                          </span>
                        </div>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
            : null
          }
        </>

        <div className="text-center mb-5">
          <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
          <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={() => { addLoanIssue('P') }}
            fontWeight="regular">Save</MDButton>
          {/* <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp; */}
          <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>
          <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
          <MDButton type="button" variant="button" onClick={() => { addLoanIssue('MI') }} id="issue-btn"
            className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'issue' ? 'display' : 'd-none'}`} fontWeight="regular" >Issue</MDButton>
          <MDButton type="button" variant="button" onClick={() => { addLoanIssue('R') }} id="return-btn"
            className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'return' ? 'display' : 'd-none'}`} fontWeight="regular" >Return</MDButton>
          {/* <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? cmb_loan_status !== 'P' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} disabled={cmb_loan_status !== "P" ? '' : 'disabled'} >Print<FiDownload className="erp-download-icon-btn" /></MDButton> */}
        </div >

        {/* Document modal */}
        <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
          </Modal.Header>
          <Modal.Body>
            {/* <DocumentF group_id={txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null} document_group={docGroup} /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

        <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></div>
            </div>
            {displayRecordIndentIssueComponent()}

          </Modal.Body>
        </Modal >



        {/* Delete Modal */}
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
          <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
          <Modal.Body className='erp_modal_body'>
            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
            <h6>Are you sure?</h6>
            <div className="erp-form-label">Do you wish to delete this record ?</div>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant="success" className='erp-gb-button' onClick={handleClose}>
              Cancel
            </Button>&nbsp;
            {/* <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button> */}
          </Modal.Footer>
        </Modal>

      </DashboardLayout >
    </>
  )
}