import React, { useState, useRef, useEffect, useMemo } from 'react'
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from 'FrmGeneric/FrmValidations';
import ComboBox from 'Features/ComboBox';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { resetGlobalQuery, globalQuery } from 'assets/Constants/config-constant';

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";

import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import { FiPrinter } from 'react-icons/fi';
import ShortLeave from 'FrmGeneric/Invoice/ShortLeave';
import { renderToString } from "react-dom/server";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { CircularProgress } from '@material-ui/core';


function FrmGatePassEntry() {

    // Config Constant
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName, USER_CODE, DEPARTMENT_ID } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate, idList = 0, compType, modules_forms_id } = state || {}

    //Loader
    const [isLoading, setIsLoading] = useState(false);

    const [actionLabel, setActionLabel] = useState('Save');
    const navigator = useNavigate();
    const generateAutoNoAPiCall = useRef();

    //////useRef Hooks
    const comboBoxRef = useRef();
    const validate = useRef();
    const validateNumberDateInput = useRef();
    const navigate = useNavigate();
    let cmb_department_id_ref = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;

    }

    ///Current Time
    const currentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    ////Array Options
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [employeeList, setEmployeeListOption] = useState([]);

    ///Hooks for Header data
    const [dt_gate_passdate, setGatePassDate] = useState(today);
    const [is_gatepasstype, setGatePassType] = useState('Personal');
    const [is_leaveType, setLeaveType] = useState('Short Leave');
    const [gatepass_outtime, setGatePassOutTime] = useState(null);
    const [gatepass_intime, setGatePassInTime] = useState(null);
    const [cmb_employee_id, setEmployeeId] = useState('');
    const [txt_employee_code, setEmployeeCode] = useState('');
    const [machine_employee_code, setMachineEmployeeCode] = useState();
    const [short_leave_transaction_id, setShortLeavesTransactionId] = useState(idList);

    // const [cmb_department_id, setDepartmentId] = useState('');
    const [txt_approved_by, setApprovedBy] = useState(UserId);
    const [cmb_approval_status, setApprovedStatus] = useState('Pending');
    const [txt_leave_reason, setLeaveReason] = useState('');
    const [departmentopt, setDepartmentOption] = useState([]);
    const [total_hours, setTotalHours] = useState('');
    const [ApproveFlag, setApproveFlag] = useState(false);
    const [actionType, setActionType] = useState('')
    const [short_leave_id, setShortLeaveId] = useState('')

    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [cmb_departmentId, setDepartmentId] = useState(0);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [cmb_sub_department_id, setSubDepartmentId] = useState(0);
    const [shift_name, setShiftName] = useState('');
    const [shift_timing, setShiftTiming] = useState('');
    const [reportingTo, setReportingToName] = useState('');
    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [userDetails, setUserDetails] = useState([]);
    const [fixshortLeavehours, setFixShortLeaveHours] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        navigate('/Masters/MGatePass/MGatePassListing')
        setShowSuccessMsgModal(false)
    };

    useEffect(async () => {
        await ActionType()
        await FillCombos();
        if (idList !== 0) {
            await FnCheckUpdateResponce()
        } else {
            await generateShortLeaveId();
        }
    }, [])


    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("shortLeaveFormId");
                break;
            case 'approve':
                setActionType('(Approve)');
                setActionLabel('Approve');
                setApprovedStatus('Approved');
                setApproveFlag(true);
                await validate.current.readOnly("shortLeaveFormId");
                // $('#txt_approved_by').attr('disabled', false);
                $('#cmb_approval_status').attr('disabled', false);
                break;

            case 'cancel':
                setActionType('(Cancel)');
                setActionLabel('cancel');
                setApprovedStatus('Cancelled');
                await validate.current.readOnly("shortLeaveFormId");
                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };

    const calculateTotalHours = () => {
        debugger
        const inTimePunch = document.getElementById('gatepass_intime').value;
        const outTimePunch = document.getElementById('gatepass_outtime').value;
        if (inTimePunch && outTimePunch) {
            const [inHours, inMinutes] = inTimePunch.split(':').map(Number);
            const [outHours, outMinutes] = outTimePunch.split(':').map(Number);

            // Create Date objects for the out and in punch times using the current date
            const currentDate = new Date();

            const outTime = new Date(currentDate);
            outTime.setHours(outHours, outMinutes, 0, 0);

            const inTime = new Date(currentDate);
            inTime.setHours(inHours, inMinutes, 0, 0);

            // If inTime is earlier than outTime, it means it crossed midnight, so add a day to inTime
            if (inTime <= outTime) {
                inTime.setDate(inTime.getDate() + 1);
            }

            // Calculate the difference in milliseconds

            const diffMilliseconds = inTime - outTime; // 10-20 = -10 = 

            // Convert milliseconds to minutes
            const diffMinutes = Math.floor(diffMilliseconds / 60000);
            const fixshortLeaveMinutes = fixshortLeavehours * 60; // 

            if (is_leaveType === "Short Leave" && diffMinutes > fixshortLeaveMinutes) {
                $("#error_gatepass_outtime").text("Max 2 hours allowed for short leave.");
                $("#error_gatepass_outtime").show();
                $("#error_gatepass_outtime").focus();
            } else {
                $("#error_gatepass_outtime").hide();
            }

            // Convert to hours and minutes
            const hours = Math.floor(diffMinutes / 60);
            const minutes = diffMinutes % 60;

            // Format the result in HH:MM
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');

            setTotalHours(`${formattedHours}:${formattedMinutes}`);
            return `${formattedHours}:${formattedMinutes}`;
        }
    };
    const calculateShortLeave = () => {
        debugger
        const inTimePunch = document.getElementById('shift_timing').value; // shift timing, e.g., "09:00:00-19:00:00"
        const outTimePunch = document.getElementById('gatepass_outtime').value;
        if (inTimePunch && outTimePunch) {
            // Parse shift timing
            const [shiftStart, shiftEnd] = inTimePunch.split('-');
            const [shiftStartHours, shiftStartMinutes] = shiftStart.split(':').map(Number);
            const [shiftEndHours, shiftEndMinutes] = shiftEnd.split(':').map(Number);

            // Parse outTimePunch
            const [outHours, outMinutes] = outTimePunch.split(':').map(Number);

            // Create Date objects for the end of shift and out punch times using the current date
            const currentDate = new Date();

            const shiftEndTime = new Date(currentDate);
            shiftEndTime.setHours(shiftEndHours, shiftEndMinutes, 0, 0);

            const shiftStartTime = new Date(currentDate);
            shiftStartTime.setHours(shiftStartHours, shiftStartMinutes, 0, 0); // 9:58

            const allowedStartTime = new Date(currentDate);
            allowedStartTime.setHours(shiftStartHours + fixshortLeavehours, shiftStartMinutes, 0, 0); // 10:00

            const outTime = new Date(currentDate);
            outTime.setHours(outHours, outMinutes, 0, 0);

            let diffMilliseconds = 0

            if (outTime <= allowedStartTime) { // 9:58 <= 10:00 
                diffMilliseconds = outTime - shiftStartTime; // 9:58 - 8:00 = 1:58
            } else {
                diffMilliseconds = shiftEndTime - outTime;  // 20:00 - 18:00 = 2:00
            }

            const diffMinutes = Math.floor(diffMilliseconds / 60000);
            const fixshortLeaveMinutes = fixshortLeavehours * 60;

            if (is_leaveType === "Short Leave" && diffMinutes > fixshortLeaveMinutes) {
                $("#error_gatepass_outtime").text("Max 2 hours allowed for short leave.");
                $("#error_gatepass_outtime").show();
                $("#error_gatepass_outtime").focus();
            }
            else if (is_leaveType === "Short Leave" && diffMinutes < 0) {
                $("#error_gatepass_outtime").text("Enter valid time");
                $("#error_gatepass_outtime").show();
                $("#error_gatepass_outtime").focus();
                return setTotalHours(``);
            }
            else {
                $("#error_gatepass_outtime").hide();
            }


            const hours = Math.floor(diffMinutes / 60);
            const minutes = diffMinutes % 60;

            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');

            setTotalHours(`${formattedHours}:${formattedMinutes}`);
            return `${formattedHours}:${formattedMinutes}`;
        }
    };



    const FillCombos = async () => {
        debugger
        try {

            resetGlobalQuery();
            globalQuery.columns = ['short_leave_hours'];
            globalQuery.table = "hm_hrpayroll_settings";
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const shortLeaveHours = await comboBoxRef.current.fillFiltersCombo(globalQuery);
            setFixShortLeaveHours(shortLeaveHours[0].short_leave_hours);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'employee_id', 'employee_name', 'employee_code', 'department_name', 'sub_department_name', 'machine_employee_code', 'reporting_to', 'reporting_to_name', 'old_employee_code', 'shift_start_end_time', 'shift_name'];

            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            if (DEPARTMENT_ID != "11") {
                globalQuery.conditions.push({ field: "reporting_to", operator: "=", value: UserId });
            }
            var employeesAPICall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
            employeesAPICall = employeesAPICall?.map(prop => ({ ...prop, value: prop.employee_id, label: `[${prop.old_employee_code}] ${prop.employee_name}` }));
            employeesAPICall.unshift({ employee_id: '', value: 'All', label: 'All' });
            setEmployeeOptions(employeesAPICall);



            // resetGlobalQuery();
            // globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            // globalQuery.table = "cm_department"
            // globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // comboBoxRef.current.fillFiltersCombo(globalQuery)
            //     .then(rcvdDepts => {
            //         const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
            //             if (department.department_type === "M") {
            //                 acc.mainDepartments.push({
            //                     ...department,
            //                     label: department.department_name,
            //                     value: department.department_id,
            //                 });
            //             } else if (department.department_type === "S") {
            //                 acc.subDepartments.push({
            //                     ...department,
            //                     label: department.department_name,
            //                     value: department.department_id,
            //                 });
            //             }
            //             return acc;
            //         }, { mainDepartments: [], subDepartments: [] });
            //         setAllDepartmentsList([...mainDepartments, ...subDepartments]);

            //         mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
            //         setDepartmentOpts(mainDepartments);
            //         subDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
            //         setSubDepartmentOpts(subDepartments);
            //     });

            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboBoxRef.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setEmployeeListOption(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });

            if (DEPARTMENT_ID !== '11') {


                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'employee_id', 'employee_code', 'employee_name', 'department_name', 'sub_department_name', 'machine_employee_code', 'reporting_to', 'reporting_to_name', 'old_employee_code', 'department_id', 'sub_department_id', 'shift_start_end_time', 'shift_name']
                globalQuery.table = "cmv_employee";
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
                globalQuery.conditions.push({ field: "employee_code", operator: "=", value: USER_CODE });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const employeeApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
                const employeeAllList = employeeApiCall?.map(prop => ({ ...prop, value: prop.employee_id, label: `[${prop.old_employee_code}] ${prop.employee_name}` }));
                employeeAllList.unshift({ employee_id: '', value: 'All', label: 'All' });
                // setDepartmentId(employeeApiCall[0].department_id);
                // cmb_department_id_ref.current.department_id = employeeApiCall[0].department_id
                // setSubDepartmentId(employeeApiCall[0].sub_department_id);
                // subDepartmentComboRef.current.department_id = employeeApiCall[0].sub_department_id
                // setEmployeeCode(employeeAllList[0].employee_code);

                if (keyForViewUpdate === "add") {
                    setEmployeeCode(USER_CODE);
                    setEmployeeId(UserId);
                    employeeComboRef.current.employee_id = UserId;
                    setReportingToName(employeeAllList[1].reporting_to_name);
                    setShiftName(employeeAllList[1].shift_name);
                    setShiftTiming(employeeAllList[1].shift_start_end_time);
                    // setUserDetails(employeeApiCall);

                }

                const filteredUserDetails = employeeAllList.filter(user =>
                    !employeesAPICall.some(emp => emp.employee_id === user.employee_id)
                );

                // Add userDetails to the employee options without duplicates
                const mergedEmployeeList = [
                    ...employeesAPICall,
                    ...filteredUserDetails.map(user => ({
                        ...user,
                        value: user.employee_id, // Assuming 'id' exists in userDetails
                        label: user.employee_name // Assuming 'name' exists in userDetails
                    }))
                ];
                if (mergedEmployeeList.length > 0) {
                    setEmployeeOptions(mergedEmployeeList);
                }
            }

        } catch (error) {

        }
    }

    const comboBoxesOnChange = async (key) => {
        try {
            switch (key) {
                // case 'Employee':
                //     var employeeID = $('#cmb_employee_id').val();
                //     if (employeeID !== "") {
                //         var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                //         setEmployeeId(employeeID);
                //         setEmployeeCode(employeeCode);

                //         let findEmployee = employeeOptions.find((employee) => {
                //             return employee.employee_code === employeeCode;
                //         })

                //         if (findEmployee) {
                //             setApprovedBy(findEmployee.reporting_to);
                //             setMachineEmployeeCode(findEmployee.machine_employee_code)
                //         } else {
                //             setApprovedBy('');
                //         }

                //     }
                //     break;
                // case "subDepartment":
                //     debugger
                //     setEmployeeCode('');
                //     setEmployeeId('');
                //     let selectedDepTS = cmb_department_id_ref.current.department_id;
                //     const subDepartmentLists = allDepartmentsList.filter(department =>
                //         (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                //     );
                //     subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                //     setSubDepartmentOpts(subDepartmentLists);
                //     const departmentIDs = cmb_department_id_ref.current?.department_id || 'All';
                //     const subdepartmentIDs = subDepartmentComboRef.current?.value || 'All';
                //     if (departmentIDs !== "") {
                //         resetGlobalQuery();
                //         globalQuery.columns = ['field_id', 'field_name', 'employee_code', 'department_name', 'sub_department_name', 'machine_employee_code', 'reporting_to', 'reporting_to_name', 'old_employee_code'];
                //         globalQuery.table = "cmv_employee";
                //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                //         globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                //         if (departmentIDs !== "" && departmentIDs !== "All") {
                //             globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIDs });
                //         }
                //         if (subdepartmentIDs !== "" && subdepartmentIDs !== "All") {
                //             globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentIDs });
                //         }
                //         if (DEPARTMENT_ID != "11") {
                //             globalQuery.conditions.push({ field: "reporting_to", operator: "=", value: UserId });
                //         }

                //         var employeesAPICall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
                //         // setEmployeeOptions(employeesAPICall);



                //         const filteredUserDetails = userDetails.filter(user =>
                //             !employeesAPICall.some(emp => emp.employee_id === user.employee_id)
                //         );

                //         // Add userDetails to the employee options without duplicates
                //         const mergedEmployeeList = [
                //             ...employeesAPICall,
                //             ...filteredUserDetails.map(user => ({
                //                 ...user,
                //                 value: user.employee_id, // Assuming 'id' exists in userDetails
                //                 label: user.employee_name // Assuming 'name' exists in userDetails
                //             }))
                //         ];
                //         if (mergedEmployeeList.length > 0) {
                //             setEmployeeOptions(mergedEmployeeList);
                //         }

                //     }

                //     break;

                // case 'EmployeeCode':
                //     // var employeeCode = $('#txt_employee_code').val();
                //     var serachemployeeCode = $('#txt_employee_code').val();
                //     setEmployeeCode(serachemployeeCode);

                //     // let findEmployee = employeeOptions.find((employee) => {
                //     //     return employee.employee_code === employeeCode;
                //     // })
                //     let findEmployee = null;

                //     if (serachemployeeCode.length >= 3) {
                //         findEmployee = employeeOptions.find((employee) => {
                //             return employee.employee_code === serachemployeeCode ||
                //                 employee?.field_name?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                //                 || employee?.old_employee_code?.includes(serachemployeeCode)
                //         });
                //     }
                //     if (findEmployee) {
                //         setEmployeeId(findEmployee.field_id);
                //         setApprovedBy(findEmployee.reporting_to);
                //         setMachineEmployeeCode(findEmployee.machine_employee_code)
                //     } else {
                //         setEmployeeId('');
                //         setApprovedBy('');
                //     }
                //     break;
                // case 'EmplCode':
                //     var employeeID = $('#cmb_employee_id').val();
                //     if (employeeID !== "") {
                //         var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                //         setEmployeeId(employeeID);
                //         setEmployeeCode(employeeCode);
                //     }
                //     break;
                case 'EmployeeCode':
                    debugger
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);
                    let findEmployee = null;

                    if (serachemployeeCode.length >= 2) {
                        findEmployee = employeeOptions.find((employee) => {
                            return employee.employee_code === serachemployeeCode
                                || employee?.label?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                || employee?.old_employee_code === serachemployeeCode
                            // || employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.employee_id);
                        employeeComboRef.current = {
                            employee_id: findEmployee.employee_id,
                            employee_code: findEmployee.employee_code,
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                        };
                    }
                    break;
                case 'EmplCode':
                    // setEmployeeCode(employeeComboRef.current.employee_code);
                    if (employeeComboRef.current && employeeComboRef.current.employee_code) {
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    }

                    break;
                case 'Employee':
                    debugger
                    var employeeID = employeeComboRef.current?.employee_id;
                    $("#error_gatepass_outtime").hide();
                    if (employeeID !== "") {
                        // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeComboRef.current.employee_id);
                        setEmployeeCode(employeeComboRef.current.employee_code);
                        setShiftName(employeeComboRef.current.shift_name);
                        setShiftTiming(employeeComboRef.current.shift_start_end_time);
                        setReportingToName(employeeComboRef.current.reporting_to_name);
                        setTotalHours('');
                        setGatePassOutTime('');
                    } else {
                        setEmployeeCode('');
                    }
                // case 'EmployeeList':
                //     setEmployeeCode('');
                //     setEmployeeId('');
                //     subDepartmentComboRef.current = null;
                //     setSubDepartmentId(0);
                //     let selectedDepartment = cmb_department_id_ref.current.department_id;
                //     const subDepartmentList = allDepartmentsList.filter(department =>
                //         (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                //     );
                //     subDepartmentList.unshift({ department_id: 0, value: 'All', label: 'All' });
                //     setSubDepartmentOpts(subDepartmentList);

                //     const departmentID = cmb_department_id_ref.current?.department_id || 'All';
                //     const subdepartmentID = subDepartmentComboRef.current?.value || 'All';
                //     if (departmentID !== "") {
                //         resetGlobalQuery();

                //         globalQuery.columns = ['field_id', 'field_name', 'employee_code', 'department_name', 'sub_department_name', 'machine_employee_code', 'reporting_to', 'reporting_to_name', 'old_employee_code'];
                //         globalQuery.table = "cmv_employee";
                //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                //         globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                //         if (departmentID !== "" && departmentID !== "All") {
                //             globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentID });
                //         }
                //         if (subdepartmentID !== "" && subdepartmentID !== "All") {
                //             globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentID });
                //         }
                //         if (DEPARTMENT_ID != "11") {
                //             globalQuery.conditions.push({ field: "reporting_to", operator: "=", value: UserId });
                //         }

                //         var employeesAPICall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
                //         // setEmployeeOptions(employeesAPICall);
                //         const filteredUserDetails = userDetails.filter(user =>
                //             !employeesAPICall.some(emp => emp.employee_id === user.employee_id)
                //         );

                //         // Add userDetails to the employee options without duplicates
                //         const mergedEmployeeList = [
                //             ...employeesAPICall,
                //             ...filteredUserDetails.map(user => ({
                //                 ...user,
                //                 value: user.employee_id, // Assuming 'id' exists in userDetails
                //                 label: user.employee_name // Assuming 'name' exists in userDetails
                //             }))
                //         ];
                //         if (mergedEmployeeList.length > 0) {
                //             setEmployeeOptions(mergedEmployeeList);
                //         }


                //     }
                //     break;

            }

        } catch (error) {
            console.log("error : ", error)
            navigate('/Error')
        }
    }
    const validateEmployeeForm = async () => {
        debugger
        // if (cmb_departmentId === '' || cmb_departmentId === 0) {
        //     $("#error_cmb_department_id").text("Please fill this field...!");
        //     $("#error_cmb_department_id").show();
        //     $("#cmb_departmentId").focus();
        //     return false;
        // } else {
        //     $("#error_cmb_department_id").hide();
        // }
        // if (cmb_sub_department_id === '' || cmb_sub_department_id === 0) {
        //     $("#error_cmb_sub_department_id").text("Please fill this field...!");
        //     $("#error_cmb_sub_department_id").show();
        //     $("#cmb_sub_department_id").focus();
        //     return false;
        // } else {
        //     $("#error_cmb_sub_department_id").hide();
        // }

        if (gatepass_outtime === '' || gatepass_outtime === null) {
            $("#error_gatepass_outtime").text("Please fill this field...!");
            $("#error_gatepass_outtime").show();
            $("#error_gatepass_outtime").focus();
            return false;
        } else {
            $("#error_gatepass_outtime").hide();
        }
        if ((gatepass_intime === '' || gatepass_intime === null) && keyForViewUpdate === 'update' && is_leaveType != "Short Leave") {
            $("#error_gatepass_intime").text("Please fill this field...!");
            $("#error_gatepass_intime").show();
            $("#error_gatepass_intime").focus();
            return false;
        } else {
            $("#error_gatepass_intime").hide();
        }

        if (is_leaveType === "Short Leave") {
            calculateShortLeave();
        } else {
            const inTimePunch = document.getElementById('gatepass_intime').value;
            const outTimePunch = document.getElementById('gatepass_outtime').value;
            if (inTimePunch && outTimePunch) {
                const [inHours, inMinutes] = inTimePunch.split(':').map(Number);
                const [outHours, outMinutes] = outTimePunch.split(':').map(Number);

                // Create Date objects for the out and in punch times using the current date
                const currentDate = new Date();

                const outTime = new Date(currentDate);
                outTime.setHours(outHours, outMinutes, 0, 0);

                const inTime = new Date(currentDate);
                inTime.setHours(inHours, inMinutes, 0, 0);

                // If inTime is earlier than outTime, it means it crossed midnight, so add a day to inTime
                if (inTime <= outTime) {
                    inTime.setDate(inTime.getDate() + 1);
                }

                // Calculate the difference in milliseconds
                const diffMilliseconds = inTime - outTime;

                // Convert milliseconds to minutes
                const diffMinutes = Math.floor(diffMilliseconds / 60000);

            }

        }
        return true;
    }
    const saveShortLeaves = async () => {
        try {
            const selectedDate = new Date(dt_gate_passdate);
            const month = selectedDate.getMonth() + 1; // Months are zero-indexed (0-11)
            const checkIsValidate = await validateEmployeeForm();
            let hasUsedShortLeave = true;  // Use 'let' instead of 'const'

            if (keyForViewUpdate != 'cancel') {
                hasUsedShortLeave = await handleDateChange('GatePassDate', selectedDate);  // Reassign the value
                if (hasUsedShortLeave) {
                    return false; // Short leave already used
                }
            }

            const date = new Date(dt_gate_passdate);
            const formattedDate = date.toISOString().split('T')[0];
            if (checkIsValidate === true) {
                setIsLoading(true)
                const validateForm = await validate.current.validateForm('shortLeaveFormId');

                if (validateForm === true) {
                    const gatePassHeaderData = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        created_by: UserName,
                        machine_employee_code: machine_employee_code,
                        employee_id: cmb_employee_id,
                        short_leave_id: short_leave_id,
                        financial_year: FINANCIAL_SHORT_NAME,
                        short_leave_transaction_id: short_leave_transaction_id,
                        leave_type: is_leaveType,
                        gate_pass_type: is_gatepasstype,
                        out_time: gatepass_outtime,
                        in_time: gatepass_intime,
                        short_leave_date: formattedDate,
                        // department_id: cmb_department_id_ref.current.department_id,
                        // sub_department_id: subDepartmentComboRef.current.department_id,
                        // employee_code: txt_employee_code,
                        employee_code: txt_employee_code,
                        leave_reason: txt_leave_reason,
                        approved_by: txt_approved_by ? txt_approved_by : UserId,
                        approval_status: cmb_approval_status,
                        total_hours: total_hours,
                        month: month,
                    }
                    if (keyForViewUpdate !== "approve" && keyForViewUpdate !== "cancel") {
                        gatePassHeaderData.approved_by = '';
                    }
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(gatePassHeaderData)
                    };
                    const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShortLeave/FnAddUpdateRecord/${ApproveFlag}`, requestOptions)
                    const resp = await apicall.json()
                    console.log(resp);
                    if (resp.success === '0') {
                        setErrMsg(resp.error)
                        setShowErrorMsgModal(true)
                    } else {
                        console.log("resp: ", resp)
                        setSuccMsg(resp.message)
                        setShowSuccessMsgModal(true)
                    }
                }
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }
    const generateShortLeaveId = async () => {
        try {
            const transactionId = await generateAutoNoAPiCall.current.generateTAutoNo("ht_short_leave", "short_leave_id", "", 'SL', "5");
            setShortLeaveId(transactionId);
            return transactionId;
        } catch (error) {
            navigate('/Error')
        }
    }


    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            setIsLoading(true)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShortLeave/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
            const response = await apiCall.json();
            const shortLeaves = response.data;
            setShortLeaveId(shortLeaves.short_leave_id);
            // setDepartmentId(shortLeaves.department_id);
            // cmb_department_id_ref.current.department_id = shortLeaves.department_id
            // setSubDepartmentId(shortLeaves.sub_department_id);
            // subDepartmentComboRef.current.department_id = shortLeaves.sub_department_id

            setEmployeeCode(shortLeaves.employee_code);
            setEmployeeId(shortLeaves.employee_id);
            employeeComboRef.current.employee_id = shortLeaves.employee_id
            setMachineEmployeeCode(shortLeaves.machine_employee_code);
            setGatePassInTime(shortLeaves.in_time);
            setGatePassDate(shortLeaves.short_leave_date);
            setGatePassOutTime(shortLeaves.out_time);
            setLeaveReason(shortLeaves.leave_reason);
            setApprovedBy(shortLeaves.approved_by);
            setGatePassType(shortLeaves.gate_pass_type);
            setLeaveType(shortLeaves.leave_type);
            setTotalHours(shortLeaves.total_hours);
            setApprovedStatus(shortLeaves.approval_status);
            setShiftName(shortLeaves.shift_name);
            setShiftTiming(shortLeaves.shift_start_end_time);
            setReportingToName(shortLeaves.reporting_to_name);
            if (keyForViewUpdate === 'cancel') {
                setApprovedStatus('Cancelled');
                // setApprovedBy(UserId);
            } if (keyForViewUpdate === 'approve') {
                setApprovedStatus('Approved');
            }
            if (keyForViewUpdate === 'update' && shortLeaves.approval_status !== 'Pending') {
                await validate.current.readOnly("shortLeaveFormId");
                $('#gatepass_intime').attr('disabled', false);
                $('#gatepass_outtime').attr('disabled', true);
                $('#dt_gate_passdate').attr('disabled', true);
                $('.erp_radio_button').attr('disabled', true);
                $('.erp_radio_button').attr('disabled', true);

            }
            if (keyForViewUpdate === 'update' && shortLeaves.approval_status === 'Pending') {
                document.getElementById('updateTimeNotShow').style.display = 'none';
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }

    }

    ////Validation starts here
    const validateFields = async () => {
        await validate.current.validateFieldsOnChange('shortLeaveFormId');
    }

    const shortLeaveInvoiceContent = {

        company: '',
        company_branch: '',
        employee_code: '',
        employee_id: '',
        machine_employee_code: '',
        in_time: '',
        employee_code: '',
        department_name: '',
        sub_department_name: '',
        out_time: '',
        short_leave_date: '',
        leave_reason: '',
        approved_by: '',
        total_hours: '',
        approved_by_name: '',
        approval_status: '',
        employee_name: '',
        short_leave_id: '',
    };
    const printInvoice = async (openPrintModal) => {
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShortLeave/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
        const response = await apiCall.json();
        const shortLeaves = response.data;

        shortLeaveInvoiceContent.company = shortLeaves.company_name
        shortLeaveInvoiceContent.company_branch = shortLeaves.company_branch_name
        shortLeaveInvoiceContent.department_name = shortLeaves.department_name
        shortLeaveInvoiceContent.sub_department_name = shortLeaves.sub_department_name
        shortLeaveInvoiceContent.employee_code = shortLeaves.employee_code
        shortLeaveInvoiceContent.leave_type = shortLeaves.leave_type
        shortLeaveInvoiceContent.gate_pass_type = shortLeaves.gate_pass_type
        shortLeaveInvoiceContent.employee_id = shortLeaves.employee_id
        shortLeaveInvoiceContent.machine_employee_code = shortLeaves.machine_employee_code
        shortLeaveInvoiceContent.in_time = shortLeaves.in_time
        shortLeaveInvoiceContent.out_time = shortLeaves.out_time
        shortLeaveInvoiceContent.short_leave_date = shortLeaves.short_leave_date
        shortLeaveInvoiceContent.leave_reason = shortLeaves.leave_reason
        shortLeaveInvoiceContent.approved_by = shortLeaves.approved_by
        shortLeaveInvoiceContent.total_hours = shortLeaves.total_hours
        shortLeaveInvoiceContent.approval_status = shortLeaves.approval_status
        shortLeaveInvoiceContent.employee_name = shortLeaves.employee_name
        shortLeaveInvoiceContent.approved_by_name = shortLeaves.approved_by_name
        shortLeaveInvoiceContent.short_leave_id = shortLeaves.short_leave_id


        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: shortLeaveInvoiceContent, navigationLink: "/Masters/MGatePass/MGatePassEntry", invoiceType: 'SL', title: 'Short Leaves', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
        } else {
            return renderToString(<ShortLeave invoiceContent={shortLeaveInvoiceContent} />);
        }
    }


    // const handleDateChange = async (key, date) => {
    //     switch (key) {
    //         case 'GatePassDate':
    //             const GatePassDate = document.getElementById('dt_gate_passdate')
    //             if (GatePassDate !== '') {
    //                 $('#error_dt_gate_passdate').hide();
    //                 setGatePassDate(date);
    //                 const selectedDate = new Date(date);
    //                 const month = selectedDate.getMonth() + 1; // Months are zero-indexed (0-11)
    //                 globalQuery.columns = ['short_leave_id'];
    //                 globalQuery.conditions.push({ field: "approval_status", operator: "=", value: "Approved" });
    //                 globalQuery.conditions.push({ field: "month", operator: "=", value: month });
    //                 globalQuery.conditions.push({ field: "employee_code", operator: "=", value: txt_employee_code });
    //                 globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    //                 globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //                 globalQuery.table = "htv_short_leave";
    //                 var getCount = await comboBoxRef.current.fillFiltersCombo(globalQuery);
    //                 // SetCount(getCount);
    //                 if (getCount.length >= 2) {
    //                     $('#error_dt_gate_passdate').show(); // Show error message
    //                     $('#error_dt_gate_passdate').text("You have already used short leave."); // Set the error message text
    //                 }

    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // };
    const handleDateChange = async (key, date) => {
        debugger
        switch (key) {
            case 'GatePassDate':
                const GatePassDate = document.getElementById('dt_gate_passdate').value;
                if (GatePassDate !== '') {
                    setGatePassDate(date); // Set the selected date

                    const selectedDate = new Date(date);
                    const month = selectedDate.getMonth() + 1; // Extract the month (0-indexed, so add 1)
                    if (is_leaveType === "Short Leave") {
                        // Clear previous conditions before adding new ones
                        globalQuery.columns = ['short_leave_id'];
                        globalQuery.conditions = []; // Reset conditions

                        globalQuery.conditions.push({ field: "approval_status", operator: "=", value: "Approved" });
                        globalQuery.conditions.push({ field: "month", operator: "=", value: month }); // Filter by month
                        globalQuery.conditions.push({ field: "employee_code", operator: "=", value: txt_employee_code });
                        globalQuery.conditions.push({ field: "leave_type", operator: "=", value: "Short Leave" });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.table = "htv_short_leave";

                        // Make API call to check how many records exist for the employee in the month
                        var getCount = await comboBoxRef.current.fillFiltersCombo(globalQuery);

                        // Return true if the user has already used short leave twice or more in the same month
                        if (getCount.length >= 2) {
                            $('#error_dt_gate_passdate').show(); // Show error message
                            $('#error_dt_gate_passdate').text("You have already used your limit of Two short leaves."); // Set the error message text
                            return getCount.length >= 2;

                        }
                    }
                }
                break;
            default:
                return false;
        }
    };

    return (
        <>
            <ComboBox ref={comboBoxRef} />
            <FrmValidations ref={validate} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div> :
                null}
            <DashboardLayout>
                {/* <div className="erp_top_Form"> */}
                <div className='card p-1 mt-3'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Short Leave / Gate Pass {actionType} </label>
                    </div>


                    <form id="shortLeaveFormId">

                        <div className='row mt-1'>
                            <div className='col-sm-6 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Short Leave Id :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='short_leave_id' className="erp_input_field" value={short_leave_id} onChange={(e) => { setShortLeaveId(e.target.value); validateFields() }} disabled />
                                        <MDTypography variant="button" id="error_short_leave_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Leave Date :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker selected={dt_gate_passdate} id="dt_gate_passdate" onChange={(date) => handleDateChange('GatePassDate', date)}
                                            dateFormat="dd-MM-yyyy" placeholderText="dd-mm-yyyy" className="erp_input_field" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'approve'} />
                                        {/* <Form.Control type="date" id='dt_gate_passdate' className="erp_input_field" value={dt_gate_passdate} onChange={(e) => { setGatePassDate(e.target.value); validateFields() }} /> */}
                                        <MDTypography variant="button" id="error_dt_gate_passdate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Leave Type</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check className="erp_radio_button" label="Short Leave" type="radio" value="Short Leave" name="leaveType"
                                                    checked={is_leaveType === 'Short Leave'} defaultChecked onClick={() => { setLeaveType('Short Leave'); setGatePassOutTime(''); setTotalHours(''); $("#error_gatepass_outtime").hide(); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel' || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')} />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check className="erp_radio_button" label="Gate Pass" value="Gate Pass" type="radio" name="leaveType"
                                                    checked={is_leaveType === 'Gate Pass'} onClick={() => { setLeaveType('Gate Pass'); setGatePassOutTime(''); setTotalHours(''); $("#error_gatepass_outtime").hide(); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel' || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {is_leaveType === 'Gate Pass' && (
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Gate Pass Type</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check className="erp_radio_button" label="Personal" type="radio" value="Personal" name="gatePassType"
                                                        checked={is_gatepasstype === 'Personal'} onClick={() => { setGatePassType('Personal'); }} defaultChecked disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel' || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')} />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check className="erp_radio_button" label="Official" value="Official" type="radio" name="gatePassType"
                                                        checked={is_gatepasstype === 'Official'} onClick={() => { setGatePassType('Official'); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel' || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Department </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Select
                                            ref={cmb_department_id_ref}
                                            options={departmentOpts}
                                            inputId="cmb_departmentId"
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate) || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')}
                                            value={departmentOpts.length > 1 ? departmentOpts.find(option => option.value === cmb_departmentId) : null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                cmb_department_id_ref.current = selectedOpt;
                                                // comboBoxesOnChange("department");
                                                comboBoxesOnChange('EmployeeList')
                                                validateFields();
                                            }}
                                            placeholder="Search for a Department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate) || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                comboBoxesOnChange("subDepartment");
                                                validateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}

                                {/* <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Employee Code :<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-2">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                            onChange={(e) => { comboBoxesOnChange('EmployeeCode'); validateFields(); }}
                                            onBlur={(e) => { comboBoxesOnChange('EmplCode'); }}
                                            maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_employee_id" className="form-select form-select-sm" value={cmb_employee_id} onChange={e => { comboBoxesOnChange('Employee'); validateFields(); }} maxLength="255">
                                            <option value="">Select</option>
                                            {employeeOptions?.map(employee => (
                                                <option value={employee.field_id} employeeCode={employee.employee_code}>{employee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Employee / Reporting To :<span className="required">*</span></Form.Label>
                                    </div>
                                    {/* <div className="col-3">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                            onChange={(e) => { comboBoxesOnChange('EmployeeCode'); }}
                                            onBlur={(e) => { comboBoxesOnChange('EmplCode'); }}
                                            maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div> */}
                                    {/* <div className="col-sm-4"> 
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'> */}
                                    <div className="col">
                                        <Select ref={employeeComboRef}
                                            options={employeeOptions}
                                            inputId="cmb_employee_id"
                                            isDisabled={keyForViewUpdate === "approve" || keyForViewUpdate === 'view' || keyForViewUpdate === 'cancel'}
                                            value={employeeOptions.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                validateFields();
                                                comboBoxesOnChange('Employee');
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='reportingTo' className="erp_input_field" value={reportingTo} onChange={(e) => { setReportingToName(e.target.value); validateFields(); }} disabled />
                                        <MDTypography variant="button" id="error_reportingTo" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            <div className='col-sm-6 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Shift Name / Time:</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='shift_name' className="erp_input_field" value={shift_name} onChange={(e) => { setShiftName(e.target.value); }} disabled />
                                        <MDTypography variant="button" id="error_shift_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {/* <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">In Time :</Form.Label>
                                    </div> */}
                                    <div className="col">
                                        <Form.Control type="text" id='shift_timing' className="erp_input_field" value={shift_timing} onChange={(e) => { setShiftTiming(e.target.value); validateFields(); }} disabled />
                                        <MDTypography variant="button" id="error_shift_timing" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">In / Out time</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="time" id='gatepass_outtime' className="erp_input_field" value={gatepass_outtime}
                                            onChange={(e) => { setGatePassOutTime(e.target.value); validateFields(); if (is_leaveType === 'Short Leave') { calculateShortLeave(); } }}
                                            disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel'} />
                                        <MDTypography variant="button" id="error_gatepass_outtime" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {/* <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">In Time :</Form.Label>
                                    </div> */}
                                    {is_leaveType != 'Short Leave' ? (
                                        <div className="col">
                                            <Form.Control type="time" id='gatepass_intime' className="erp_input_field" value={gatepass_intime} onChange={(e) => { setGatePassInTime(e.target.value); calculateTotalHours(); validateFields(); }} disabled={keyForViewUpdate === 'add' || keyForViewUpdate === 'view' || keyForViewUpdate === 'cancel'}
                                                optional={`${keyForViewUpdate !== 'update' ? "optional" : ''}`}
                                            />
                                            <MDTypography variant="button" id="error_gatepass_intime" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    ) : null}
                                </div>


                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">In Time :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="time" id='gatepass_intime' className="erp_input_field" value={gatepass_intime} onChange={(e) => { setGatePassInTime(e.target.value); calculateTotalHours(); validateFields(); }} disabled={keyForViewUpdate === 'add' || keyForViewUpdate === 'view' || keyForViewUpdate === 'cancel'}
                                            optional={`${keyForViewUpdate !== 'approve' ? "optional" : ''}`}
                                        />
                                        <MDTypography variant="button" id="error_gatepass_intime" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Total Hours</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='total_hours' className="erp_input_field" value={total_hours} onChange={(e) => { setTotalHours(e.target.value); validateFields() }} disabled
                                            optional="optional" />
                                        <MDTypography variant="button" id="error_total_hours" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className={`${keyForViewUpdate === 'add' ? 'd-none' : 'display'}`} id="updateTimeNotShow">

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved By<span className="required">*</span></Form.Label>
                                        </div>

                                        <div className="col">
                                            <select id="txt_approved_by" className="form-select form-select-sm optional" value={txt_approved_by} disabled
                                                onChange={e => { setApprovedBy(e.target.value); validateFields(); }}
                                                optional={`${keyForViewUpdate !== 'approve' ? "optional" : ''}`} >
                                                <option value="" disabled>Select</option>
                                                {employeeList?.map(employee => (
                                                    <option value={employee.field_id}>{employee.field_name}</option>

                                                ))}

                                            </select>
                                            <MDTypography variant="button" id="error_txt_approved_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved Status <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="select" id="cmb_approval_status" className="form-select form-select-sm" value={cmb_approval_status} onChange={e => { setApprovedStatus(e.target.value); validateFields(); }} disabled={keyForViewUpdate !== 'approve'} maxLength="255" required>
                                                <option value="Pending">Pending</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Cancelled">Cancelled</option>

                                            </Form.Control>
                                            <MDTypography variant="button" id="error_cmb_approval_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Leave Reason
                                            <span className="required">*</span>
                                        </Form.Label>
                                    </div>
                                    <div className="col">

                                        <Form.Control
                                            as="textarea"
                                            className="erp_input_field optional"
                                            id="txt_leave_reason"
                                            value={txt_leave_reason}
                                            onChange={e => { setLeaveReason(e.target.value); validateFields(); }}
                                            disabled={keyForViewUpdate!=="update" && keyForViewUpdate!=="add"}
                                        />
                                        <MDTypography variant="button" id="error_txt_leave_reason" className={`erp_validation`} fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>

                    {/* </div> */}
                    <div className="erp_frm_Btns py-2">
                        <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/Masters/MGatePass/MGatePassListing')}>Back</MDButton>
                        <MDButton type="submit" id="save_Button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            onClick={() => saveShortLeaves()} fontWeight="regular">{actionLabel}</MDButton>
                        {/* {is_leaveType === 'Gate Pass' && ( */}
                        <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
                        </MDButton>
                        {/* )} */}

                    </div >
                </div>
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout >

        </>
    )
}

export default FrmGatePassEntry