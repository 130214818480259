import React, { useEffect, useRef, useMemo, useLayoutEffect } from 'react'
import { useState } from 'react'
import $, { data } from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { Tab, Table, Tabs, Form, Card } from "react-bootstrap";
import SizingProductionDetails from './component/SizingProductionDetails';
import FrmSizingMaterialMovementEntry from './component/materialMovement';
import Accordion from "react-bootstrap/Accordion";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';

// Import for the searchable combo box.
import Select from 'react-select';


function FrmTSizingProductionEntry(props) {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const [actionLabel, setActionLabel] = useState('Save');
    const [actionType, setActionType] = useState('')


    const { state } = useLocation();
    const { WeavingProductionSizingMasterId = 0, keyForViewUpdate, compType } = state || {}
    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    //useRef Hooks
    const selectRefs = useRef([])

    const handleSelectChange = (item, index, event) => {
        const currentSelectRef = selectRefs.current[index];
        updatedSizingProductionDetails('production_operator_id', item, currentSelectRef, event)
    }; const comboDataAPiCall = useRef();
    const cmb_prod_supervisor_id_ref = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const navigate = useNavigate();
    //for Approve
    const [isApprove, setIsApprove] = useState(false);


    //Sizing Production master hooks
    const [txt_weaving_production_sizing_master_id, setWeavingProductionSizingMasterId] = useState(WeavingProductionSizingMasterId);
    // const [txt_weaving_production_sizing_details_id, setWeavingProductionSizingDetailsId] = useState(0);
    const [dt_sizing_production_date, setSizingProductionDate] = useState(today);
    const [txt_production_month, setProdctionMonth] = useState();
    const [cmb_set_no, setProdSetNo] = useState();
    const [txt_production_year, setProductionYear] = useState();
    const [cmb_plant_id, setProductionPlantName] = useState(1);
    const [txt_sizing_production_code, setSizingProductionCode] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [calculationsTotal, setCalculationsTotal] = useState([])
    const [shiftNames, setShiftNames] = useState([]);
    const [txt_Production_Sizing_Status, setProductionSizingStatus] = useState('P');
    const [cmb_section_id, setProdSection] = useState();
    const [cmb_sub_section_id, setProdSubSection] = useState();
    const [cmb_shift, setShift] = useState('');
    const [cmb_prod_supervisor_id, setProdSupervisor] = useState();
    const [txt_set_length, setWarpingSetLength] = useState();
    const [txt_prod_material_name, setProdMaterialName] = useState();
    const [productMaterialId, setProdMaterialId] = useState();
    const [production_count, setProductionCount] = useState();
    const [no_of_ends, setNoOfEnds] = useState();
    const [warping_creel_ends, setWarpingCreelEnds] = useState();
    const [warping_length, setWarpingLength] = useState();
    const [warping_no_of_creels, setWarpingCreels] = useState();
    const [customer_id, setCustomerId] = useState();
    const [job_type, setJobType] = useState();
    const [warping_prod_constant, setWarpingProdConstant] = useState();

    //Sizing Production comboboxes
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);
    const [machineOperatorOptions, setMachineOperatorOptions] = useState([]);
    const [beamWarpingOptions, setBeamWarpingOptions] = useState([]);
    const [beamSizingOptions, setBeamSizingOptions] = useState([]);



    ///Sizing Production Hooks
    const [prodSummaryData, setProductionSummaryData] = useState([]);
    const [machineNameOption, setMachineNameOption] = useState([]);
    const [prevdataindexforStoppage, setPrevDataIndexForStoppage] = useState();
    const [stoppagereasonOptions, setProdStoppageReasonOptions] = useState([]);
    const [rowCount, setRowCount] = useState(1)
    const [labelForStoppage, setLabelForStopage] = useState('Add');

    //loader
    const [isLoading, setIsLoading] = useState(false);

    ///////RIng Frame Production Shift Details
    const [machineNumData, setMachineNumData] = useState([]);
    // const [spinningProdCountOptions, setSpinngProdCountOptions] = useState([]);
    const [stoppageDetails, setStoppageDetails] = useState([]);
    // const [ringFrameProdShiftData, setRingFrameProdShiftData] = useState([]);


    const sizingProdTotalObj = {
        speed: 0,
        sizing_length: 0,
        gross_weight: 0,
        tare_weight: 0,
        net_weight: 0,
        calculative_net_weight: 0,
        size_pickup: 0,
        rf: 0,
        visc: 0,
    }

    //array sent by props
    const [sizingProductionData, setsizingProductionData] = useState([]);
    const [sizingProductionMaterialData, setsizingProductionMaterialData] = useState([]);
    // const [sizingProductionTensionData, setsizingProductionTensionData] = useState([]);
    const [sizingProductionTotals, setSizingProductionTotals] = useState({ ...sizingProdTotalObj });

    //accordion open close
    const [checkIsValidate, setCheckIsValidate] = useState(false);

    ///Shiftwise Machine Wastage Entry(Ring Frame) Combobox
    const [prodWastageOptions, setProdWastageOptions] = useState([]);
    const [godownNameOptions, setGodownNameOptions] = useState([]);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Shiftwise Machine Wastage Entry Hooks
    const [cmb_prod_wastage_type, setProdWastageType] = useState();
    const [txt_act_wastages, setActWastage] = useState();
    const [labelForWastage, setLabelForWastage] = useState('Add');
    const [prevdataindexforWastage, setPrevDataIndexForWastage] = useState();
    const [upToDateShiftDetails, setUpToDateShiftDetails] = useState();
    const [txt_prod_rf_code, setProdRfCode] = useState();
    const [txt_wastage_quantity, setWastageQuantity] = useState();
    const [cmb_godownId, setgodownId] = useState();
    const [cmb_shift_wastage, setShiftWastage] = useState();

    const [wastageDetails, setWastageDetails] = useState([]);

    const sizingProductionBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        sizing_production_date: today(),
        machine_id: 0,
        weaving_production_set_no: 0,
        product_material_id: '',
        production_operator_id: 0,
        shift: 0,
        sizing_production_order_no: 0,
        sizing_production_status: 'P',
        status_remark: '',
        weaving_production_sizing_details_id: 0,
        yarn_count: 0,
        actual_count: 0,
        // warping_length: 0,
        sizing_rs: 0,
        // beam_no: 0,
        speed: 0,
        sizing_length: 0,
        total_ends: 0,
        no_of_creels: 0,
        gross_weight: 0,
        tare_weight: 0,
        net_weight: 0,
        unsize_beam_weight: 0,
        calculative_net_weight: 0,
        size_pickup: 0,
        rf: 0,
        visc: 0,
        rate: 0,
        amount: 0,
        creel_a_tension: 0,
        creel_b_tension: 0,
        sq_pres_max: 0,
        sq_pres_min: 0,
        saw_box_a_temp: 0,
        saw_box_b_temp: 0,
        strech: 0,
        moisture: 0,
        after_waxing: 0,
        leasing_tension: 0,
        t_1: 0,
        t_2: 0,
        t_3: 0,
        t_4: 0,
        winding_tension: 0,
        beam_pressing_tension: 0,
        lappers: 0,
        comb_breaks: 0,
        remark: 0,
        created_by: UserName,
        size_waste: 0,
        unsize_waste: 0,
        creel_waste: 0,
        godown_id: 6
    }


    const sizingMaterialBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        sizing_production_date: today(),
        weaving_production_set_no: 0,
        shift: 0,
        sizing_production_order_no: 0,
        product_material_sz_balance_quantity: 0,
        consumption_quantity: 0,
        status_remark: '',
        material_status: 'P',
        weaving_production_sizing_material_id: 0,
        product_material_unit_id: 0,
        created_by: UserName,
    }

    useEffect(() => {

        const loadData = async () => {
            setIsLoading(true);
            ActionType();
            await fillComboBox();
            setMonthYear();

            if (WeavingProductionSizingMasterId !== 0) {
                await FnCheckUpdateResponce();
            }
            setIsLoading(false);
            // FnGetShiftDetails(today)
        }
        loadData()

    }, [])

    const ActionType = async () => {
        debugger
        switch (keyForViewUpdate) {

            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                setIsApprove(false);
                break;

            case 'view':
                setActionType('(View)');
                // await validate.current.readOnly("Sizingproduction");
                // await validate.current.readOnly("stoppageFormId");
                $("input[type=radio]").attr('disabled', true);
                setIsApprove(false);
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;

            case 'delete':
                setActionType('(Delete)');
                await validate.current.readOnly("Sizingproduction");
                $("input[type=radio]").attr('disabled', true);
                setIsApprove(false);
                // $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;

            case 'approve':
                setActionType('(Approve)');
                setActionLabel('Approve')
                await validate.current.readOnly("Sizingproduction");
                await validate.current.readOnly("stoppageFormId");
                $("input[type=radio]").attr('disabled', true);
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");

                setIsApprove(true)
                break;


            default:
                setActionType('(Creation)');
                setIsApprove(false);
                break;
        }

    };


    const fillComboBox = async () => {
        debugger
        try {
            resetGlobalQuery();
            globalQuery.columns.push('set_no', 'customer_order_no');
            globalQuery.table = "xtv_warping_production_order";
            globalQuery.conditions.push({ field: "warping_order_status", operator: "=", value: 'A' });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ['set_no DESC'];
            let SetNoAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setSetNoOptions(SetNoAPICall);


            //Plant Options
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "cmv_plant"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getProdPlantApiCall) => {
                setPlantOptions(getProdPlantApiCall);
            })


            resetGlobalQuery();
            globalQuery.columns.push('warping_plan_product_constant');
            globalQuery.table = "xt_production_settings";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            const prodConstantAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setWarpingProdConstant(prodConstantAPICall[0]['warping_plan_product_constant']);

            // const beamSizeSizingNoAPIcall = await comboDataAPiCall.current.fillComboBox('SizedBeams')
            // setBeamSizingOptions(beamSizeSizingNoAPIcall);



            resetGlobalQuery();
            globalQuery.columns = ['beam_inwards_id', 'property_value', 'beam_no', 'customer_short_name', 'beam_inward_type'];
            globalQuery.table = "xtv_beam_inwards_table";
            globalQuery.conditions.push({ field: "beam_status", operator: "=", value: 'E', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const beamSizeSizingNoAPIcall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setBeamSizingOptions(beamSizeSizingNoAPIcall);

            ////Production Section
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "xmv_production_section"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWarpingProdSecCall) => {
                setProdSectionOptions(getWarpingProdSecCall);
                setProdSection('18');
                comboOnChange('cmb_section_id');
            })

            const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
            setShiftOptions(shiftsApiCall);

            let shift_Names = [];
            shiftsApiCall.map((shift) => {
                shift_Names.push(shift.property_name)
            })
            setShiftNames(shift_Names);



            if (keyForViewUpdate === '' || keyForViewUpdate === 'Add') {
                let productionData = sizingProductionBlankObject;
                setsizingProductionData([productionData]);

                let materialData = sizingMaterialBlankObject;
                setsizingProductionMaterialData([materialData])

            }


            // let jsonForSummaryData = {};
            // for (let key = 0; key < shift_Names.length; key++) {
            //     let data = {

            //         shift_sizing_total_length: 0,
            //         shift_size_total_waste: 0,
            //         shift_unsize_total_waste: 0,
            //         shift_creel_total_waste: 0,
            //         shift_net_total_weight: 0,
            //         shift_creel_total_upto_date_waste: 0,
            //         shift_net_total_upto_date_weight: 0,
            //         shift_size_total_upto_date_waste: 0,
            //         shift_sizing_total_upto_date_length: 0,
            //         shift_unsize_total_upto_date_waste: 0,
            //         //changes to done
            //         total: 0,
            //         total_up_date: 0
            //     }
            //     jsonForSummaryData[shift_Names[key]] = data;
            // }
            // setProductionSummaryData(jsonForSummaryData);
        } catch (error) {
            console.log('error', error);
        }
    }

    const comboOnChange = async (key) => {

        try {
            setIsLoading(true)

            switch (key) {
                case 'cmb_section_id':
                    const prod_section_Id = $('#cmb_section_id').val();
                    ///// Production sub section Arr[]
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_type');
                    globalQuery.table = "xmv_production_sub_section"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: prod_section_Id });
                    let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    setProdSubSectionOptions(getProdSubSectionApiCall)
                    setProdSubSection('23');


                    const prodsubSectionId = $('#cmb_sub_section_id').val();

                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name');
                    globalQuery.table = "cmv_machine"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "section_id", operator: "=", value: prod_section_Id });
                    globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
                    comboDataAPiCall.current.fillFiltersCombo(globalQuery).then(getProdSubSectionApiCall => {
                        setMachineNameOption(getProdSubSectionApiCall)
                        console.log("sub section list: ", getProdSubSectionApiCall);
                    });

                    ////Employees in Warping Production
                    resetGlobalQuery();
                    globalQuery.columns.push("employee_id AS field_id", 'employee_name AS field_name', 'employee_type AS employee_type');
                    globalQuery.table = "cm_employee"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    // globalQuery.conditions.push({ field: "department_id", operator: "=", value: 23 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
                        const prodSupervisorList = [
                            { value: '', label: 'Select' },
                            ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
                        ];
                        setMachineOperatorOptions(prodSupervisorList);
                    })


                    ////Godown Options
                    resetGlobalQuery();
                    globalQuery.columns = ['godown_id AS field_id', 'godown_name AS field_name', 'godown_name']
                    globalQuery.table = "cm_godown"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((godownTypeApiCall) => {
                        setGodownNameOptions(godownTypeApiCall)
                    })


                    //Generating Production Code no
                    if (keyForViewUpdate === '') {
                        await generateSizingProductionCode();
                    }

                    break;

                case 'cmb_plant_id':
                    let plantId = $('#cmb_plant_id').val();
                    setProductionPlantName(plantId);
                    break;

                case 'cmb_set_no':
                    debugger
                    let set_no = $("#cmb_set_no option:selected").text();
                    setProdSetNo(set_no);

                    ///Setting schedule quantity , noof creels, set lengt, product material name, material style & material ID
                    resetGlobalQuery();
                    globalQuery.columns = ['creel_ends', 'warping_creels', 'product_material_name', 'actual_count', 'product_material_id', 'beam_name', 't_ends', 'warping_set_length', 'length', 'customer_id', 'job_type']
                    globalQuery.table = "xtv_weaving_production_warping_details"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "weaving_production_set_no", operator: "=", value: set_no });
                    globalQuery.conditions.push({ field: 'warping_production_status', operator: "=", value: 'A' });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    let getSetNoDataApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    setProdMaterialName(getSetNoDataApiCall[0]['product_material_name']);
                    setProdMaterialId(getSetNoDataApiCall[0]['product_material_id']);
                    setProductionCount(getSetNoDataApiCall[0]['actual_count']);
                    setWarpingSetLength(getSetNoDataApiCall[0]['warping_set_length']);
                    setNoOfEnds(getSetNoDataApiCall[0]['t_ends']);
                    setCustomerId(getSetNoDataApiCall[0]['customer_id']);
                    setJobType(getSetNoDataApiCall[0]['job_type']);
                    let beamOptions = [];
                    // let warpingNoOfCreels = 0;
                    let warpingCreelEnds = 0;
                    let warpingLength = 0;
                    // let sizingTensionDetailsArr = [];

                    getSetNoDataApiCall.forEach((data, index) => {
                        // Only create and push to beamOptions if needed
                        if (data['beam_name'] || data['creel_ends']) {
                            let beamObj = {
                                beam_name: data['beam_name'],
                                creel_ends: data['creel_ends']
                            };
                            beamOptions.push(beamObj); // Add beamObj to beamOptions
                        }

                        // Aggregate values if they exist
                        if (data['creel_ends']) warpingCreelEnds += data['creel_ends'];
                        // if (data['no_of_creels']) warpingNoOfCreels += data['no_of_creels'];
                        if (data['length']) warpingLength += data['length'];

                        // Push the blank object into sizingTensionDetailsArr
                        // sizingTensionDetailsArr.push({ ...sizingTensionDetailsBlankObj });
                    });


                    // setsizingProductionTensionData([...sizingTensionDetailsArr]);
                    setWarpingCreelEnds(warpingCreelEnds);
                    setWarpingLength(warpingLength);
                    setWarpingCreels(getSetNoDataApiCall.length);
                    setBeamWarpingOptions(beamOptions);

                    resetGlobalQuery();
                    globalQuery.columns = ['beam_inwards_id', 'property_value', 'beam_no', 'customer_short_name', 'beam_inward_type'];
                    globalQuery.table = "xtv_beam_inwards_table";
                    globalQuery.conditions.push({ field: "beam_status", operator: "=", value: 'E', });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                    if (getSetNoDataApiCall[0]['job_type'] === "Job Work") {
                        // globalQuery.conditions.push({ field: "property_group", operator: "=", value: "BI", });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: getSetNoDataApiCall[0]['customer_id'] });
                    } else {
                        globalQuery.conditions.push({ field: "property_group", operator: "=", value: "PT", });
                    }
                    const beamSizeSizingNoAPIcall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setBeamSizingOptions(beamSizeSizingNoAPIcall);

                    // let processedBeamSizingOptions = beamSizeSizingNoAPIcall.map((beam) => {
                    //     // Initialize variables for field_id and field_name
                    //     let field_id = beam.beam_inwards_id; // Default to beam_inwards_id
                    //     let field_name = beam.beam_no;       // Default to beam_no

                    //     // Update field_name and field_id if job_type is "Job Work"
                    //     if (getSetNoDataApiCall[0]['job_type'] === "Job Work") {
                    //         field_name = `${beam.customer_short_name}-${beam.property_value}-${beam.beam_no}`;
                    //         field_id = `${beam.beam_inwards_id}`;
                    //     }

                    //     return {
                    //         ...beam,
                    //         field_id,   // Include field_id in the result
                    //         field_name, // Include field_name in the result
                    //     };
                    // });

                    // Set the processed options to the state
                    // setBeamSizingOptions(processedBeamSizingOptions);
                    break;
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }

    const FnCheckUpdateResponce = async () => {
        debugger

        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnShowParticularRecordForUpdate/${WeavingProductionSizingMasterId}/${COMPANY_ID}`)
            const responce = await apiCall.json();
            console.log(' Sizing Production : ', responce);

            let updateSizingProductionDetailsData = responce.WeavingProductionSizingDetailsRecord;
            let updateSizingProductionMasterData = responce.WeavingProductionSizingMasterModelRecord
            let updateSizingProductionMaterialMovement = responce.WeavingProductionSizingMaterialRecord

            if (updateSizingProductionMasterData != '' && updateSizingProductionMasterData != null) {
                setProdSetNo(updateSizingProductionDetailsData[0]['weaving_production_set_no'])

                setProdctionMonth(updateSizingProductionMasterData.prod_month)
                setProductionYear(updateSizingProductionMasterData.prod_year)
                setProductionPlantName(updateSizingProductionMasterData.plant_id)
                await comboOnChange('cmb_set_no');
                setProdSection(updateSizingProductionMasterData.section_id)
                setProdSubSection(updateSizingProductionMasterData.sub_section_id)
                await comboOnChange('subSectionOnChange');
                setSizingProductionDate(updateSizingProductionMasterData.sizing_production_date)
                setProdSupervisor(updateSizingProductionMasterData.production_supervisor_id)
                setWeavingProductionSizingMasterId(updateSizingProductionMasterData.weaving_production_sizing_master_id)
                setSizingProductionCode(updateSizingProductionMasterData.sizing_production_code)
                setWarpingCreels(updateSizingProductionDetailsData[0]['warping_creels'])
            }

            // Calculate totals for each key in TotalsSizingProd
            let totals = { ...sizingProdTotalObj };

            updateSizingProductionDetailsData.forEach((obj) => {
                Object.keys(totals).forEach((key) => {
                    totals[key] += validateNumberDateInput.current.decimalNumber(parseFloat(obj[key]), 4) || 0; // Sum each property, default to 0 if the property is missing
                });
            });

            // Calculate averages for 'speed' and 'breaks_per_million'
            const dataLength = updateSizingProductionDetailsData.length;
            if (dataLength > 0) {
                ['speed', 'size_pickup', 'rf', 'visc'].forEach((key) => {
                    totals[key] = validateNumberDateInput.current.decimalNumber((totals[key] / dataLength), 4); // Compute the average
                });
            }
            setSizingProductionTotals(totals);


            if (updateSizingProductionDetailsData !== null && updateSizingProductionDetailsData !== "") {
                setsizingProductionData(updateSizingProductionDetailsData);
            }

            if (updateSizingProductionMaterialMovement !== null && updateSizingProductionMaterialMovement !== "") {
                setsizingProductionMaterialData(updateSizingProductionMaterialMovement)
            }

            // fnManageSizingStatus(updateSizingProductionDetailsData, updateSizingProductionMaterialMovement)
            //Disable the particular fields in Table...
            updateSizingProductionDetailsData.map((obj, index) => {
                // Use a more specific selector to get the row matching the current index
                const row = $(`#sizingProductionTbl tbody tr[rowindex="${index}"]`);
                // Check if the rowindex matches the current index
                if (obj['sizing_production_status'] === 'A') {
                    if (parseInt(row.attr('rowindex')) === parseInt(index)) {
                        row.find('input, select, Select').attr('disabled', true);
                    }
                }
            });

        } catch (error) {
            console.log('error: ', error);
        }
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TSizingProduction/FrmTSizingProductionListing/reg' : `/Transactions/TSizingProduction/FrmTSizingProductionListing`;
        navigate(Path);
    }

    const setMonthYear = () => {
        let prod_Plan_Dt = $("#dt_sizing_production_date").val();
        if (prod_Plan_Dt != '') {
            setSizingProductionDate(prod_Plan_Dt);
            let dateComponents = prod_Plan_Dt.split('-');
            setProdctionMonth(dateComponents[1]);
            setProductionYear(dateComponents[0])
        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('Sizingproduction')
        validate.current.validateFieldsOnChange('stoppageFormId')
        validate.current.validateFieldsOnChange('wastageFormId')
    }

    // const renderproductionsummary = useMemo(() => {

    //     let namesOfTheShift = [...shiftNames];
    //     const prodSummaryRowsFirstCol = ['Sizing Length (mtr)', 'Sizing Weight (kg)', 'Sizing Waste (kg)', 'Unsized Waste (kg)', 'Creels Waste (kg)'];
    //     return (
    //         <Table id="todaysProdSummaryTableId" bordered striped>
    //             <thead className="erp_table_head">
    //                 <tr>
    //                     <th className="erp_table_th"></th>
    //                     <th className="erp_table_th text-center" colSpan={3}>
    //                         Today's Production Summary
    //                     </th>
    //                     <th className="erp_table_th text-center" colSpan={3}>
    //                         Up To Day's Production Summary
    //                     </th>
    //                 </tr>
    //             </thead>
    //             <thead className="erp_table_head">
    //                 <tr>
    //                     <th className="erp_table_th">Description</th>
    //                     {shiftOptions?.map((header, indexOfItem) => (
    //                         <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
    //                             {header.field_name}
    //                         </th>
    //                     ))}
    //                     <th className="erp_table_th">Total</th>
    //                     {shiftOptions?.map((header, indexOfItem) => (
    //                         <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
    //                             {header.field_name}
    //                         </th>
    //                     ))}
    //                     <th className="erp_table_th">Total</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {namesOfTheShift.length === 0 ? (<tr></tr>) : (
    //                     <React.Fragment>
    //                         {/* First Row */}
    //                         <tr>
    //                             <th className="erp_table_th">{prodSummaryRowsFirstCol[0]}</th>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_sizing_total_length}
    //                                     </td>

    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_sizing_total_length, 0)}
    //                                 </td>
    //                             </>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_sizing_total_upto_date_length}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_sizing_total_upto_date_length, 0)}
    //                                 </td>
    //                             </>
    //                         </tr>
    //                         {/* Second Row */}

    //                         <tr>
    //                             <th className="erp_table_th">{prodSummaryRowsFirstCol[1]}</th>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_net_total_weight}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_net_total_weight, 0)}
    //                                 </td>
    //                             </>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_net_total_upto_date_weight}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_net_total_upto_date_weight, 0)}
    //                                 </td>
    //                             </>
    //                         </tr>
    //                         {/* Third Row */}
    //                         <tr>
    //                             <th className="erp_table_th">{prodSummaryRowsFirstCol[2]}</th>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_size_total_waste}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_size_total_waste, 0)}
    //                                 </td>
    //                             </>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_size_total_upto_date_waste}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_size_total_upto_date_waste, 0)}
    //                                 </td>
    //                             </>
    //                         </tr>
    //                         {/* Four Row */}
    //                         <tr>
    //                             <th className="erp_table_th">{prodSummaryRowsFirstCol[3]}</th>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_unsize_total_waste}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_unsize_total_waste, 0)}
    //                                 </td>
    //                             </>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_unsize_total_upto_date_waste}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_unsize_total_upto_date_waste, 0)}
    //                                 </td>
    //                             </>
    //                         </tr>
    //                         {/* five Row */}
    //                         <tr>
    //                             <th className="erp_table_th">{prodSummaryRowsFirstCol[4]}</th>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_creel_total_waste}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_creel_total_waste, 0)}
    //                                 </td>
    //                             </>
    //                             <>
    //                                 {namesOfTheShift.map((key, index) => (
    //                                     <td key={key} className="erp_table_td">
    //                                         {prodSummaryData[key].shift_creel_total_upto_date_waste}
    //                                     </td>
    //                                 ))}
    //                                 <td className="erp_table_td">
    //                                     {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_creel_total_upto_date_waste, 0)}
    //                                 </td>
    //                             </>
    //                         </tr>

    //                     </React.Fragment>
    //                 )}
    //             </tbody>
    //         </Table>
    //     )
    // }, [prodSummaryData]);


    // const FnGetShiftDetails = async () => {
    //     debugger
    //     setShift("I");
    //     let namesOfTheShift = [...shiftOptions];
    //     let sizing_production_date = $('#dt_sizing_production_date').val();
    //     try {
    //         //change API
    //         const getShiftApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnShowParticularSizingShiftSummary/${sizing_production_date}/${COMPANY_ID}`);
    //         const respForShiftApiCall = await getShiftApiCall.json();
    //         let productSummaryData = respForShiftApiCall.data;
    //         let upToDateShift_Details = {};
    //         namesOfTheShift.map((shift) => {
    //             if (productSummaryData[shift] && productSummaryData[shift].hasOwnProperty('shift_sizing_total_length')) {
    //                 upToDateShift_Details[shift] = {
    //                     shift_sizing_total_length: productSummaryData[shift].shift_sizing_total_length,
    //                     shift_net_total_weight: productSummaryData[shift].shift_net_total_weight,
    //                     shift_size_total_waste: productSummaryData[shift].shift_size_total_waste,
    //                     shift_unsize_total_waste: productSummaryData[shift].shift_unsize_total_waste,
    //                     shift_creel_total_waste: productSummaryData[shift].shift_creel_total_waste,
    //                     shift_sizing_total_upto_date_length: productSummaryData[shift].shift_sizing_total_upto_date_length,
    //                     shift_net_total_upto_date_weight: productSummaryData[shift].shift_net_total_upto_date_weight,
    //                     shift_size_total_upto_date_waste: productSummaryData[shift].shift_size_total_upto_date_waste,
    //                     shift_unsize_total_upto_date_waste: productSummaryData[shift].shift_unsize_total_upto_date_waste,
    //                     shift_creel_total_upto_date_waste: productSummaryData[shift].shift_creel_total_upto_date_waste,
    //                 };
    //             } else {
    //                 upToDateShift_Details[shift] = {};
    //             }
    //         })
    //         setProductionSummaryData(productSummaryData);
    //     } catch (error) {
    //         console.log('error', error);
    //     }
    // }
    // ------------------------------------------save validation---------------------------------------------------------------



    const validateMaterialDetails = async () => {
        debugger;
        if (sizingProductionMaterialData.length > 0) {
            for (let index = 0; index < sizingProductionMaterialData.length; index++) {
                const element = sizingProductionMaterialData[index];
                const { weaving_production_set_no, shift, product_material_id = '', consumptionQtyInfo = 0 } = element;

                if (weaving_production_set_no !== 0 || (shift !== '' && shift !== 0) || (product_material_id !== '' &&
                    product_material_id !== undefined) || consumptionQtyInfo !== 0) {
                    if (shift === '') {
                        setErrMsg('Please Select at Shift in sizing material movement...!');
                        setShowErrorMsgModal(true);
                        return false;
                    } else if (weaving_production_set_no === 0) {
                        setErrMsg('Please Select at Set NO. in sizing material movement...!');
                        setShowErrorMsgModal(true);
                        return false;
                    } else if (product_material_id === '') {
                        setErrMsg('Please Select Material Name in sizing material movement...!');
                        setShowErrorMsgModal(true);
                        return false;
                    } else if (consumptionQtyInfo === 0) {
                        setErrMsg(`Please properly consume the stock for Shift-${shift} & Set No. - ${weaving_production_set_no} in the material movement section.`);
                        setShowErrorMsgModal(true);
                        return false;
                    }
                    return true;
                } else {
                    return true;
                }

            }
        } else {
            return true;
        }
        // let lastRowSelectSetNo = '';
        // let lastRowSelectShift = '';
        // let lastRowSelectMaterialname = '';
        // let lastRowSelectconsumptionqty = '';

        // if (sizingProductionMaterialData.length > 0) {

        //     const lastRowIndex = sizingProductionMaterialData.length - 1;
        //     lastRowSelectSetNo = sizingProductionMaterialData[lastRowIndex].weaving_production_set_no;
        //     lastRowSelectShift = sizingProductionMaterialData[lastRowIndex].shift;
        //     lastRowSelectMaterialname = sizingProductionMaterialData[lastRowIndex].product_material_id;
        //     lastRowSelectconsumptionqty = sizingProductionMaterialData[lastRowIndex].consumption_quantity;


        //     if (lastRowSelectSetNo !== '' || lastRowSelectShift !== '' || lastRowSelectMaterialname !== '' || lastRowSelectconsumptionqty !== 0) {
        //         if (lastRowSelectShift === '') {
        //             setErrMsg('Please Select at Shift in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         } else if (lastRowSelectSetNo === '') {
        //             setErrMsg('Please Select at Set NO. in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         } else if (lastRowSelectMaterialname === '') {
        //             setErrMsg('Please Select Material Name in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         } else if (lastRowSelectconsumptionqty === 0) {
        //             setErrMsg('Please Consumption Qty in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         }
        //         return true;
        //     } else {
        //         return true;
        //     }
        // } else {
        //     return true;
        // }
    }

    //--------------------------------------Production Details------------------------------------------------------//
    const setRowCountAndAddRow = () => {

        const tableRows = document.querySelectorAll('#sizingProductionTbl tbody tr');
        let lastRowSetNo = '';
        let lastRowMachinesNo = '';
        // let lastRowMachineOperator = '';
        let lastRowShift = '';

        // let lastRowBeamno = '';
        let lastRowsizinglength = '';
        let lastRowgrossweight = '';



        if (tableRows.length > 0) {
            const lastRowIndex = tableRows.length - 1;
            const lastRowSelectSetNo = tableRows[lastRowIndex].querySelector('select[id^="weaving_production_set_no_"]');
            if (lastRowSelectSetNo) {
                lastRowSetNo = lastRowSelectSetNo.value;
            }

            const lastRowSelectMachinesNo = tableRows[lastRowIndex].querySelector('select[id^="machine_id_"]');
            if (lastRowSelectMachinesNo) {
                lastRowMachinesNo = lastRowSelectMachinesNo.value;
            }

            // const lastRowSelectMachineOperator = tableRows[lastRowIndex].querySelector('select[id^="production_operator_id_"]');
            // if (lastRowSelectMachineOperator) {
            //     lastRowMachineOperator = lastRowSelectMachineOperator.value;
            // }

            const lastRowSelectShift = tableRows[lastRowIndex].querySelector('select[id^="shift_"]');
            if (lastRowSelectShift) {
                lastRowShift = lastRowSelectShift.value;
            }

            // const lastRowSelectBeamno = tableRows[lastRowIndex].querySelector('input[id^="beam_no_"]');
            // if (lastRowSelectBeamno) {
            //     lastRowBeamno = lastRowSelectBeamno.value;
            // }

            const lastRowSelectsizinglength = tableRows[lastRowIndex].querySelector('input[id^="sizing_length_"]');
            if (lastRowSelectsizinglength) {
                lastRowsizinglength = lastRowSelectsizinglength.value;
            }
            const lastRowSelectgrossweight = tableRows[lastRowIndex].querySelector('input[id^="gross_weight_"]');
            if (lastRowSelectgrossweight) {
                lastRowgrossweight = lastRowSelectgrossweight.value;
            }

        }

        if (lastRowSetNo !== '' && lastRowMachinesNo !== '' && lastRowShift !== '' && (lastRowsizinglength !== '' && parseInt(lastRowsizinglength) !== 0) && (lastRowgrossweight !== '' && parseInt(lastRowgrossweight) !== 0)) {
            const getExistingsizingProductionData = [...sizingProductionData]
            getExistingsizingProductionData.push(sizingProductionBlankObject)
            setsizingProductionData(getExistingsizingProductionData)

            setRowCount(rowCount + 1);
        } else {
            const lastRow = tableRows[tableRows.length - 1];
            const setNoInput = lastRow.querySelector('select[id^="weaving_production_set_no_"]');
            const machinesNoInput = lastRow.querySelector('select[id^="machine_id_"]');
            const machineOperatorInput = lastRow.querySelector('select[id^="production_operator_id_"]');
            const shiftInput = lastRow.querySelector('select[id^="shift_"]');

            const sizinglengthInput = lastRow.querySelector('input[id^="sizing_length_"]');
            const grossweightInput = lastRow.querySelector('input[id^="gross_weight_"]');



            if (machinesNoInput) {
                const selectedOption = machinesNoInput.options[machinesNoInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    machinesNoInput.parentElement.dataset.tip = 'Please select Machine';
                    machinesNoInput.focus();
                    return;
                } else {
                    delete machinesNoInput.parentElement.dataset.tip;
                }
            }

            if (machineOperatorInput) {
                const selectedOption = machineOperatorInput.options[machineOperatorInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    machineOperatorInput.parentElement.dataset.tip = 'Please select  Machine Operator';
                    machineOperatorInput.focus();
                    return;
                } else {
                    delete machineOperatorInput.parentElement.dataset.tip;
                }
            }

            // if (setNoInput) {
            //     const selectedOption = setNoInput.options[setNoInput.selectedIndex];
            //     if (!selectedOption || !selectedOption.value) {
            //         setNoInput.parentElement.dataset.tip = 'Please select SetNO.';
            //         setNoInput.focus();
            //         return;
            //     } else {
            //         delete setNoInput.parentElement.dataset.tip;
            //     }
            // }

            if (shiftInput) {
                const selectedOption = shiftInput.options[shiftInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    shiftInput.parentElement.dataset.tip = 'Please select Shift';
                    shiftInput.focus();
                    return;
                } else {
                    delete shiftInput.parentElement.dataset.tip;
                }
            }

            if (sizinglengthInput.value === '0' || sizinglengthInput.value === '') {
                sizinglengthInput.parentElement.dataset.tip = 'Please enter Sizing Length...!';
                sizinglengthInput.focus();
                return;
            } else {
                delete sizinglengthInput.parentElement.dataset.tip;
            }

            if (grossweightInput.value === '0' || grossweightInput.value === '') {
                grossweightInput.parentElement.dataset.tip = 'Please enter Gross Weight...!';
                grossweightInput.focus();
                return;
            } else {
                delete grossweightInput.parentElement.dataset.tip;
            }


        }
    };

    const validateSizingProductionDetails = () => {
        debugger
        try {
            let sizingProductionDetails = [...sizingProductionData];
            let validation = true;

            for (let i = 0; i < sizingProductionDetails.length; i++) {
                let data = sizingProductionDetails[i];

                for (let key in data) {
                    let tabledataId = $("#" + key + `_${i}`);

                    if (key === 'status_remark' || key === 'net_weight' || key === 'size_pickup' || key === 'calculative_net_weight') {

                    } else if (key !== 'production_operator_id') {
                        if (tabledataId.is(':visible') && (['', 0, '0', null].includes(tabledataId.val()))) {
                            if (tabledataId.attr('type') === 'text' || tabledataId.attr('type') === 'remark' && !tabledataId.is(':disabled')) {
                                tabledataId.parent().attr('data-tip', 'Please Enter proper data...!');
                            } else {
                                tabledataId.parent().attr('data-tip', 'Please Select atleast one...!');
                            }

                            tabledataId.focus();
                            validation = false;
                            break;
                        }
                    } else if (data['production_operator_id'] === '' || data['production_operator_id'] === 0 || data['production_operator_id'] === null) {
                        tabledataId.addClass('required-field');
                        tabledataId.attr('data-tip', 'Please select operator');
                        validation = false;
                        break; // Exit inner loop
                    }
                }

                if (!validation) {
                    break; // Exit outer loop if validation failed
                }
            }

            return validation; // Return the overall validation status
        } catch (error) {
            console.error("Validation error:", error); // Log any errors
            return false; // Return false if an error occurs
        }
    };


    const FnAddRemoveSizingTblRow = (rowIndex, action) => {

        let sizingProductionDetails = [...sizingProductionData];
        // if () {
        if (action === 'add') {
            if (validateSizingProductionDetails() === true) {
                // Add a new empty object at the beginning of the array
                sizingProductionBlankObject['rf'] = sizingProductionDetails[0]['rf'];
                sizingProductionBlankObject['visc'] = sizingProductionDetails[0]['visc'];
                sizingProductionBlankObject['creel_a_tension'] = sizingProductionDetails[0]['creel_a_tension'];
                sizingProductionBlankObject['creel_b_tension'] = sizingProductionDetails[0]['creel_b_tension'];
                sizingProductionBlankObject['sq_pres_max'] = sizingProductionDetails[0]['sq_pres_max'];
                sizingProductionBlankObject['sq_pres_min'] = sizingProductionDetails[0]['sq_pres_min'];
                sizingProductionBlankObject['saw_box_a_temp'] = sizingProductionDetails[0]['saw_box_a_temp'];
                sizingProductionBlankObject['saw_box_b_temp'] = sizingProductionDetails[0]['saw_box_b_temp'];

                sizingProductionBlankObject['moisture'] = sizingProductionDetails[0]['moisture'];
                sizingProductionBlankObject['after_waxing'] = sizingProductionDetails[0]['after_waxing'];
                sizingProductionBlankObject['leasing_tension'] = sizingProductionDetails[0]['leasing_tension'];
                sizingProductionBlankObject['t_1'] = sizingProductionDetails[0]['t_1'];
                sizingProductionBlankObject['t_2'] = sizingProductionDetails[0]['t_2'];
                sizingProductionBlankObject['t_3'] = sizingProductionDetails[0]['t_3'];
                sizingProductionBlankObject['t_4'] = sizingProductionDetails[0]['t_4'];
                sizingProductionBlankObject['winding_tension'] = sizingProductionDetails[0]['winding_tension'];
                sizingProductionBlankObject['beam_pressing_tension'] = sizingProductionDetails[0]['beam_pressing_tension'];

                setsizingProductionData([...sizingProductionDetails, sizingProductionBlankObject]);
            }
        } else {
            // Remove the specified row
            sizingProductionDetails.splice(rowIndex, 1);
            if (sizingProductionDetails.length > 0) {

                let totals = { ...sizingProdTotalObj };

                // Calculate totals for each key in TotalsSizingProd
                sizingProductionDetails.forEach((obj) => {
                    Object.keys(totals).forEach((key) => {
                        totals[key] += validateNumberDateInput.current.decimalNumber(parseFloat(obj[key]), 4) || 0; // Sum each property, default to 0 if the property is missing
                    });
                });

                // Calculate averages for 'speed' and 'breaks_per_million'
                const dataLength = sizingProductionDetails.length;
                if (dataLength > 0) {
                    ['speed', 'size_pickup', 'rf', 'visc'].forEach((key) => {
                        totals[key] = validateNumberDateInput.current.decimalNumber((totals[key] / dataLength), 4); // Compute the average
                    });
                }
                setSizingProductionTotals(totals);
                setsizingProductionData([...sizingProductionDetails]);
            }
        }
        // }
    }


    const SizingrenderProductionDetails = useMemo(() => {

        if (!sizingProductionData) {
            return null;
        }

        return <Table id='sizingProductionTbl' className={`erp_table ${sizingProductionData?.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >Action</th>
                    <th className="erp_table_th" >SR NO.</th>
                    <th className="erp_table_th" >Date</th>
                    <th className="erp_table_th" >MACHINE NO.</th>
                    <th className="erp_table_th" >SHIFT</th>
                    <th className="erp_table_th" >MACHINE OPERATOR</th>
                    {/* <th className="erp_table_th" >SET NO.</th> */}
                    <th className="erp_table_th" >BEAM NO.</th>
                    {/* <th className="erp_table_th" style={{ textAlign: 'center', paddingRight: "100px" }}>MATERIAL NAME</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>Sizing Production <br></br> Order No</th> */}
                    <th className="erp_table_th" rowSpan={2}>SPEED</th>
                    <th className="erp_table_th" rowSpan={2}>BEAM <br></br> LENGTH</th>
                    {/*<th className="erp_table_th" rowSpan={2}>NO OF <br></br> CREELS</th>
                    <th className="erp_table_th" rowSpan={2}>TOTAL NO<br></br> OF ENDS</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>YARN <br></br> COUNT</th>
                    <th className="erp_table_th" rowSpan={2}>ACTUAL <br></br>  COUNT</th> */}

                    {/* <th className="erp_table_th" >R.S(Inches)</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>SIZING <br></br> BEAM NO.</th>
                    <th className="erp_table_th" rowSpan={2}>SIZING <br></br> LENGTH</th> */}
                    <th className="erp_table_th" rowSpan={2}>GROSS <br></br> WEIGHT</th>
                    <th className="erp_table_th" rowSpan={2}>TARE <br></br> WEIGHT</th>
                    <th className="erp_table_th" rowSpan={2}>NET <br></br> WEIGHT</th>
                    {/* <th className="erp_table_th" rowSpan={2}>UNSIZE <br></br> BEAM WEIGHT</th> */}
                    <th className="erp_table_th" rowSpan={2}>CALCULATIVE <br></br>NET WEIGHT</th>
                    <th className="erp_table_th" rowSpan={2}>SIZE <br></br> PICK-UP %</th>
                    <th className="erp_table_th" rowSpan={2}>RF%</th>
                    <th className="erp_table_th" rowSpan={2}>VISC(sec)</th>

                    <th className="erp_table_th" rowSpan={2}>CREEL A <br></br> TENSION</th>
                    <th className="erp_table_th" rowSpan={2}>CREEL B <br></br> TENSION</th>
                    <th className="erp_table_th" rowSpan={2}>SQ. PRES. <br></br> MINIMUM</th>
                    <th className="erp_table_th" rowSpan={2}>SQ. PRES. <br></br> MAXIMUM</th>
                    <th className="erp_table_th" rowSpan={2}>SAW BOX-A <br></br> TEMP.</th>
                    <th className="erp_table_th" rowSpan={2}>SAW BOX-B <br></br> TEMP.</th>
                    <th className="erp_table_th" >STRECH %</th>
                    <th className="erp_table_th" >MOISTURE %</th>
                    <th className="erp_table_th" rowSpan={2}>AFTER<br></br>WAXING %</th>
                    <th className="erp_table_th" rowSpan={2}>LEASING<br></br>TENSION</th>
                    <th className="erp_table_th" rowSpan={2}>T-1</th>
                    <th className="erp_table_th" rowSpan={2}>T-2</th>
                    <th className="erp_table_th" rowSpan={2}>T-3</th>
                    <th className="erp_table_th" rowSpan={2}>T-4</th>
                    <th className="erp_table_th" rowSpan={2}>WINDING<br></br>TENSION</th>
                    <th className="erp_table_th" rowSpan={2}>BEAM PRESS.<br></br>TENSION</th>
                    <th className="erp_table_th" >COMB<br></br> BREAKS</th>
                    <th className="erp_table_th" >LAPPERS</th>
                    {/* <th className="erp_table_th" >REMARK</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>SIZE <br></br> WASTE</th>
                    <th className="erp_table_th" rowSpan={2}>UNSIZE <br></br> WASTE</th>
                    <th className="erp_table_th" rowSpan={2}>CREEL <br></br> WASTE</th> */}
                    <th className="erp_table_th" >RATE</th>
                    <th className="erp_table_th" >AMOUNT</th>
                    {/* <th className="erp_table_th" rowSpan={2}>Sizing Prod. Status</th> */}
                    {/* <th className="erp_table_th" >Status Remark</th> */}
                    <th className="erp_table_th" >Godown</th>
                </tr>
            </thead>
            <tbody>
                {sizingProductionData?.map((item, index) =>

                    <tr rowindex={index} className={`sticky-column ${item.sizing_production_status === 'A' ? 'disabled-row' : ''}`}>
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                            
                        <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => FnAddRemoveSizingTblRow(index, 'add')} />
                            {item.weaving_production_sizing_details_id === 0 ?
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => FnAddRemoveSizingTblRow(index, 'remove')} />
 : ''}
                        </td>

                        <td className='erp_table_td'>
                            {index + 1}
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="date" className="erp_input_field mb-0" Headers="sizing_production_date" id={`sizing_production_date_${index}`} value={item.sizing_production_date} onChange={(e) => updatedSizingProductionDetails("sizing_production_date", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.sizing_production_date
                            }
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`machine_id_${index}`} value={item.machine_id} onChange={(e) => updatedSizingProductionDetails("machine_id", item, e)} className="erp_input_field_table_txt mb-0" disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                        Headers="machine_id" >
                                        <option value="">Select</option>
                                        {machineNameOption?.map(machine => (
                                            <option value={machine.field_id}>{machine.machine_id}{machine.field_name}</option>
                                        ))}
                                    </select>
                                    : item.machine_name
                            }
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`shift_${index}`} value={item.shift} onChange={(e) => updatedSizingProductionDetails("shift", item, e)} className="erp_input_field_table_txt mb-0" Headers="shift" disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} >
                                        <option value="">Select</option>
                                        {shiftOptions?.map(shift => (
                                            <option value={shift.field_name}>{shift.field_name}</option>
                                        ))}
                                    </select>
                                    : item.shift
                            }
                        </td>

                        <td className='erp_table_td' id={`tooltipforsupervisor_${index}`}>
                            {/* <Tooltip title="Select Supervisor..." id={`tooltipforsupervisor_${index}`} placement="top"> */}
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? (
                                        <Select

                                            ref={(ref) => (selectRefs.current[index] = ref)}
                                            options={machineOperatorOptions.filter(options => options.employee_type === 'Worker')}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            id={`production_operator_id_${index}`}
                                            value={machineOperatorOptions.find(option => option.field_id === parseInt(item.production_operator_id))}

                                            onChange={(e) => handleSelectChange(item, index, e)}
                                            placeholder="Search Operator here..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    width: '200px', // Set the width of the option box
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    width: '200px', // Set the width of the menu box
                                                    zIndex: 1000, // Ensure this is higher than the table's zIndex
                                                }),
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 1000, // Ensure this is higher than the table's zIndex
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    width: '160px',
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                            menuPortalTarget={document.body}
                                        />
                                    ) : item.production_operator_name
                            }
                            {/* </Tooltip> */}
                        </td>



                        {/* <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <select id={`weaving_production_set_no_${index}`} value={item.weaving_production_set_no} className="erp_input_field_table_txt mb-0"
                                        Headers="weaving_production_set_no"
                                        disabled={item.warping_production_status_desc === 'Approved'}
                                        onChange={(e) => { handleSetNoChange(item, e); }}
                                        style={{ width: '100px' }}
                                    >
                                        <option value=''>Select</option>
                                        {
                                            //array in ascending order
                                            setNoOptions.sort((a, b) => a.set_no - b.set_no).map(setNO => (
                                                <option value={setNO.weaving_production_set_no}>{setNO.weaving_production_set_no}</option>
                                            ))}
                                    </select>
                                    : item.weaving_production_set_no
                            }
                        </td> */}

                        {/* <td className={`erp_table_td`} style={{ position: "relative" }}>
                            <div className='col-auto'>
                                <div className="form-select form-select-sm"
                                    onClick={() => FnToggleBeamSizeList(index, item)}
                                    data-optional="optional">
                                    {'Select'}
                                </div>
                                <ul className="list-items beam-no-ul" id={`beam-no-order-ul_${index}`}
                                    style={{
                                        marginTop: '1px',
                                        position: "fixed",
                                        top: "100px", // Adjust this value to position the dropdown correctly
                                        left: "0",
                                        zIndex: 1000, // High z-index to ensure it appears above other elements
                                        backgroundColor: "#fff",
                                        border: "1px solid #ccc",
                                        width: "100px",
                                        display: "none"  // Initially hidden
                                    }}>
                                    {beamWarpingOptions.length !== 0 ?
                                        beamWarpingOptions.map((beamNo, rowindex) =>
                                            <li className={`item beamno_order_no`} key={index}>
                                                <span className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name={`beamnumberCheckBox`}
                                                        value={beamNo.field_id}
                                                        onClick={(e) => (GetBeamNotoArr('beam_no', item, e, `beam-no-order-ul_${index}`))}
                                                        className="erp_radio_button beamnumberCheckBox"
                                                        optional='optional'
                                                    />
                                                </span>
                                                <span className="item-text">{beamNo.field_name}</span>
                                            </li>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        </td> */}

                        <td className={`erp_table_td `}>
                            {item.weaving_production_sizing_details_id === 0 ? (

                                <select id={`sizing_beam_no_${index}`} value={item.sizing_beam_no} className="erp_input_field_table_txt mb-0" Headers="sizing_beam_no" onChange={(e) => { updatedSizingProductionDetails('sizing_beam_no', item, e); }}
                                    disabled={item.sizing_production_status_desc === 'Approved'} >
                                    <option value="">Select</option>
                                    {beamSizingOptions?.map(beamSize => (
                                        <option value={beamSize.beam_inwards_id}>{beamSize.beam_inward_type}</option>
                                    ))}
                                </select>
                            ) : (
                                <span>{item.sizing_beam_name}</span>
                            )}
                        </td>

                        {/* <td className='erp_table_td'>

                            <input type="text" className="erp_input_field mb-0" id={`product_material_name_${index}`} value={item.product_material_name} disabled />

                        </td> */}

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field mb-0" Headers="sizing_production_date" id={`sizing_production_order_no_${index}`} value={item.sizing_production_order_no} onChange={(e) => updatedSizingProductionDetails("sizing_production_order_no", item, e)} disabled />
                                    </>
                                    : item.sizing_production_order_no
                            }
                        </td> */}

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`speed_${index}`} value={item.speed} maxLength='19' onChange={(e) => updatedSizingProductionDetails("speed", item, e)} />
                                    </>
                                    : item.speed
                            }
                        </td>

                        <td className='erp_table_td'>
                            {/* {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`warping_length_${index}`} value={item.warping_length} maxLength='19' onChange={(e) => updatedSizingProductionDetails("warping_length", item, e)} />
                                    </>
                                    : item.warping_length
                            } */}

                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" style={{ backgroundColor: '#AFE1AF', width: '75px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`sizing_length_${index}`} value={item.sizing_length}
                                            disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedSizingProductionDetails("sizing_length", item, e)} />
                                    </>
                                    : item.sizing_length
                            }
                        </td>

                        {/*<td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`no_of_creels_${index}`} value={item.no_of_creels} onChange={(e) => updatedSizingProductionDetails("no_of_creels", item, e)} disabled />
                                    </>
                                    : item.no_of_creels
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`total_ends_${index}`} value={item.total_ends} onChange={(e) => updatedSizingProductionDetails("total_ends", item, e)} disabled />
                                    </>
                                    : item.total_ends
                            }
                        </td> */}

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" style={{ width: '75px' }} id={`production_count_id_${index}`} value={item.yarn_count} onChange={(e) => updatedSizingProductionDetails("yarn_count", item, e)} disabled />
                                    </>
                                    : item.yarn_count
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`actual_count_${index}`} value={item.actual_count} onChange={(e) => updatedSizingProductionDetails("actual_count", item, e)} disabled />
                                    </>
                                    : item.actual_count
                            }
                        </td> */}



                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`sizing_rs_${index}`} value={item.sizing_rs} onChange={(e) => updatedSizingProductionDetails("sizing_rs", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.sizing_rs
                            }
                        </td> */}
                        {/* beamSizingOptions */}


                        {/*
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" style={{ backgroundColor: '#AFE1AF', width: '75px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`sizing_length_${index}`} value={item.sizing_length}
                                            disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedSizingProductionDetails("sizing_length", item, e)} />
                                    </>
                                    : item.sizing_length
                            }
                        </td> */}



                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ?
                                    <>
                                        <input type="text" style={{ backgroundColor: '#AFE1AF', width: '75px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`gross_weight_${index}`} value={item.gross_weight}
                                            disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedSizingProductionDetails("gross_weight", item, e)} />
                                    </>
                                    : item.gross_weight
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'

                                    ? <>
                                        <input type="text" style={{ backgroundColor: '#AFE1AF', width: '75px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`tare_weight_${index}`} value={item.tare_weight}
                                            onChange={(e) => updatedSizingProductionDetails("tare_weight", item, e)} />
                                    </>
                                    : item.tare_weight
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" style={{ width: '75px' }} className="erp_input_field_table_txt mb-0 text-end" id={`net_weight_${index}`} value={item.net_weight} disabled />
                                    </>
                                    : item.net_weight
                            }
                        </td>

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0text-end" style={{ width: '75px' }} id={`unsize_beam_weight_${index}`} value={item.unsize_beam_weight} onChange={(e) => updatedSizingProductionDetails("unsize_beam_weight", item, e)} disabled />
                                    </>
                                    : item.unsize_beam_weight
                            }
                        </td> */}


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`calculative_net_weight_${index}`} value={item.calculative_net_weight} onChange={(e) => updatedSizingProductionDetails("calculative_net_weight", item, e)} disabled />
                                    </>
                                    : item.calculative_net_weight
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`size_pickup_${index}`} value={item.size_pickup} onChange={(e) => updatedSizingProductionDetails("size_pickup", item, e)} disabled />
                                    </>
                                    : item.size_pickup
                            }
                        </td>



                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 remove0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`size_waste_${index}`} value={item.size_waste} onChange={(e) => updatedSizingProductionDetails("size_waste", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.size_waste
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 remove0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`unsize_waste_${index}`} value={item.unsize_waste} onChange={(e) => updatedSizingProductionDetails("unsize_waste", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.unsize_waste
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 remove0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`creel_waste_${index}`} value={item.creel_waste} onChange={(e) => updatedSizingProductionDetails("creel_waste", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </> : item.creel_waste
                            }
                        </td> */}

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`rf_${index}`} value={item.rf} style={{ backgroundColor: '#AFE1AF', width: '75px' }} Headers='rf'
                                            disabled={item.sizing_production_status_desc === 'Approved'} onChange={(e) => updatedSizingProductionDetails("rf", item, e)} />
                                    </> : item.rf
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`visc_${index}`} value={item.visc} onChange={(e) => updatedSizingProductionDetails("visc", item, e)} disabled={item.sizing_production_status_desc === 'Approved'} />
                                    </>
                                    : item.visc
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '60px' }} id={`creel_a_tension_${index}`} value={item.creel_a_tension} maxLength='19' onChange={(e) => updatedSizingProductionDetails("creel_a_tension", item, e)} />
                                    </>
                                    : item.creel_a_tension
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '60px' }} id={`creel_b_tension_${index}`} value={item.creel_b_tension} maxLength='19' onChange={(e) => updatedSizingProductionDetails("creel_b_tension", item, e)} />
                                    </>
                                    : item.creel_b_tension
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ?
                                    <><input type="text" style={{ backgroundColor: '#AFE1AF', width: '70px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`sq_pres_min_${index}`} value={item.sq_pres_min}
                                        // disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                        onChange={(e) => updatedSizingProductionDetails("sq_pres_min", item, e)} />
                                    </>
                                    : item.sq_pres_min
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`sq_pres_max_${index}`} style={{ backgroundColor: '#AFE1AF', width: '70px' }} value={item.sq_pres_max} onChange={(e) => updatedSizingProductionDetails("sq_pres_max", item, e)} />
                                    </>
                                    : item.sq_pres_max
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" style={{ backgroundColor: '#AFE1AF', width: '70px' }} id={`saw_box_a_temp_${index}`} value={item.saw_box_a_temp} onChange={(e) => updatedSizingProductionDetails("saw_box_a_temp", item, e)} />
                                    </>
                                    : item.saw_box_a_temp
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 " style={{ backgroundColor: '#AFE1AF', width: '70px' }} id={`saw_box_b_temp_${index}`} value={item.saw_box_b_temp} onChange={(e) => updatedSizingProductionDetails("saw_box_b_temp", item, e)} />
                                    </>
                                    : item.saw_box_b_temp
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`strech_${index}`} value={item.strech} style={{ backgroundColor: '#AFE1AF', width: '60px' }} Headers='strech'
                                            onChange={(e) => updatedSizingProductionDetails("strech", item, e)} />
                                    </> : item.strech
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`moisture_${index}`} value={item.moisture} onChange={(e) => updatedSizingProductionDetails("moisture", item, e)} />
                                    </>
                                    : item.moisture
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '65px' }} id={`after_waxing_${index}`} value={item.after_waxing} onChange={(e) => updatedSizingProductionDetails("after_waxing", item, e)} />
                                    </>
                                    : item.after_waxing
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '60px' }} id={`leasing_tension_${index}`} value={item.leasing_tension} maxLength='19' onChange={(e) => updatedSizingProductionDetails("leasing_tension", item, e)} />
                                    </>
                                    : item.leasing_tension
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ?
                                    <><input type="text" style={{ backgroundColor: '#AFE1AF', width: '55px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`t_1_${index}`} value={item.t_1}
                                        // disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                        onChange={(e) => updatedSizingProductionDetails("t_1", item, e)} />
                                    </>
                                    : item.t_1
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`t_2_${index}`} style={{ backgroundColor: '#AFE1AF', width: '55px' }} value={item.t_2} onChange={(e) => updatedSizingProductionDetails("t_2", item, e)} />
                                    </>
                                    : item.t_2
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" style={{ backgroundColor: '#AFE1AF', width: '55px' }} id={`t_3_${index}`} value={item.t_3} onChange={(e) => updatedSizingProductionDetails("t_3", item, e)} />
                                    </>
                                    : item.t_3
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '55px' }} id={`t_4_${index}`} value={item.t_4} onChange={(e) => updatedSizingProductionDetails("t_4", item, e)} />
                                    </>
                                    : item.t_4
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`winding_tension_${index}`} value={item.winding_tension} style={{ backgroundColor: '#AFE1AF', width: '60px' }} Headers='winding_tension'
                                            disabled={item.sizing_production_status_desc === 'Approved'} onChange={(e) => updatedSizingProductionDetails("winding_tension", item, e)} />
                                    </> : item.winding_tension
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`beam_pressing_tension_${index}`} value={item.beam_pressing_tension} onChange={(e) => updatedSizingProductionDetails("beam_pressing_tension", item, e)} disabled={item.sizing_production_status_desc === 'Approved'} />
                                    </>
                                    : item.beam_pressing_tension
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" style={{ backgroundColor: '#AFE1AF', width: '65px' }} id={`comb_breaks_${index}`} value={item.comb_breaks} onChange={(e) => updatedSizingProductionDetails("comb_breaks", item, e)} />
                                    </>
                                    : item.comb_breaks
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" style={{ backgroundColor: '#AFE1AF', width: '65px' }} id={`lappers_${index}`} value={item.lappers} onChange={(e) => updatedSizingProductionDetails("lappers", item, e)} />
                                    </>
                                    : item.lappers
                            }
                        </td>

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" style={{ backgroundColor: '#AFE1AF', width: '80px' }} id={`remark_${index}`} value={item.remark} onChange={(e) => updatedSizingProductionDetails("remark", item, e)} />
                                    </>
                                    : item.remark
                            }
                        </td> */}

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`rate_${index}`} value={item.rate} style={{ width: '75px' }} Headers='rate'
                                            disabled={item.sizing_production_status_desc === 'Approved'} onChange={(e) => updatedSizingProductionDetails("rate", item, e)} />
                                    </> : item.rate
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`amount_${index}`} value={item.amount} onChange={(e) => updatedSizingProductionDetails("amount", item, e)} disabled={item.sizing_production_status_desc === 'Approved'} />
                                    </>
                                    : item.amount
                            }
                        </td>

                        {/* <td className="erp_table_td">
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <select id={`sizing_production_status_${index}`} value={item.sizing_production_status} className="form-select form-select-sm mb-0 " Headers='sizing_production_status'
                                            disabled={isApprove ? item.sizing_production_status_desc === 'Approved' : true}
                                            onChange={(e) => { updatedSizingProductionDetails("sizing_production_status", item, e); FnValidateForm(); }}>
                                            <option value="P">Pending</option>
                                            <option value="A">Approved</option>
                                            <option value="R">Rejected</option>
                                            <option value="C">Completed</option>
                                            <option value="X">Canceled</option>
                                            <option value="I">Partial</option>
                                        </select>
                                    </>
                                    : item.sizing_production_status_desc
                            }
                        </td> */}

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" id={`status_remark_${index}`} value={item.status_remark} onChange={(e) => updatedSizingProductionDetails("status_remark", item, e)} />
                                    </>
                                    : item.status_remark
                            }
                        </td> */}
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`production_sub_section_godown_id_${index}`} value={item.godown_id || "6"} className="erp_input_field_table_txt mb-0" Headers="godown_id" onChange={(e) => { updatedSizingProductionDetails('godown_id', item, e); }} disabled
                                    >
                                        <option value="">Select</option>
                                        {godownNameOptions?.map(godownType => (
                                            <option value={godownType.field_id}>{godownType.godown_name}</option>
                                        ))}
                                    </select>
                                    : item.godown_name
                            }
                        </td>
                    </tr>
                )}

                <tr className="sticky-column">
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={keyForViewUpdate === 'view' ? 6 : 7}>Totals</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.speed}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.sizing_length}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.gross_weight}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.tare_weight}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.net_weight}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.calculative_net_weight}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.size_pickup}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.rf}</td>
                    <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.visc}</td>

                </tr>
            </tbody>
        </Table>
    }, [sizingProductionData, machineNameOption, machineOperatorOptions, beamSizingOptions]);

    const updatedSizingProductionDetails = (propertyName, currentMaterial, event, selectedOptionForMachineOperator, beamSizeArr) => {
        try {
            setIsLoading(true);
            let updatedDetailsData = [...sizingProductionData]

            let arrayIndex = 0;
            // let setNoBeamData = selectedSetNoBeamData;
            if (propertyName !== 'production_operator_id') {
                arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
            } else {
                arrayIndex = parseInt(event.inputRef.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getAttribute('rowindex'))
                let machineOperatorId = $('#production_operator_id_' + arrayIndex);
                if (selectedOptionForMachineOperator.label !== 'Select') {
                    currentMaterial[propertyName] = selectedOptionForMachineOperator.field_id;
                    machineOperatorId.removeAttr('data-tip');
                } else {
                    machineOperatorId.attr('data-tip', 'Select Machine Operator');
                }
            }

            // let warping_length = $("#warping_length").val();
            let no_of_ends = $("#no_of_ends").val();
            // let warping_no_of_creels = $("#warping_no_of_creels").val();
            let size_pickup;
            switch (propertyName) {
                case 'sizing_beam_no':
                    let matchedBeamNo = updatedDetailsData.find((warpingdata) => warpingdata.sizing_beam_no === event.target.value);

                    if (!matchedBeamNo) {
                        $("#" + event.target.id).parent().removeAttr('data-tip');
                        currentMaterial[propertyName] = event.target.value;
                    } else {
                        $("#" + event.target.id).parent().attr('data-tip', 'Plese Select another sizing beam...');
                    }
                    break;
                // case 'beam_no':
                //     currentMaterial[propertyName] = beamSizeArr;
                //     let warpingLength = 0; let warpingCreels = 0; let totalNoOfEnds = 0;
                //     beamSizeArr.forEach((index) => {
                //         let selectedBeamdata = currentMaterial['selectedSetNoBeamdata'].find((data) => {f
                //             return data['beam_no'] === index;
                //         });
                //         if (selectedBeamdata) {
                //             warpingLength += selectedBeamdata.length;
                //             totalNoOfEnds += selectedBeamdata.creel_ends;
                //             warpingCreels += selectedBeamdata.no_of_creels;
                //         }
                //     });

                //     currentMaterial['warping_length'] = warpingLength;
                //     currentMaterial['total_ends'] = totalNoOfEnds;
                //     currentMaterial['no_of_creels'] = warpingCreels;
                //     break;

                // case 'sizing_length':
                //     if (event._reactName === 'onChange') {
                //         //check value is not equal to empty or not
                //         if (event.target.value !== '' && parseInt(event.target.value) !== 0) {
                //             //checking for not to exceed warping length
                //             let totalSizingLength = updatedDetailsData.reduce((acc, data, index) => {
                //                 if (arrayIndex !== index) {
                //                     acc += data['sizing_length'];
                //                 }
                //                 return acc;  // Always return the accumulator
                //             }, 0);

                //             totalSizingLength += parseInt(event.target.value) || 0;  // Ensure it's a valid number

                //             if (parseInt(totalSizingLength) > parseInt(warping_length)) {
                //                 event.target.parentElement.dataset.tip = 'Cant exceed Warping length...!'
                //                 // currentMaterial[propertyName] = currentMaterial.warping_length
                //                 $(`#${event.target.id}`).focus();
                //                 setTimeout(function () {
                //                     delete event.target.parentElement.dataset.tip;
                //                 }, 4000);
                //             } else {
                //                 currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //                 fnCalculateWeight(currentMaterial)
                //                 delete event.target.parentElement.dataset.tip;
                //                 currentMaterial.sizing_production_status = 'P'
                //             }
                //         }
                //         // checking value if it is '' or 0 then dispplay error msg 
                //         else if (parseInt(event.target.value) === 0 || event.target.value === '') {
                //             currentMaterial[propertyName] = '';
                //             event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //             $(`#${event.target.id}`).focus();
                //         }
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;

                // case 'gross_weight':
                //     if (event._reactName === 'onChange') {
                //         if (event.target.value !== '' && parseInt(event.target.value) !== 0) {
                //             currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //             fnCalculateWeight(currentMaterial)
                //             delete event.target.parentElement.dataset.tip;
                //             currentMaterial.sizing_production_status = 'P'
                //         } else if (parseInt(event.target.value) === 0) {
                //             currentMaterial[propertyName] = '';
                //             event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //             $(`#${event.target.id}`).focus();
                //         }
                //     } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                //         event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //         $(`#${event.target.id}`).focus();
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;

                // case 'tare_weight':
                //     debugger
                //     if (event._reactName === 'onChange') {
                //         if (parseInt(event.target.value) > parseInt(currentMaterial.gross_weight)) {
                //             event.target.parentElement.dataset.tip = 'Tare Weight Cannot exceed Gross Weight...!'
                //             $(`#${event.target.id}`).focus();
                //             setTimeout(function () {
                //                 delete event.target.parentElement.dataset.tip;
                //             }, 4000);
                //         } else {
                //             currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //             fnCalculateWeight(currentMaterial)
                //             delete event.target.parentElement.dataset.tip;
                //             currentMaterial.sizing_production_status = 'P'

                //         }
                //     } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                //         event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //         $(`#${event.target.id}`).focus();
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;

                // case 'total_ends':
                //     if (event._reactName === 'onChange') {
                //         currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //         fnCalculateWeight(currentMaterial)
                //         delete event.target.parentElement.dataset.tip;
                //     } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                //         event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //         $(`#${event.target.id}`).focus();
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;


                // case 'net_weight':
                //     if (event._reactName === 'onChange') {
                //         currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //         fnCalculateWeight(currentMaterial)
                //         delete event.target.parentElement.dataset.tip;
                //     } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                //         event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //         $(`#${event.target.id}`).focus();
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;

                // case 'unsize_beam_weight':
                //     if (event._reactName === 'onChange') {
                //         currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //         fnCalculateWeight(currentMaterial)
                //         delete event.target.parentElement.dataset.tip;
                //     } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                //         event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //         $(`#${event.target.id}`).focus();
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;

                // case 'rate':
                //     if (event._reactName === 'onChange') {
                //         currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //         fnCalculateWeight(currentMaterial)
                //         currentMaterial.sizing_production_status = 'P'
                //         delete event.target.parentElement.dataset.tip;
                //     } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                //         event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                //         $(`#${event.target.id}`).focus();
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;

                // case 'sizing_production_status':
                //     if (event._reactName === 'onChange') {
                //         currentMaterial[propertyName] = event.target.value;
                //         delete event.target.parentElement.dataset.tip;
                //         if (event.target.value === "A") {
                //             currentMaterial.status_remark = '';
                //         }
                //     }
                //     break;

                case 'status_remark':
                    delete event.target.parentElement.dataset.tip;
                    currentMaterial[propertyName] = event.target.value;
                    break;

                case 'shift':
                    delete event.target.parentElement.dataset.tip;
                    if (event.target.value !== '') {

                        currentMaterial[propertyName] = event.target.value;
                    }
                    break;
                // case 'shift':
                //     currentMaterial[propertyName] = event.target.value
                //     currentMaterial.sizing_production_status = 'P'
                //     delete event.target.parentElement.dataset.tip;
                //     break;
                // case 'product_material_id':
                //     currentMaterial[propertyName] = event.target.value
                //     currentMaterial.sizing_production_status = 'P'
                //     delete event.target.parentElement.dataset.tip;
                //     break;
                case 'machine_id':
                    delete event.target.parentElement.dataset.tip;
                    currentMaterial['machine_id'] = event.target.value;
                    break;

                case 'sizing_production_date':
                    currentMaterial['sizing_production_date'] = event.target.value;
                    break;
                case 'unsize_waste':
                case 'creel_waste':
                case 'size_waste':

                // case 'production_operator_id':
                // case 'weaving_production_set_no':
                // case 'yarn_count':
                // case 'actual_count':
                // case 'size_pickup':

                //     if (event._reactName === 'onChange') {
                //         currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                //         fnCalculateWeight(currentMaterial)
                //         currentMaterial.sizing_production_status = 'P'
                //         delete event.target.parentElement.dataset.tip;
                //     } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                //         currentMaterial[propertyName] = 0;
                //         event.target.value = 0; // Set the value to 0
                //         fnCalculateWeight(currentMaterial)
                //         delete event.target.parentElement.dataset.tip;
                //     } else {
                //         currentMaterial[propertyName] = 0;
                //     }
                //     break;
                // case 'sizing_rs':
                //     if (event.target.value > 0 && event.target.value < 100) {
                //         currentMaterial[propertyName] = event.target.value
                //         delete event.target.parentElement.dataset.tip;
                //     } else {
                //         event.target.parentElement.dataset.tip = 'Enter R.S. properly....'
                //     }
                //     break;

                case "tare_weight":
                case "gross_weight":
                    $("#" + event.target.id).parent().removeAttr('data-tip');
                    currentMaterial[propertyName] = event.target.value;
                    currentMaterial['net_weight'] = currentMaterial['gross_weight'] - currentMaterial['tare_weight'];
                    size_pickup = ((parseFloat(currentMaterial['net_weight']) - currentMaterial['calculative_net_weight']) / parseFloat(currentMaterial['net_weight'])) * 100;
                    currentMaterial['size_pickup'] =
                        size_pickup === Infinity || size_pickup === -Infinity
                            ? 0
                            : validateNumberDateInput.current.decimalNumber(size_pickup, 4);

                    break;

                case "sizing_length":
                    currentMaterial[propertyName] = event.target.value;
                    $(`#${propertyName}_${arrayIndex}`).parent().removeAttr('data-tip');
                    // let beam_ends = $(`#beam_name_${arrayIndex} option:selected`).attr('beam_ends');
                    let calculative_net_weight = (parseFloat(currentMaterial['sizing_length']) * parseFloat(no_of_ends)) / (parseInt(warping_prod_constant) * parseFloat($("#production_count").val()));
                    currentMaterial['calculative_net_weight'] = validateNumberDateInput.current.decimalNumber(calculative_net_weight, 4)
                    size_pickup = ((parseFloat(currentMaterial['net_weight']) - currentMaterial['calculative_net_weight']) / parseFloat(currentMaterial['net_weight'])) * 100;
                    currentMaterial['size_pickup'] =
                        size_pickup === Infinity || size_pickup === -Infinity
                            ? 0
                            : validateNumberDateInput.current.decimalNumber(size_pickup, 4);

                    break;

                case "speed":

                case "rf":
                case "visc":
                case "creel_a_tension":
                case "creel_b_tension":
                case "sq_pres_max":
                case "sq_pres_min":
                case "saw_box_a_temp":
                case "saw_box_b_temp":
                case "strech":
                case "moisture":
                case "after_waxing":
                case "leasing_tension":
                case "t_1":
                case "t_2":
                case "t_3":
                case "t_4":
                case "winding_tension":
                case "beam_pressing_tension":
                case "comb_breaks":
                case "lappers":
                case 'remark':
                case 'rate':
                case 'godown_id':
                case 'amount':
                    $("#" + event.target.id).parent().removeAttr('data-tip');
                    currentMaterial[propertyName] = event.target.value
                    break;


                default:
                    break;
            }

            updatedDetailsData[arrayIndex] = currentMaterial;

            let totals = { ...sizingProdTotalObj };

            // Calculate totals for each key in TotalsSizingProd
            if (Object.keys(totals).includes(propertyName)) {
                updatedDetailsData.forEach((obj) => {
                    Object.keys(totals).forEach((key) => {
                        totals[key] += validateNumberDateInput.current.decimalNumber(parseFloat(obj[key]), 4) || 0; // Sum each property, default to 0 if the property is missing
                    });
                });

                // Calculate averages for 'speed' and 'breaks_per_million'
                const dataLength = updatedDetailsData.length;
                if (dataLength > 0) {
                    ['speed', 'size_pickup', 'rf', 'visc'].forEach((key) => {
                        totals[key] = validateNumberDateInput.current.decimalNumber((totals[key] / dataLength), 4); // Compute the average
                    });
                }
                setSizingProductionTotals(totals);
            }

            setsizingProductionData(updatedDetailsData);

        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    const FnValidateForm = async () => {

        let validStatus = true;
        const tableRows1 = document.querySelectorAll('#sizingProductionTbl tbody tr');
        tableRows1.forEach(row => {
            const statusRemark = row.querySelector('input[id^="status_remark_"]');
            const SizingStatus = row.querySelector(`select[id^="sizing_production_status_"]`);
            const status = statusRemark.value.trim();
            const SizingStatusValue = SizingStatus.value.trim();

            if (SizingStatusValue === "R") {
                if (status === '') {
                    statusRemark.parentElement.dataset.tip = 'Please enter the Rejection remark...!';
                    statusRemark.focus();
                    validStatus = false;
                    return;
                } else {
                    delete statusRemark.parentElement.dataset.tip;
                }
            } else {
                delete statusRemark.parentElement.dataset.tip;
            }

        });
        return validStatus;
    };

    const fnCalculateWeight = (currentMaterial) => {
        // debugger

        let grossWeight = parseFloat(currentMaterial.gross_weight)
        let tareWeight = parseFloat(currentMaterial.tare_weight)

        let sizingLength = parseFloat(currentMaterial.sizing_length);
        // let totalNoOfEnds = parseFloat(currentMaterial.total_ends);
        let totalNoOfEnds = parseInt($("#warping_creel_ends").val());
        // let actualCount = parseFloat(currentMaterial.actual_count);
        let actualCount = parseFloat($("#production_count").val());
        let newNetWeight = 0;
        newNetWeight = parseFloat(currentMaterial.net_weight);
        // let unsizeBeamWeight = parseFloat(currentMaterial.unsize_beam_weight);
        let rate = parseFloat(currentMaterial.rate);
        let calculateUnsizeBeamWeight = 0

        //to calculate net weight
        if (grossWeight != '' || tareWeight != '') {
            newNetWeight = grossWeight - tareWeight
            currentMaterial['net_weight'] = validateNumberDateInput.current.decimalNumber(newNetWeight.toString(), 4);
        } else {
            currentMaterial['net_weight'] = 0;
        }

        //to calcualte unsize beam weight 

        if (sizingLength !== '' && totalNoOfEnds !== '' && actualCount !== '') {
            sizingLength = parseFloat(sizingLength);
            totalNoOfEnds = parseFloat(totalNoOfEnds);
            actualCount = parseFloat(actualCount);

            if (!isNaN(sizingLength) && !isNaN(totalNoOfEnds) && !isNaN(actualCount) && actualCount !== 0) {
                let calculateDenominator = 1693 * actualCount;
                let calculateNumerator = sizingLength * totalNoOfEnds;
                calculateUnsizeBeamWeight = calculateNumerator / calculateDenominator;
                currentMaterial['unsize_beam_weight'] = validateNumberDateInput.current.decimalNumber(calculateUnsizeBeamWeight.toString(), 4);
            } else {
                currentMaterial['unsize_beam_weight'] = 0;
            }
        } else {
            currentMaterial['unsize_beam_weight'] = 0;
        }
        //calculte  SIZE PICK-UP %
        // ((Net Weight - Unsize Beam weight)/Unsize Beam weight)*100
        if (newNetWeight !== '' && calculateUnsizeBeamWeight !== '') {
            if (!isNaN(newNetWeight) && !isNaN(calculateUnsizeBeamWeight) && calculateUnsizeBeamWeight !== 0) {
                // let calculatedDifference = Difference=Net Weight−Unsize Beam Weight
                let calculatedDifference = newNetWeight - calculateUnsizeBeamWeight
                let calculateratio = (calculatedDifference / calculateUnsizeBeamWeight);
                let calculateSizePickUp = calculateratio * 100;
                currentMaterial['size_pickup'] = validateNumberDateInput.current.decimalNumber(calculateSizePickUp.toString(), 4);
            } else {
                currentMaterial['size_pickup'] = 0;
            }
        } else {
            currentMaterial['size_pickup'] = 0;
        }
        // calculate Amount 
        if (rate !== '' && calculateUnsizeBeamWeight !== '') {
            if (!isNaN(rate) && !isNaN(calculateUnsizeBeamWeight) && calculateUnsizeBeamWeight !== 0) {
                let calculateAmt = rate * calculateUnsizeBeamWeight
                currentMaterial['amount'] = validateNumberDateInput.current.decimalNumber(calculateAmt.toString(), 4);
            } else {
                currentMaterial['amount'] = 0;
            }
        } else {
            currentMaterial['amount'] = 0;
        }

        return currentMaterial;
    }


    //Material Moment//
    const SizingrenderMaterialMomentDetails = useMemo(() => {


        return <Table id='sizinigProductionTbl' className={`erp_table ${sizingProductionMaterialData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
                    <th className="erp_table_th">Sr no .</th>
                    <th className="erp_table_th">Date</th>
                    <th className="erp_table_th">Shift</th>
                    {/* <th className="erp_table_th">Set no</th>
                <th className="erp_table_th">Material Name</th> */}
                    <th className="erp_table_th">Material Unit</th>
                    <th className="erp_table_th">Quantity</th>
                    <th className="erp_table_th">Balance Quantity</th>
                    {/* changes by tushar */}
                    <th className="erp_table_th">Stock Qty</th>
                    <th className="erp_table_th">Consumption Quantity</th>
                    <th className="erp_table_th">Material Status</th>
                    {/* <th className="erp_table_th">Status Remark</th> */}
                </tr>
            </thead>
            <tbody>
                {sizingProductionMaterialData.map((item, index) =>

                    <tr rowindex={index} className="sticky-column">
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(index)} />
                        </td>

                        <td className='erp_table_td'>
                            {index + 1}
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <>
                                        <input
                                            type="date"
                                            className="erp_input_field mb-0"
                                            disabled={item.material_status_desc === 'Approved'}
                                            Headers="sizing_production_date"
                                            id={`sizing_production_date_${index}`}
                                            value={item.sizing_production_date}
                                        // onChange={(e) => handleSetNoChange(item, e, index)}
                                        />
                                    </>
                                    : item.sizing_production_date
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <select id={`shift_${index}`} value={item.shift} className="erp_input_field_table_txt mb-0"
                                        Headers="shift"
                                        disabled={(keyForViewUpdate === 'update' || keyForViewUpdate === 'approve') && (item.material_status_desc === 'Approved' || item.product_material_unit_name) ? true : false}
                                    // onChange={(e) => handleSetNoChange(item, e, index)}
                                    >
                                        <option value="">Select</option>
                                        {shiftOptions?.map(shift => (
                                            <option value={shift.field_name}>{shift.field_name}</option>
                                        ))}
                                    </select>
                                    : item.shift
                            }
                        </td>

                        {/* <td className={`erp_table_td `}>
                        {
                            keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                ? <select id={`weaving_production_set_no_${index}`} value={item.weaving_production_set_no} className="erp_input_field_table_txt mb-0"
                                    Headers="weaving_production_set_no"
                                    disabled={item.material_status_desc === 'Approved'}
                                    onChange={(e) => { handleSetNoChange(item, e, index); }}
                                >
                                    <option value="">Select</option>
                                    {setNoOptions?.map(setNO => (
                                        <option value={setNO.set_no}>{setNO.set_no}</option>
                                    ))}

                                </select>
                                : item.weaving_production_set_no
                        }
                    </td>

                    <td className="erp_table_td text-end">
                        <select className="form-select form-select-sm mb-0" id={`product_material_id_${index}`} value={item.product_material_id} disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                            onChange={(e) => { handleSetNoChange(item, e, index); }}
                            Headers='product_material_id' >
                            <option value="">Select</option>
                        </select>

                    </td> */}

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`product_material_unit_name_${index}`}>{item.product_material_unit_name}</span>
                                    : item.product_material_unit_name
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`product_material_quantity_${index}`}>{item.product_material_quantity}</span>
                                    : item.product_material_quantity
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <input className="erp_input_field_table_txt mb-0" id={`product_material_sz_balance_quantity_${index}`}
                                        Headers="product_material_sz_balance_quantity"
                                        value={item.product_material_sz_balance_quantity}
                                    />
                                    : item.product_material_sz_balance_quantity
                            }
                        </td>

                        <td className={`erp_table_td`}>{item?.stock_quantity ? item.stock_quantity : 0}</td>

                        {/* <td className='erp_table_td'>
                        {
                            keyForViewUpdate !== 'view'
                                ? <div className='d-flex' style={{ alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        className="erp_input_field_table_txt remove0 mb-0 ms-1"
                                        id={`consumption_quantity_${index}`}
                                        value={item.consumption_quantity}
                                        Headers="consumption_quantity"
                                        style={{ backgroundColor: '#AFE1AF' }}
                                        onChange={(e) => { FnUpdateInspMaterialMovementRecords(item, e, index); }}
                                        disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                        maxLength='19'
                                    />
                                    <FaDatabase onClick={() => FnMaterialConsumption(item, index)} />
                                </div>
                                : item.consumption_quantity
                        }
                    </td> */}

                        <td className='erp_table_td'>
                            {keyForViewUpdate !== 'view'
                                ? <>
                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                        <input
                                            type="text"
                                            className="erp_input_field_table_txt remove0 mb-0 ms-1 text_end"
                                            id={`consumption_quantity_${index}`}
                                            value={item.consumption_quantity}
                                            Headers="consumption_quantity"
                                            style={{ backgroundColor: '#AFE1AF' }}
                                            // onChange={(e) => { handleSetNoChange(item, e, index); }}
                                            disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            maxLength='19' />
                                        {/* <FaDatabase onClick={() => FnMaterialConsumption(item, index)} /> */}

                                    </div>
                                </>
                                : item.consumption_quantity}
                        </td>

                        <td className="erp_table_td">
                            <select id={`material_status${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.material_status}
                                disabled={isApprove ? item.material_status_desc === 'Approved' : true}
                                // onChange={(e) => { handleSetNoChange(item, e, index); FnValidateForm(); }}
                                Headers='material_status'>
                                <option value="P">Pending</option>
                                <option value="A">Approved</option>
                                <option value="R">Rejected</option>
                                <option value="I">Partial Issue</option>
                                <option value="C">Completed</option>
                                <option value="X">Canceled</option>
                                <option value="Z">PreeClosed</option>
                            </select>
                        </td>

                        {/* <td className='erp_table_td'>
                            {keyForViewUpdate === 'approve' ? (
                                <input
                                    type="text" id={`status_remark_${index}`}
                                    disabled={!isApprove}
                                    className="erp_input_field_table_txt mb-0" value={item.status_remark}
                                // Headers='status_remark'
                                // onChange={(e) => { handleSetNoChange(item, e, index); }}
                                />
                            ) : (
                                item.status_remark
                            )}
                        </td> */}
                    </tr>
                )}
            </tbody>
        </Table>
    }, [sizingProductionMaterialData])


    // ------------------------------------------save---------------------------------------------------------------

    const addSizingProduction = async () => {

        try {
            setIsLoading(true)
            let isCalculationValid = false;
            if (keyForViewUpdate === 'approve') {
                isCalculationValid = await FnValidateForm()
            }

            if (cmb_prod_supervisor_id === '' || cmb_prod_supervisor_id === '0') {
                $("#error_cmb_prod_supervisor_id").text("Please fill this field...!");
                $("#error_cmb_prod_supervisor_id").show();
                $("#cmb_prod_supervisor_id").focus();
                return false;
            }

            // let checkValidProdDetails = false;
            // if (keyForViewUpdate !== 'approve')
            //     checkValidProdDetails = await validateProdDetails()

            // let checkValidMaterialDetails = false;
            // if (keyForViewUpdate !== 'approve')
            //     checkValidMaterialDetails = await validateMaterialDetails()


            // if ((checkValidMaterialDetails === true || keyForViewUpdate === 'approve') && (checkValidProdDetails === true || keyForViewUpdate === 'approve') && (keyForViewUpdate !== "approve" || isCalculationValid === true)) {
            let lastestStatus = txt_Production_Sizing_Status;
            // if (keyForViewUpdate === 'approve') {
            //     const allApprovedWeaving = sizingProductionData.every(item => item.sizing_production_status === 'A');
            //     const allApprovedMaterial = sizingProductionMaterialData.every(item => item.material_status === 'A');
            //     const anyApproved = sizingProductionData.some(item => item.sizing_production_status === 'A') || sizingProductionMaterialData.some(item => item.material_status === 'A');
            //     const allPending = sizingProductionData.every(item => item.sizing_production_status === 'P') && sizingProductionMaterialData.every(item => item.material_status === 'P');
            //     const anyRejected = sizingProductionData.some(item => item.sizing_production_status === 'R') || sizingProductionMaterialData.some(item => item.material_status === 'R');

            //     if (anyRejected) {
            //         lastestStatus = 'R';
            //     } else if (allApprovedWeaving && allApprovedMaterial) {
            //         lastestStatus = 'A';
            //     } else if (anyApproved) {
            //         lastestStatus = 'I';
            //     } else if (allPending) {
            //         lastestStatus = 'P';
            //     } else {
            //         lastestStatus('p');
            //     }
            // }

            const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransSZStoppageData': [], 'TransSZMaterialData': [], 'TransSZWastageData': [], 'commonIds': {}, 'TransStockDetailData': [] }
            //master data   'TransStockDetailData': [],
            const sizingProductionMasterData = {
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                weaving_production_sizing_master_id: txt_weaving_production_sizing_master_id,
                sizing_production_code: txt_sizing_production_code,
                sizing_production_date: dt_sizing_production_date,
                customer_order_no: $("#cmb_set_no option:selected").attr("customer_order_no"),
                set_no: $("#cmb_set_no option:selected").val(),
                prod_month: txt_production_month,
                prod_year: txt_production_year,
                plant_id: cmb_plant_id,
                section_id: cmb_section_id,
                sub_section_id: cmb_sub_section_id,
                production_supervisor_id: cmb_prod_supervisor_id,
                is_active: chk_isactive,
                created_by: UserName,
                modified_by: txt_weaving_production_sizing_master_id !== 0 ? UserName : null,
                sizing_production_master_status: lastestStatus,

            }
            json.TransHeaderData = sizingProductionMasterData;
            console.log("sizingProductionMasterData", sizingProductionMasterData);
            //Details data
            for (let index = 0; index < sizingProductionData.length; index++) {
                const element = sizingProductionData[index];

                if (element.weaving_production_sizing_details_id === 0) {
                    const addSizingProductionData = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        financial_year: FINANCIAL_SHORT_NAME,
                        weaving_production_sizing_details_id: element.weaving_production_sizing_details_id,
                        sizing_production_order_no: element.sizing_production_order_no,
                        sizing_production_status: "A",
                        sizing_production_date: element.sizing_production_date,
                        prod_month: txt_production_month,
                        prod_year: txt_production_year,
                        shift: element.shift,
                        weaving_production_set_no: $("#cmb_set_no option:selected").val(),
                        customer_order_no: $("#cmb_set_no option:selected").attr("customer_order_no"),
                        // product_material_id: element.product_material_id, godown_id
                        // beam_no: JSON.stringify(element.beam_no),
                        sizing_beam_no: element.sizing_beam_no,
                        actual_count: element.actual_count,
                        total_ends: no_of_ends,
                        warping_length: warping_length,
                        sizing_length: element.sizing_length,
                        net_weight: element.net_weight,
                        gross_weight: element.gross_weight,
                        tare_weight: element.tare_weight,
                        unsize_beam_weight: element.unsize_beam_weight,
                        size_pickup: element.size_pickup,
                        sizing_rs: element.sizing_rs,
                        rate: element.rate,
                        amount: element.amount,
                        plant_id: cmb_plant_id,
                        yarn_count: element.yarn_count,
                        production_operator_id: element.production_operator_id,
                        production_supervisor_id: cmb_prod_supervisor_id,
                        section_id: cmb_section_id,
                        sub_section_id: cmb_sub_section_id,
                        machine_id: element.machine_id,
                        sizing_production_code: txt_sizing_production_code,
                        speed: element.speed,
                        calculative_net_weight: element.calculative_net_weight,
                        rf: element.rf,
                        visc: element.visc,
                        creel_a_tension: element.creel_a_tension,
                        creel_b_tension: element.creel_b_tension,
                        sq_pres_max: element.sq_pres_max,
                        sq_pres_min: element.sq_pres_min,
                        saw_box_a_temp: element.saw_box_a_temp,
                        saw_box_b_temp: element.saw_box_b_temp,
                        strech: element.strech,
                        moisture: element.moisture,
                        after_waxing: element.after_waxing,
                        leasing_tension: element.leasing_tension,
                        t_1: element.t_1,
                        t_2: element.t_2,
                        t_3: element.t_3,
                        t_4: element.t_4,
                        winding_tension: element.winding_tension,
                        beam_pressing_tension: element.beam_pressing_tension,
                        lappers: element.lappers,
                        comb_breaks: element.comb_breaks,
                        size_waste: element.size_waste,
                        unsize_waste: element.unsize_waste,
                        creel_waste: element.creel_waste,
                        godown_id: parseInt(element.godown_id),
                        is_active: chk_isactive,
                        created_by: UserName,
                        modified_by: element.weaving_production_sizing_details_id !== 0 ? UserName : null,
                        status_remark: '',
                        no_of_creels: $("#warping_no_of_creels").val()
                    }
                    json.TransDetailData.push(addSizingProductionData)
                }

            }
            for (let index = 0; index < sizingProductionData.length; index++) {
                const element = sizingProductionData[index];

                if (element.weaving_production_sizing_details_id === 0) {
                    const addSizingProductionStockData = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        financial_year: FINANCIAL_SHORT_NAME,
                        sizing_stock_details_id: element.sizing_stock_details_id ?? 0,
                        weaving_production_sizing_details_id: element.weaving_production_sizing_details_id,
                        sizing_production_date: element.sizing_production_date,
                        set_no: $("#cmb_set_no option:selected").val(),
                        customer_order_no: $("#cmb_set_no option:selected").attr("customer_order_no"),
                        job_type: job_type,
                        product_material_id: productMaterialId,
                        beam_no: element.sizing_beam_no,
                        total_ends: no_of_ends,
                        sizing_length: element.sizing_length,
                        net_weight: element.net_weight,
                        gross_weight: element.gross_weight,
                        tare_weight: element.tare_weight,
                        rate: element.rate,
                        amount: element.amount,
                        customer_id: customer_id,
                        section_id: cmb_section_id,
                        sub_section_id: cmb_sub_section_id,
                        sizing_production_code: txt_sizing_production_code,
                        godown_id: parseInt(element.godown_id),
                        is_active: chk_isactive,
                        created_by: UserName,
                        modified_by: element.sizing_stock_details_id !== 0 ? UserName : null,
                        sized_beam_status: "A"
                    }
                    json.TransStockDetailData.push(addSizingProductionStockData)
                }
            }
            //material movement data
            if (sizingProductionMaterialData.length > 0) {
                let lastIndexOfMaterialMovement = sizingProductionMaterialData.length - 1
                if (sizingProductionMaterialData[lastIndexOfMaterialMovement].weaving_production_set_no !== 0) {
                    for (let index = 0; index < sizingProductionMaterialData.length; index++) {
                        const materialDataElement = sizingProductionMaterialData[index];
                        const materialMovementData = {
                            company_branch_id: COMPANY_BRANCH_ID,
                            company_id: COMPANY_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            plant_id: cmb_plant_id,
                            section_id: cmb_section_id,
                            sub_section_id: cmb_sub_section_id,
                            weaving_production_sizing_material_id: materialDataElement.weaving_production_sizing_material_id === undefined || materialDataElement.weaving_production_sizing_material_id === null ? 0 : materialDataElement.weaving_production_sizing_material_id,
                            weaving_production_sizing_details_id: 0,
                            weaving_production_sizing_master_id: 0,
                            sizing_production_date: materialDataElement.sizing_production_date,
                            sizing_production_code: txt_sizing_production_code,
                            sizing_production_order_no: materialDataElement.sizing_production_order_no,
                            prod_month: txt_production_month,
                            prod_year: txt_production_year,
                            shift: materialDataElement.shift,
                            weaving_production_set_no: $("#cmb_set_no option:selected").val(),
                            product_material_id: materialDataElement.product_material_id,
                            product_material_unit_id: materialDataElement.product_material_unit_id,
                            product_material_quantity: materialDataElement.product_material_quantity,
                            consumption_quantity: materialDataElement.consumption_quantity,
                            material_status: materialDataElement.material_status,
                            material_status_remark: materialDataElement.material_status_remark,
                            is_active: chk_isactive,
                            created_by: UserName,
                            modified_by: txt_weaving_production_sizing_master_id === 0 ? null : UserName,
                        };
                        json.TransSZMaterialData.push(materialMovementData)
                    }
                }
            }


            json.commonIds.company_id = COMPANY_ID
            json.commonIds.weaving_production_sizing_master_id = WeavingProductionSizingMasterId
            // json.commonIds.beam_status = "C"
            // json.commonIds.UserName = UserName;
            console.log('sizing production Details', json);

            const formData = new FormData()
            formData.append('WeavingProductionSizingData', JSON.stringify(json))

            const forwardData = {
                method: 'POST',
                body: formData,
            }
            const sizingProductionApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnAddUpdateRecord`, forwardData)
            const response = await sizingProductionApiCall.json();

            if (response.success === '0') {
                setErrMsg(response.error)
                setShowErrorMsgModal(true)
            } else {
                setSuccMsg(response.message)
                setShowSuccessMsgModal(true)
            }
            // }
        } catch (error) {
            console.log("error", error);
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }

    // ---------------------------------fn check update ends--------------------------------------------------------------------


    // ---------------------------------fn check update ends--------------------------------------------------------------------
    // generate autono
    const generateSizingProductionCode = async () => {
        // debugger
        const productionDate = $('#dt_sizing_production_date').val();
        const [year, month, day] = productionDate.split('-');
        const formatted_date = `${day}${month}${year.slice(2)}`;
        const subSectionVal = $('#cmb_sub_section_id').val();
        const shortName = $('#cmb_sub_section_id option:selected').attr('shortName');
        // comboOnChange('subSectionOnChange')
        const SizingProductionCode = await generateAutoNoAPiCall.current.generateProductionCode({
            "entity_name": "xt_weaving_production_sizing_master",
            "sub_section": subSectionVal,
            "sub_section_short_name": shortName,
            "field_name": "sizing_production_code",
            "transaction_date": formatted_date,
            "trans_no_length": "4",
            "company_id": COMPANY_ID,
        });
        console.log(' Sizing Production : ', SizingProductionCode);
        setSizingProductionCode(SizingProductionCode)
    }


    // const fnManageSizingStatus = (sizingProductionData, inspectionMaterialData) => {
    //     // debugger
    //     const hasRejected = sizingProductionData.some(item => item.sizing_production_status === 'R') || sizingProductionMaterialData.some(item => item.material_status === 'R');
    //     const hasPending = sizingProductionData.some(item => item.sizing_production_status === 'P') || sizingProductionMaterialData.some(item => item.material_status === 'P');
    //     const hasApprove = sizingProductionData.every(item => item.sizing_production_status === 'A') && sizingProductionMaterialData.every(item => item.material_status === 'A');
    //     if (hasRejected) {
    //         setProductionSizingStatus('R');
    //         setActionLabel('Rejected');
    //     } else if (hasPending) {
    //         setProductionSizingStatus('P');
    //         if (keyForViewUpdate === 'Add') {
    //             setActionLabel('Rejected');
    //         } else {
    //             if (keyForViewUpdate === 'update') {
    //                 setActionLabel('Update');
    //             } else {
    //                 setActionLabel('Approved');
    //             }
    //         }
    //     } else if (hasApprove) {
    //         setProductionSizingStatus('A');
    //         setActionLabel('Approved');
    //     } else {
    //         setProductionSizingStatus('p');
    //     }
    // };
    //   --------------------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <GenerateMaterialId ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <DashboardLayout>


                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Sizing Production {actionType} </label>
                    </div>

                    <form id='Sizingproduction'>
                        <div className='row p-1'>
                            {/* 1 st Column */}
                            <div className='col-sm-6 erp_form_col_div'>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Set No <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_set_no" className="form-select form-select-sm" value={cmb_set_no} onChange={(e) => { comboOnChange('cmb_set_no'); }}>
                                            <option >Select</option>
                                            {setNoOptions.length !== 0 ? (
                                                <>
                                                    {setNoOptions?.map(production => (
                                                        <option value={production.set_no} customer_order_no={production.customer_order_no}>{production.set_no}</option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Production Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_sizing_production_date' className="erp_input_field" value={dt_sizing_production_date} onChange={(e) => { setMonthYear(); validateFields(); generateSizingProductionCode(); }} disabled={keyForViewUpdate !== ''} />
                                        <MDTypography variant="button" id="error_dt_sizing_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFields(); }}>
                                            <option value="" disabled>Select</option>
                                            {plantOptions.length !== 0 ? (
                                                <>{plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.field_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Prod. supervisor <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select ref={cmb_prod_supervisor_id_ref}
                                            options={machineOperatorOptions}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            inputId="cmb_prod_supervisor_id" // Provide the ID for the input box
                                            value={machineOperatorOptions.length > 1 ? machineOperatorOptions.find(option => option.value === cmb_prod_supervisor_id) : null}
                                            onChange={(selectedOpt) => {
                                                setProdSupervisor(selectedOpt.value);
                                                cmb_prod_supervisor_id_ref.current = selectedOpt;
                                            }}
                                            placeholder="Search Supervisor here..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_prod_supervisor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">No Of Ends<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" className="erp_input_field" id="no_of_ends" value={no_of_ends} disabled />
                                    </div>
                                </div>



                                <div className='row'>
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Production Count<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" className="erp_input_field" id="production_count" value={production_count} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Beam No's <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_beam_nos" className="form-select form-select-sm" >
                                            {beamWarpingOptions.length !== 0 ? (
                                                <>
                                                    {beamWarpingOptions?.map(beamNo => (
                                                        <option >{beamNo.beam_name}</option>
                                                    ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label"> Warping Creels Ends <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" id='warping_creel_ends' className="erp_input_field" value={warping_creel_ends} disabled />
                                    </div>
                                </div>



                            </div>

                            <div className='col-sm-6 erp_form_col_div'>

                                <div className='row'>
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Month & Year <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6 d-flex">
                                        <Form.Control type="text" id='txt_production_month' className="erp_input_field" value={txt_production_month} disabled />
                                        <Form.Control type="text" id='txt_production_year' className="erp_input_field ms-1" value={txt_production_year} disabled />

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Prod. Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); comboOnChange('cmb_section_id'); validateFields(); }} disabled>
                                            <option value="">Select</option>
                                            {prodsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsectionOptions?.map(production => (
                                                        <option value={production.field_id}>{production.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Prod. Sub Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { validateFields(); comboOnChange('subSectionOnChange'); }} disabled>
                                            <option value="">Select</option>
                                            {prodsubsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsubsectionOptions?.map(subproduction => (
                                                        <option key={subproduction.field_id} shortName={subproduction.production_sub_section_short_type} value={subproduction.field_id}>
                                                            {subproduction.field_name}
                                                        </option>
                                                    ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>




                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label"> Sizing Prod. Code <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" id='txt_sizing_production_code' className="erp_input_field" value={txt_sizing_production_code} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Product Material <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" id="txt_prod_material_name" className="erp_input_field" value={txt_prod_material_name} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Set Length <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control id="txt_set_length" type="text" className="erp_input_field" value={txt_set_length} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Warping Length <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control id="warping_length" type="text" className="erp_input_field" value={warping_length} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Warping Creels <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control id="warping_no_of_creels" type="text" className="erp_input_field" value={warping_no_of_creels} disabled />
                                    </div>
                                </div>

                            </div>

                            {/* ..............2nd Column.......................... */}
                            {/* <div className="col-sm-5 px-lg-2 d-block">
                                <div className="col-lg-12 col-12 erp_form_col_div">
                                    <div className="card">
                                        <div className="card-header py-0 main_heding mb-0">
                                            <label className="erp-form-label-md-lg">Sizing Production Summary</label>
                                        </div>
                                        
                                        <div className="card-body p-0">
                                            <div className={`table-responsive`}>
                                                {renderproductionsummary}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> */}


                        </div>
                    </form>
                    <hr />


                    <Accordion defaultActiveKey='1'>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="erp-form-label-md">Sizing Production Details</Accordion.Header>
                            <Accordion.Body>
                                <div className='row mt-1'><label className="erp-form-label-md-lg">Production Details</label></div>
                                {SizingrenderProductionDetails}
                                {/* <hr />
                                <div className='row mt-2'><label className="erp-form-label-md-lg">Material Moment</label></div>
                                {SizingrenderMaterialMomentDetails} */}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <hr />

                    <div className="erp_frm_Btns mb-5">
                        <MDButton type="button" className="erp-gb-button erp_MLeft_btn ms-4" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                        <MDButton type="submit" onClick={addSizingProduction} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </DashboardLayout>
        </>
    )
}

export default FrmTSizingProductionEntry
