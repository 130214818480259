
import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png';

const DirectWarpingPlan = React.forwardRef(({ warpingProdPlanOrderContent }, ref) => {
    debugger
    let companyDetails = warpingProdPlanOrderContent.company;
    let warpingTransData = warpingProdPlanOrderContent.warpingTransData;
    let warpingDetailsData = warpingProdPlanOrderContent.warpingDetailsData;
    let warpingMaterialsPropertiesData = warpingProdPlanOrderContent.warpingMaterialsPropertiesData;
    let warpingMaterialsPropertiesColumns = warpingProdPlanOrderContent.warpingMaterialsPropertiesColumns;
    const totalEndsLabel = warpingMaterialsPropertiesColumns.includes("No. Of Ends")
        ? (warpingMaterialsPropertiesData.length > 0
            ? warpingMaterialsPropertiesData[0]["No. Of Ends"]
            : 'N/A')
        : '';

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-1 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="165" height="auto" mt={1} />
                    </div>
                    <div className="col-sm-11 transformed mt-1">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {companyDetails.company_name}
                        </div>
                        <div className='erp-invoice-print-label-lg text-center'>
                            Direct Warping Plan
                        </div>
                    </div>
                </div>

                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}> Order No : {warpingTransData.orderNo} </dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg ' style={{ fontSize: '13px' }}>Total Ends :{warpingTransData.txt_t_ends}</dt>
                    </div>
                    {/* <div className='col-sm-3'>
                    {warpingMaterialsPropertiesColumns.includes("No. Of Ends") && (
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}>
                            Total Ends: {warpingMaterialsPropertiesData[0]["No. Of Ends"]}
                        </dt>
                    )}
                    </div> */}
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}>Length(MTRS) : {warpingTransData.setLength} </dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}>Speed(MPM) : </dt>
                    </div>
                </div>
                <div className='row   p-0 m-0'>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}> Count(NE) :  </dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}>Lot No :</dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}>Supplier : </dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}>Beam Pressing : </dt>
                    </div>
                </div>
                <div className='row   p-0 m-0'>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}> Date : {warpingTransData.date} </dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '13px' }}>Set No :{warpingTransData.setNo}</dt>
                    </div>

                </div>
            </div>
        </>
    )
    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>
                <div className='col-sm-6'>
                    {/* <div className='text-center'>
                <dt className="erp-invoice-print-label-md-lg" style={{fontSize:"10px"}}>For {invoiceContent.customerDetails.customer}</dt>
              </div> */}
                    <div className='align-bottom-center mt-5'>
                        <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>SUPERVISOR</dt>
                    </div>
                </div>
                <div className='col-sm-6'>
                    {/* <div className='text-center'>
                <dt className="erp-invoice-print-label-md-lg" style={{fontSize:"10px"}}>For {itemData.company}</dt>
              </div> */}
                    <div className='align-bottom-center mt-5'>
                        <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>HOD</dt>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <>
            <style>
                {`
                        @media print {
                        @page {
                            margin: 1 px; /* Removes any additional margin */
                            size: landscape;
                        }

                        body {
                            margin: 1 px; /* Adds a 1px margin around the body */
                            padding: 0; /* Removes any padding */
                        }

                        .print-content {
                            padding: 0; /* Ensure no padding within the content */
                            box-sizing: border-box;
                        }
                    }
                        `}
            </style>

            <div className=''>
                <div className="row">
                    <div className="col-12">
                        <div className="container-invoice" style={{ maxWidth: "980px" }}>
                            <div id="content">
                                <div className="invoice p-0">
                                    <div className="row headerContentHt">
                                        {invoiceHeader}
                                    </div>
                                    <div className="row">
                                        <div className="col-12 px-0">
                                            <div className="row p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0">

                                                        <thead className='tblHeaderContentHt'>
                                                            <tr>
                                                                <th className="erp_invoice_table_th ">SR NO</th>
                                                                <th className="erp_invoice_table_th">OPERATOR</th>
                                                                <th className="erp_invoice_table_th ">DATE/ SHIFT</th>
                                                                <th className="erp_invoice_table_th ">BEAM NO</th>
                                                                <th className="erp_invoice_table_th ">ENDS/ BEAM</th>
                                                                <th className="erp_invoice_table_th ">CROSS WT</th>
                                                                <th className="erp_invoice_table_th ">TARE. WT</th>
                                                                <th className="erp_invoice_table_th ">NET. WT</th>
                                                                <th className="erp_invoice_table_th ">WILD YARN</th>
                                                                <th className="erp_invoice_table_th ">EXTRA YARN</th>
                                                                <th className="erp_invoice_table_th ">FOREIGN MATTER</th>
                                                                <th className="erp_invoice_table_th ">WEAK PLACE</th>
                                                                <th className="erp_invoice_table_th ">WEAK SPLICE</th>
                                                                <th className="erp_invoice_table_th ">CUTS ON CONE</th>
                                                                <th className="erp_invoice_table_th ">DAMG. CONE</th>
                                                                <th className="erp_invoice_table_th ">SORT ENDS</th>
                                                                <th className="erp_invoice_table_th ">SLUB</th>
                                                                <th className="erp_invoice_table_th ">EMPTY CONES</th>
                                                                <th className="erp_invoice_table_th ">TOTAL BREAKS</th>
                                                                <th className="erp_invoice_table_th">BREAKS/ MILLION</th>
                                                                <th className="erp_invoice_table_th">REMARK</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id='warpingdetailstableId'>
                                                            {Array.from({ length: 19 }, (_, rowIndex) => (
                                                                <tr key={rowIndex} className={`tblBodyTrHt-${rowIndex}`}>

                                                                    {Array.from({ length: 21 }, (_, colIndex) => (
                                                                        <td key={colIndex} className="erp_invoice_table_td text-center" style={{ height: "25px" }}>

                                                                        </td>
                                                                    ))}
                                                                </tr>

                                                            ))}
                                                            <tr>
                                                                <td colSpan={25}>
                                                                    {/* Your invoice footer content */}
                                                                    {invoiceFooter}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default DirectWarpingPlan;

