import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';
import { MdDelete } from "react-icons/md";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Table, Accordion } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import Tooltip from "@mui/material/Tooltip";


// Import for the searchable combo box.
import Select from 'react-select';

export default function FrmGoodsReturnEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, UserId } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate, goodsReturnMasterId, compType } = state || {};
    const [action_Label, setActionLabel] = useState('(Creation)');
    const [isLoading, setIsLoading] = useState(false);
    const [showAddUpdateBtn, setShowAddUpdateBtn] = useState(false)

    //For navigate
    const navigate = useNavigate();

    //UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();
    const issueNoComboRef = useRef();
    const materialsComboRef = useRef();
    const lotNoComboRef = useRef();
    //Data states
    const [GrnDetails, setGrnDetails] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [materialtypeOptions, setmaterialtypeOptions] = useState([])
    const [cmb_goods_return_status, setGoodReturnStatus] = useState("P");
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [cmb_approved_by_id, setApprovedById] = useState(UserId);
    const [dt_approved_date, setApproveDate] = useState("");


    //combo states & options
    const [jobTypeOptions, setJobTypeArr] = useState([]);
    const [cmb_sales_job_type, setSalesOrderJob] = useState("");
    const [cmb_supplier_id, setSupplier_id] = useState("");
    const [grnOptions, setGRNOptions] = useState([]);
    const [cmb_grn_no, setGRNno] = useState("");
    const [cmb_lot_no, setLotNo] = useState('');
    const [lotNoOptions, setLotNoOptions] = useState([])
    const [cmb_material_type, setmaterialType] = useState('');
    const [materialOptions, setMaterialOptions] = useState([])

    //Form Fields
    const [txt_goods_return_no, setGoodsReturnNo] = useState(0);
    const [txt_goods_return_weight, setGoodsReturnWeight] = useState(0);
    const [txt_goods_return_quantity, setGoodsReturnQuantity] = useState('');
    const [txt_goods_return_boxes, setGoodsReturnBoxes] = useState('');
    const [txt_material_rate, setMaterialRate] = useState('');
    const [txt_goods_return_version, setGoodsReturnVersion] = useState(1);
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);

    // Function to handle input changes and update state variables
    const validateMaterialDetails = (event) => {
        const numCheck = /^\d{0,18}(?:\.\d{0,4})?$/;
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === null || !numCheck.test(event.target.value)) {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid input...!';
            return false;
        } else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }

    const clearAllDataTipsForMaterialRate = () => {
        const materialRateInputs = document.querySelectorAll(
            '.erp_table_td input[Headers="goods_return_rate"]'
        );
        materialRateInputs.forEach((input) => {
            delete input.parentElement.dataset.tip;
        });
    };

    const FnOnChangeTblRow = async (currentRowData, event, arrayIndex, param) => {
        debugger
        let clickedColName;
        let issueItemsQtyinp;

        clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
        issueItemsQtyinp = document.querySelector('#' + event.target.id);

        switch (clickedColName) {
            case 'goods_return_quantity':
                if (event.target.value !== '') {
                    if (validateMaterialDetails(event)) {
                        if (event.target.value > currentRowData['product_material_grn_accepted_quantity'] || event.target.value > currentRowData['closing_balance_quantity']) {
                            issueItemsQtyinp.parentElement.dataset.tip = 'Return quantity cant exceed Stock quantity...!';
                            currentRowData[clickedColName] = '';
                        } else {
                            currentRowData[clickedColName] = event.target.value;
                            let wtPerBox = currentRowData['weight_per_box_item']
                            let return_wgt = parseFloat(event.target.value) * parseFloat(wtPerBox);
                            currentRowData['goods_return_weight'] = validateNumberDateInput.current.decimalNumber(return_wgt, 4);
                            delete issueItemsQtyinp.parentElement.dataset.tip;
                        }
                    } else {
                        currentRowData[clickedColName] = '';
                    }

                } else {
                    currentRowData[clickedColName] = '';
                    currentRowData['goods_return_weight'] = '';
                }
                break;
            case 'goods_return_weight':
                if (event.target.value !== '') {
                    if (validateMaterialDetails(event)) {
                        // if (parseFloat(event.target.value) >= currentRowData['product_material_grn_accepted_weight'] || parseFloat(event.target.value) >= currentRowData['closing_balance_weight']) {
                        //     issueItemsQtyinp.parentElement.dataset.tip = 'Return Weight cant exceed Stock Weight...!';
                        //     currentRowData[clickedColName] = '';
                        // } else {
                        //     let wtPerBox = currentRowData['weight_per_box_item']
                        //     let return_wgt = parseFloat(event.target.value) * parseFloat(wtPerBox);
                        //     currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(return_wgt, 4);
                        //     delete issueItemsQtyinp.parentElement.dataset.tip;
                        // }
                        currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                        delete issueItemsQtyinp.parentElement.dataset.tip;

                    } else {
                        currentRowData[clickedColName] = '';
                    }

                } else {
                    currentRowData[clickedColName] = '';
                }
                break;

            case 'goods_return_boxes':
                if (validateMaterialDetails(event)) {
                    if (parseFloat(event.target.value) > currentRowData['no_of_boxes'] || parseFloat(event.target.value) > currentRowData['closing_no_of_boxes']) {
                        issueItemsQtyinp.parentElement.dataset.tip = 'Return boxes cant exceed No of boxes...!';
                        currentRowData[clickedColName] = '';
                    } else {
                        currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                        delete issueItemsQtyinp.parentElement.dataset.tip;

                    }
                }
                else {
                    currentRowData[clickedColName] = '';
                }
                break;

            case "goods_return_rate":
                if (event.target.value !== '') {
                    if (validateMaterialDetails(event)) {
                        const returnRate = event.target.value;

                        // When sales type is "Job Work", allow 0 or greater
                        if (cmb_sales_job_type === "Job Work" && returnRate >= 0) {
                            currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(returnRate, 4);
                            delete issueItemsQtyinp.parentElement.dataset.tip;
                        }
                        // For other sales types, only accept values greater than 0
                        else if (cmb_sales_job_type !== "Job Work" && returnRate > 0) {
                            currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(returnRate, 4);
                            delete issueItemsQtyinp.parentElement.dataset.tip;
                        }
                        // Invalid case: show appropriate error
                        else {
                            issueItemsQtyinp.parentElement.dataset.tip =
                                cmb_sales_job_type === "Job Work"
                                    ? 'Return Rate must be 0 or greater.'
                                    : 'Return Rate must be greater than zero.';
                            currentRowData[clickedColName] = 0;
                        }
                    } else {
                        currentRowData[clickedColName] = 0;
                    }
                } else {
                    currentRowData[clickedColName] = 0;
                    
                }
                break;

            case "goods_return_remark":
                currentRowData[clickedColName] = event.target.value;
                break;
            default:
                break;
        }



        const detailData = [...GrnDetails]
        detailData[arrayIndex] = currentRowData
        setGrnDetails(detailData);

    };

    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const clearFields = () => {
        setGoodsReturnBoxes(0)
        setGoodsReturnWeight(0)
        setGoodsReturnQuantity(0)
    }

    const [dt_goods_return_date, setGoodsReturnDate] = useState(todayDate);

    useEffect(async () => {
        setIsLoading(true);
        try {
            await fillComobos();
            ActionType()
            if (goodsReturnMasterId !== 0) {
                await FnCheckUpdateResponce();
            }
            // FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $("#indent-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])
    // delete popup
    const [show, setShow] = useState(false);
    const [issueNoOptions, setIssueNoOptions] = useState([]);
    const [cmb_product_material_id, setProductMaterialId] = useState('');

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    const currentDate = new Date();

    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate('/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing')
        }
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionLabel('(Update)')
                break;
            case 'view':
                setActionLabel('(View)')
                // await validate.current.readOnly("moduleFormId");
                break;
            case 'approve':
                setActionLabel('(Approve)')
                setGoodReturnStatus("A")

                // await validate.current.readOnly("moduleFormId");
                break;
            default:
                setActionLabel('(Creation)')
                break;
        }
    };

    const fillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setmaterialtypeOptions(getProductType)
                    console.log("Product Type List: ", getProductType);
                });

            //supplier options
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("supplier_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("supp_branch_state_id");
            globalQuery.columns.push("supp_branch_city_id");
            globalQuery.columns.push("supp_branch_gst_no");
            globalQuery.table = "cmv_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getSupplierList => {
                    //  ** Changes for the Searchable Combo
                    const suppliers = [
                        { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                        // { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                        ...getSupplierList.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, supplierGST: supplier.supp_branch_gst_no, supplier_id: supplier.supplier_id })),
                    ];
                    console.log("suppliers details Info", suppliers);
                    setSupplierOptions(suppliers);
                    console.log("Supplier List: ", getSupplierList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 180 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then(getJobTypeAPICall => {
                setJobTypeArr(getJobTypeAPICall);
            })

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getEmployee = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setEmployeeOptions(getEmployee)

        } catch (error) {
            console.log("error: ", error)
        }
    }
    const FnLoadMaterialList = async () => {
        debugger
        try {
            // GET ISSUE NO's 
            resetGlobalQuery();
            globalQuery.columns = ["st.product_material_id", "sm.product_rm_name AS product_material_name"];
            globalQuery.table = "pt_goods_receipt_details st";
            globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });

            globalQuery.joins = [
                {
                    "table": "sm_product_rm sm",
                    "type": "inner",
                    "on": [{
                        "left": "sm.product_rm_id",
                        "right": "st.product_material_id"
                    },
                    {
                        "left": "sm.product_type_id",
                        "right": 12
                    }]
                }
            ]
            globalQuery.groupBy = ['st.product_material_id']
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getMaterials => {
                    console.log(getMaterials);
                    const materialOpts = [
                        { value: '', label: 'Select', },
                        ...getMaterials.map((material) => ({ ...material, value: material.product_material_id, label: material.product_material_name })),
                    ];
                    setMaterialOptions(materialOpts)
                });

        } catch (error) {
            console.log(error);
        }
    }


    const comboOnChange = async (key) => {
        switch (key) {
            case 'materialTypeId':
                var materialTypeId = document.getElementById('cmb_material_type').value;
                if (materialTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setmaterialType(materialTypeId)
                if (materialTypeId !== "0" && materialTypeId !== "") {
                    $('#error_cmb_material_type').hide();
                    await generateGoodReturnsNo();
                } else {
                    setGoodsReturnNo("0")
                }
                break;

            case 'supplier':
                setLotNoOptions([])
                setMaterialOptions([])
                setProductMaterialId('')
                setLotNo('')
                
                resetGlobalQuery();
                globalQuery.columns = ["goods_receipt_no", "goods_receipt_date", "purchase_order_no", "purchase_order_date", "supplier_challan_no", "supplier_challan_date", "goods_receipt_master_transaction_id"]
                globalQuery.table = "pt_goods_receipt_master"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
                globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: Number(supplierComboRef.current.value) });
                globalQuery.conditions.push({ field: "goods_receipt_status", operator: "=", value: 'G' });

                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                const getGoodsReceipSummarytList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log(getGoodsReceipSummarytList)
                if (getGoodsReceipSummarytList.length !== 0) {
                    const options = [
                        { value: '', label: 'Select' },
                        ...getGoodsReceipSummarytList.map((grn) => ({ ...grn, value: grn.goods_receipt_no, label: grn.goods_receipt_no, })),
                    ]
                    setGRNOptions(options)
                    if (keyForViewUpdate === '') {
                        await FnLoadMaterialList()

                    }
                } else {
                    setGRNOptions([])
                }
                break;

            case 'grn':
                setIsLoading(true);
                resetGlobalQuery();
                clearFields();
                const grnNo = grnComboRef.current
                globalQuery.columns = [
                    "pgrd.remark",
                    "pgrd.product_material_name",
                    "pgrd.batch_no",
                    "pgrd.product_material_grn_accepted_quantity",
                    "pgrd.product_material_grn_accepted_weight",
                    "pgrd.no_of_boxes",
                    "pgrd.total_box_weight",
                    "pgrd.total_quantity_in_box",
                    "pgrd.weight_per_box_item",
                    "pgrd.material_rate",
                    "pgrd.godown_name",
                    "pgrd.godown_section_name",
                    "pgrd.godown_section_beans_name",
                    "pgrd.godown_id",
                    "pgrd.godown_section_id",
                    "pgrd.godown_section_beans_id",

                    "smsdtl.product_rm_id",
                    "smsdtl.product_type_id",
                    "smsdtl.goods_receipt_no",
                    "smsdtl.closing_balance_quantity",
                    "smsdtl.closing_balance_weight",
                    "smsdtl.closing_no_of_boxes"
                ]
                globalQuery.table = "ptv_goods_receipt_details pgrd"

                globalQuery.joins = [
                    {
                        "table": `sm_product_rm_stock_details smsdtl`,
                        "type": "LEFT",
                        "on": [
                            { "left": "pgrd.goods_receipt_no", "right": "smsdtl.goods_receipt_no" },
                            { "left": "pgrd.product_material_id", "right": "smsdtl.product_rm_id" },
                            { "left": "smsdtl.day_closed", "right": 0 },
                            // { "left": "smsdtl.godown_id", "right": "2"},
                        ]
                    }
                ]
                globalQuery.conditions.push({ field: "pgrd.goods_receipt_no", operator: "=", value: grnNo });
                globalQuery.conditions.push({ field: "pgrd.is_delete", operator: "=", value: '0', });
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

                const apiResponse = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log(apiResponse)
                if (apiResponse.length !== 0) {
                    setShowAddUpdateBtn(true)
                    setGrnDetails(apiResponse)
                    // setMaterialRate(apiResponse[0].material_rate)
                } else {
                    setGrnDetails([])
                }
                setIsLoading(false)
                break;

            case 'product_material_id':
                debugger
                setLotNo()
                setLotNoOptions([])
                setGRNno('')
                setGrnDetails([])
                //setGRNOptions([]);;

                let selected_material_id = materialsComboRef.current.value;
                if (selected_material_id !== "") {

                    // GET LOT NO's 
                    resetGlobalQuery();
                    globalQuery.columns = ["st.batch_no"];
                    globalQuery.table = "pt_goods_receipt_details st";
                    globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "st.product_material_id", operator: "=", value: selected_material_id });
                    globalQuery.conditions.push({ field: "stm.supplier_id", operator: "=", value: Number(supplierComboRef.current.value) });
                    globalQuery.conditions.push({ field: "st.grn_item_status", operator: "=", value: 'G' });
                    globalQuery.groupBy = ['st.batch_no']
                    globalQuery.joins = [
                        {
                            "table": "pt_goods_receipt_master stm",
                            "type": "inner",
                            "on": [{
                                "left": "stm.goods_receipt_master_transaction_id",
                                "right": "st.goods_receipt_master_transaction_id"
                            },
                            {
                                "left": "stm.supplier_id",
                                "right": Number(supplierComboRef.current.value)
                            }
                            ]
                        }
                    ]

                    comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                        .then(getIssueBatchNos => {
                            const issueBatchOpts = [
                                { value: '', label: 'Select', },
                                ...getIssueBatchNos.map((issueBatchNo) => ({ ...issueBatchNo, value: issueBatchNo.batch_no, label: issueBatchNo.batch_no })),
                            ];
                            setLotNoOptions(issueBatchOpts)
                        });


                    resetGlobalQuery();
                    globalQuery.columns = ["goods_receipt_no", "goods_receipt_date", "purchase_order_no", "purchase_order_date", "supplier_challan_no", "supplier_challan_date", "goods_receipt_master_transaction_id"]
                    globalQuery.table = "pt_goods_receipt_master"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
                    globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: Number(supplierComboRef.current.value) });
                    globalQuery.conditions.push({ field: "goods_receipt_status", operator: "=", value: 'G' });

                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    const getGoodsReceipSummarytList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                    if (getGoodsReceipSummarytList.length !== 0) {
                        const options = [
                            { value: '', label: 'Select' },
                            ...getGoodsReceipSummarytList.map((grn) => ({ ...grn, value: grn.goods_receipt_no, label: grn.goods_receipt_no, })),
                        ]
                        setGRNOptions(options)
                    } else {
                        setGRNOptions([])
                    }
                }
                break;

            case 'lot_no':
                debugger
                let selected_lot_no = lotNoComboRef.current.value;
                let selected_material = materialsComboRef.current.value;

                if (selected_lot_no !== "") {

                    // resetGlobalQuery();
                    // globalQuery.columns = ["goods_receipt_no", "goods_receipt_date"]
                    // globalQuery.table = "ptv_goods_receipt_details"
                    // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
                    // globalQuery.conditions.push({ field: "batch_no", operator: "=", value: selected_lot_no });
                    // globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: Number(supplierComboRef.current.value) });
                    // globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: selected_material });
                    // globalQuery.conditions.push({ field: "goods_receipt_status", operator: "=", values: 'G' });
                    resetGlobalQuery();
                    globalQuery.columns = ["st.goods_receipt_no"];
                    globalQuery.table = "pt_goods_receipt_details st";
                    globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "batch_no", operator: "=", value: selected_lot_no });
                    globalQuery.conditions.push({ field: "st.product_material_id", operator: "=", value: selected_material });
                    globalQuery.conditions.push({ field: "stm.supplier_id", operator: "=", value: Number(supplierComboRef.current.value) });
                    globalQuery.conditions.push({ field: "st.grn_item_status", operator: "=", value: 'G' });
                    globalQuery.groupBy = ['st.goods_receipt_no']
                    globalQuery.joins = [
                        {
                            "table": "pt_goods_receipt_master stm",
                            "type": "inner",
                            "on": [{
                                "left": "stm.goods_receipt_master_transaction_id",
                                "right": "st.goods_receipt_master_transaction_id"
                            },
                            {
                                "left": "stm.supplier_id",
                                "right": Number(supplierComboRef.current.value)
                            }
                            ]
                        }
                    ]
                    console.log(`SUPPLIERID${supplierComboRef.current.value}`)

                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    const getGoodsReceipSummarytList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    if (getGoodsReceipSummarytList.length !== 0) {
                        const options = [
                            { value: '', label: 'Select' },
                            ...getGoodsReceipSummarytList.map((grn) => ({ ...grn, value: grn.goods_receipt_no, label: grn.goods_receipt_no, })),
                        ]
                        setGRNOptions(options)
                    } else {
                        setGRNOptions([])
                    }

                }
                break;

            default:
                break;
        }
    }

    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            setShowAddUpdateBtn(true)
            setIsLoading(true)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnShowParticularRecordForUpdate/${goodsReturnMasterId}/${COMPANY_ID}`)
            const response = await apiCall.json();

            let masterData = response.GoodReturnsMasterData
            let detailsData = response.GoodReturnsDetailsRecord
            setGrnDetails(detailsData)
            setmaterialType(masterData.product_type_id)
            setGoodsReturnNo(masterData.goods_return_no)
            setGRNno(masterData.goods_receipt_no)
            setGoodsReturnDate(masterData.goods_return_date)
            setGoodsReturnVersion(masterData.goods_version)
            setSupplier_id(masterData.supplier_id)
            setSalesOrderJob(masterData.sales_type)
            setGoodReturnStatus(masterData.goods_return_status)
            supplierComboRef.current.value = masterData.supplier_id;

            if (keyForViewUpdate === 'approve' || masterData.goods_return_status === 'A') {
                setGoodReturnStatus("A")
                setApprovedById(UserId)
                setApproveDate(todayDate)
            }
            await comboOnChange("supplier")
            setGrnDetails(detailsData);
            //sales type both
            $('#nxtBtn').hide();


            switch (response.is_active) {
                case true:
                    document.querySelector('input[name="isSupplierActive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isSupplierActive"][value="0"]').checked = true;
                    break;
            }
            setIsLoading(false)

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnValidateSupplierCombo = () => {
        $("#error_cmb_supplier_id").hide();
        $("#error_cmb_grn_no").hide();
    }


    const FnValidateIssueOrder = async () => {
        let headerDataIsValid = await validate.current.validateForm("GoodsReturnEntryFormId");
        if (!headerDataIsValid) { return false; }
        if (cmb_material_type === "") {
            $("#error_cmb_material_type").text("Please fill this field...!");
            $("#error_cmb_material_type").show();
            $("#cmb_material_type").focus();
            return false;
        }

        // Check combo values for customer combo
        if (cmb_material_type !== "" && cmb_sales_job_type === "") {
            $("#error_cmb_sales_job_type_id").text("Please fill this field...!");
            $("#error_cmb_sales_job_type_id").show();
            $("#cmb_sales_job_type_id").focus();
            return false;
        }
        if (cmb_material_type !== "" && cmb_sales_job_type !== "" && cmb_supplier_id === "") {
            $("#error_cmb_supplier_id").text("Please fill this field...!");
            $("#error_cmb_supplier_id").show();
            $("#cmb_supplier_id").focus();
            return false;
        }

        if (cmb_material_type !== "" && cmb_sales_job_type !== "" && cmb_supplier_id !== "" && cmb_grn_no === "") {
            $("#error_cmb_grn_no").text("Please select Goods Receipt No.");
            $("#error_cmb_grn_no").show();
            $("#cmb_grn_no").focus();
            return false;
        }
        let issueDetailsIsValid = false;
        let addedIssuematerial = $('#issue-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Issued Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid;
        } else {
            const tableRows = $('#issue-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {
                // if (keyForViewUpdate === "") {
                //     const cost_center = parseInt($(this).find('select[id^="cost_center_id_"]').val());
                //     if (isNaN(cost_center) || cost_center === "" || cost_center === 0) {
                //         $(this).find(`select[id^='cost_center_id_']`).parent().attr('data-tip', 'Please Select Cost Center..!');
                //         $(this).find(`select[id^='cost_center_id_']`).focus();
                //         setOpenIssueDetailsAccord(true)
                //         issueDetailsIsValid = false;
                //         return false;
                //     }
                // }
                // else 
                if (keyForViewUpdate === "" || keyForViewUpdate === "update") {
                    const Goods_return_quantity = parseFloat($(this).find('input[id^="goods_return_quantity_"]').val());
                    if (isNaN(Goods_return_quantity) || Goods_return_quantity === "" || Goods_return_quantity === 0) {
                        $(this).find(`input[id^='return_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
                        $(this).find(`input[id^='return_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;

                    }
                }
                if (keyForViewUpdate === "" || keyForViewUpdate === "update") {

                    // const Goods_return_rate = parseFloat($(this).find('input[id^="goods_return_rate_"]').val());
                    // if (isNaN(Goods_return_rate) || Goods_return_rate === "" || Goods_return_rate === 0) {
                    //     $(this).find(`input[id^='goods_return_rate_']`).parent().attr('data-tip', 'Please enter a valid rate...!');
                    //     $(this).find(`input[id^='goods_return_rate_']`).focus();
                    //     setOpenIssueDetailsAccord(true)
                    //     issueDetailsIsValid = false;
                    //     return false;
                    // }
                    const Goods_return_rate = parseFloat($(this).find('input[id^="goods_return_rate_"]').val());
                    const isJobWork = cmb_sales_job_type === "Job Work";

                    if (isNaN(Goods_return_rate) || Goods_return_rate === "" || (!isJobWork && Goods_return_rate === 0)) {
                        const errorMessage = isJobWork
                            ? "Please enter a valid rate (0 or greater) for Job Work...!"
                            : "Please enter a valid rate greater than 0...!";

                        $(this).find(`input[id^='goods_return_rate_']`).parent().attr('data-tip', errorMessage);
                        $(this).find(`input[id^='goods_return_rate_']`).focus();
                        setOpenIssueDetailsAccord(true);
                        issueDetailsIsValid = false;
                        return false;
                    }

                }
                if (keyForViewUpdate === "" || keyForViewUpdate === "update") {

                    const Goods_return_wt = parseFloat($(this).find('input[id^="goods_return_weight_"]').val());
                    if (isNaN(Goods_return_wt) || Goods_return_wt === "" || Goods_return_wt === 0) {
                        $(this).find(`input[id^='goods_return_weight_']`).parent().attr('data-tip', 'Please enter a valid weight...!');
                        $(this).find(`input[id^='goods_return_weight_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }

            });
            return issueDetailsIsValid;
        }
    }


    const FnAddUpdateRecord = async () => {
        debugger
        try {
            let checkIsValidate = true;

            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateIssueOrder();
            }
            setIsLoading(true)
            let lastGoods_return_no = txt_goods_return_no;
            if (keyForViewUpdate === "") {
                lastGoods_return_no = await generateGoodReturnsNo();
            }

            if (checkIsValidate === true) {
                const json = { 'GoodsReturnMasterData': {}, 'GoodsReturnDetailData': [] }
                const masterData = {
                    goods_return_master_id: keyForViewUpdate === "" ? 0 : goodsReturnMasterId,
                    product_type_id: cmb_material_type,
                    goods_return_no: lastGoods_return_no,
                    goods_return_date: dt_goods_return_date,
                    goods_receipt_no: cmb_grn_no,
                    goods_version: txt_goods_return_version,
                    supplier_id: cmb_supplier_id,
                    sales_type: cmb_sales_job_type,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    approved_by_id: keyForViewUpdate === "approve" ? cmb_approved_by_id : '',
                    approved_date: keyForViewUpdate === "approve" ? dt_approved_date : '',
                    goods_return_status: keyForViewUpdate === "approve" ? "A" : "P",
                    is_active: true,
                    is_delete: false,//
                    created_by: UserName,
                    modified_by: goodsReturnMasterId !== "" ? UserName : null,
                };
                // const detailsDataArray = GrnDetails.map((grnDetail, index) => ({
                //     goods_return_details_id: GrnDetails[index]?.goods_return_details_id ?? 0,
                //     product_rm_id: grnDetail.product_rm_id,
                //     goods_return_quantity: inputValues[index]?.return_quantity || '',
                //     goods_return_weight: GrnDetails[index]?.weight_per_box_item * inputValues[index]?.return_quantity || '',
                //     goods_return_boxes: inputValues[index]?.return_boxes || '',
                //     goods_return_rate: inputValues[index]?.return_rate || '',
                //     goods_return_remark: inputValues[index]?.goods_return_remark || '',
                //     goods_receipt_no: grnDetail.goods_receipt_no,
                //     company_id: COMPANY_ID,
                //     company_branch_id: COMPANY_BRANCH_ID,
                //     financial_year: FINANCIAL_SHORT_NAME,
                //     is_active: true,
                //     is_delete: false,
                //     created_by: UserName,
                //     modified_by: goodsReturnMasterId !== "" ? UserName : null,
                //     godown_id: GrnDetails[index]?.godown_id,
                //     godown_section_id: GrnDetails[index]?.godown_section_id,
                //     godown_section_beans_id: GrnDetails[index]?.godown_section_beans_id,
                //     issue_batch_no: GrnDetails[index]?.batch_no,
                //     cone_per_wt: GrnDetails[index]?.weight_per_box_item,
                // }));
                for (let returnItem = 0; returnItem < GrnDetails.length; returnItem++) {
                    const indentIssueDetailsRow = GrnDetails[returnItem];

                    const indentReturnRow = {};
                    indentReturnRow.company_id = COMPANY_ID
                    indentReturnRow.company_branch_id = COMPANY_BRANCH_ID
                    indentReturnRow.financial_year = FINANCIAL_SHORT_NAME
                    indentReturnRow.goods_return_details_id = keyForViewUpdate !== "" ? indentIssueDetailsRow.goods_return_details_id : 0
                    indentReturnRow.product_rm_id = indentIssueDetailsRow.product_rm_id
                    indentReturnRow.goods_return_quantity = indentIssueDetailsRow.goods_return_quantity
                    indentReturnRow.goods_return_weight = indentIssueDetailsRow.goods_return_weight
                    indentReturnRow.goods_return_boxes = indentIssueDetailsRow.goods_return_boxes || 0
                    indentReturnRow.goods_return_rate = indentIssueDetailsRow?.goods_return_rate
                    indentReturnRow.goods_return_remark = indentIssueDetailsRow?.goods_return_remark
                    indentReturnRow.godown_id = indentIssueDetailsRow?.godown_id
                    indentReturnRow.godown_section_id = indentIssueDetailsRow?.godown_section_id
                    indentReturnRow.godown_section_beans_id = indentIssueDetailsRow?.godown_section_beans_id
                    indentReturnRow.issue_batch_no = indentIssueDetailsRow?.batch_no
                    indentReturnRow.cone_per_wt = indentIssueDetailsRow?.cone_per_wt
                    indentReturnRow.goods_receipt_no = indentIssueDetailsRow?.goods_receipt_no
                    json.GoodsReturnDetailData.push(indentReturnRow);

                }


                json.GoodsReturnMasterData = masterData;
                // json.GoodsReturnDetailData = detailsDataArray;
                const formData = new FormData()
                formData.append('GoodsReturnsDetails', JSON.stringify(json))
                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnAddUpdateRecord`, forwardData)
                const responce = await apiCall.json();
                console.log("response error: ", responce.data);
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    setSuccMsg(responce.message);
                    setShowSuccessMsgModal(true);
                    // navigate(`/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing`)
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    };

    const generateGoodReturnsNo = async () => {
        const ptShortName = materialtypeOptions.find(item => item.field_id === parseInt($('#cmb_material_type').val()));
        const productTypeId = $('#cmb_material_type option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_goods_return_master", "goods_return_no", ptShortName.product_type_short_name, "GMR", "5", "product_type_id", productTypeId);
        setGoodsReturnNo(autoNoApiCall);
        return autoNoApiCall;
    };

    const onQuantityChange = (quantity, wt_per_box) => {

        setGoodsReturnQuantity(quantity);
        setGoodsReturnWeight(quantity * wt_per_box || 0);
        //jquery for showing error
    }

    const editUpdateRecord = (index, action) => {
        switch (action) {
            case 'delete':
                setGrnDetails((prevGrnDetails) => {
                    return prevGrnDetails.filter((grnD, i) => i !== index)
                })
                break;
        }
    }


    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateFields = () => {
        validate.current.validateFieldsOnChange('GoodsReturnEntryFormId')
    }

    // ------------------------ Changes for the Searchable Combos. --------------------------

    let supplierComboRef = useRef(null);
    let grnComboRef = useRef(null);

    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Sr No.</th>
                        <th className="erp_table_th">Material name</th>
                        <th className="erp_table_th">Lot No.</th>
                        <th className="erp_table_th">GRN Total Weight(Kg)</th>
                        <th className="erp_table_th">GRN Total Cones</th>
                        <th className="erp_table_th">GRN Wt. per Cone</th>
                        <th className="erp_table_th">GRN No. of boxes</th>
                        <th className="erp_table_th">GRN remark</th>
                        <th className="erp_table_th">Stock quantity(cones)</th>
                        <th className="erp_table_th">Stock Wt.(Kg)</th>
                        <th className="erp_table_th">Stock no of boxes</th>
                        <th className="erp_table_th">Return Quantity</th>
                        <th className="erp_table_th">Return Weight</th>
                        <th className="erp_table_th">Return boxes</th>
                        <th className="erp_table_th">Material rate</th>
                        <th className="erp_table_th">Return Remark</th>
                        <th className="erp_table_th">Godown Name</th>
                        <th className="erp_table_th">Godown section name</th>
                        <th className="erp_table_th">Godown section beans name</th>
                    </tr>
                </thead>
                <tbody>
                    {GrnDetails.map((docItem, Index) =>
                        <tr key={Index} className="sticky-column">
                            <td className={`erp_table_td  ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex", justifyContent: "center" }}>
                                <Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id={`delete_btn_${Index}`} onClick={() => editUpdateRecord(Index, 'delete')} />
                                    </MDTypography>
                                </Tooltip>
                            </div>
                            </td>
                            <td className="erp_table_td">{Index + 1}</td>
                            <td className="erp_table_td">{docItem.product_material_name}</td>
                            <td className="erp_table_td">{docItem.batch_no}</td>
                            <td className="erp_table_td">{docItem.product_material_grn_accepted_quantity}</td>
                            <td className="erp_table_td">{docItem.total_quantity_in_box}</td>
                            <td className="erp_table_td">{docItem.weight_per_box_item}</td>
                            <td className="erp_table_td">{docItem.no_of_boxes}</td>
                            <td className="erp_table_td">{docItem.remark}</td>
                            <td className="erp_table_td">{docItem.closing_balance_quantity}</td>
                            <td className="erp_table_td">{docItem.closing_balance_weight}</td>
                            <td className="erp_table_td">{docItem.closing_no_of_boxes}</td>
                            {/* <td className="erp_table_td">
                                <Form.Control
                                    type="text"
                                    className="erp_input_field"
                                    id={`return_quantity_${docItem.product_rm_id}_${Index}`}
                                    disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                    value={inputValues[Index]?.return_quantity || ''}
                                    onChange={(e) =>
                                        handleInputChange(Index, 'return_quantity', e)
                                    }
                                    style={{ marginBottom: "0px" }}
                                />

                            </td> */}
                            <td className="erp_table_td">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`goods_return_quantity_${Index}`} className="erp_input_field mb-0 issueField"
                                        value={docItem.goods_return_quantity} onChange={(e) => { FnOnChangeTblRow(docItem, e, Index); }} Headers='goods_return_quantity' />
                                    :
                                    docItem.goods_return_quantity
                                }
                            </td>
                            <td className="erp_table_td">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?

                                    <input type="text" id={`goods_return_weight_${Index}`} className="erp_input_field mb-0 issueField"
                                        value={docItem.goods_return_weight} onChange={(e) => { FnOnChangeTblRow(docItem, e, Index); }} Headers='goods_return_weight' />
                                    :
                                    docItem.goods_return_weight
                                }
                            </td>
                            <td className="erp_table_td">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?

                                    <input type="text" id={`goods_return_boxes_${Index}`} className="erp_input_field mb-0 issueField"
                                        value={docItem.goods_return_boxes} onChange={(e) => { FnOnChangeTblRow(docItem, e, Index); }} Headers='goods_return_boxes' />
                                    :
                                    docItem.goods_return_boxes
                                }
                            </td>

                            <td className="erp_table_td">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?

                                    <input type="text" id={`goods_return_rate_${Index}`} className="erp_input_field mb-0 issueField"
                                        value={docItem.goods_return_rate} onChange={(e) => {FnOnChangeTblRow(docItem, e, Index) }} Headers='goods_return_rate' />
                                    :
                                    docItem.goods_return_rate
                                }
                            </td>

                            <td className="erp_table_td">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?

                                    <input type="text" id={`goods_return_remark_${Index}`} className="erp_input_field mb-0 issueField"
                                        value={docItem.goods_return_remark} onChange={(e) => { FnOnChangeTblRow(docItem, e, Index); }} Headers='goods_return_remark' />
                                    :
                                    docItem.goods_return_remark
                                }
                            </td>
                            {/* <td className="erp_table_td">
                                <Form.Control
                                    type="text"
                                    id={`return_weight_${docItem.product_rm_id}_${Index}`}

                                    className="erp_input_field"
                                    disabled
                                    value={inputValues[Index]?.return_weight || ''}
                                    style={{ marginBottom: "0px" }}
                                />

                            </td>
                            <td className="erp_table_td">
                                <Form.Control
                                    type="text"
                                    id={`return_boxes_${Index}`}
                                    className="erp_input_field"
                                    disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                    value={inputValues[Index]?.return_boxes || ''}
                                    onChange={(e) =>
                                        handleInputChange(Index, 'return_boxes', e)
                                    }
                                    style={{ marginBottom: "0px" }}
                                />

                            </td>
                            <td className="erp_table_td">
                                <Form.Control
                                    type="text"
                                    id={`return_rate_${docItem.product_rm_id}_${Index}`}
                                    className="erp_input_field"
                                    disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                    value={inputValues[Index]?.return_rate || ''}
                                    onChange={(e) =>
                                        handleInputChange(Index, 'return_rate', e)
                                    }
                                    style={{ marginBottom: "0px" }}
                                />

                            </td>
                            <td className="erp_table_td">
                                <Form.Control
                                    type="text"
                                    className="erp_input_field"
                                    // id={`return_quantity_${Index}`}
                                    id={`goods_return_remark_${docItem.product_rm_id}_${Index}`}
                                    disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                    value={inputValues[Index]?.goods_return_remark || ''}
                                    onChange={(e) =>
                                        handleInputChange(Index, 'goods_return_remark', e)
                                    }
                                    style={{ marginBottom: "0px" }}
                                />
                            </td> */}
                            <td className="erp_table_td">{docItem.godown_name}</td>
                            <td className="erp_table_td">{docItem.godown_section_name}</td>
                            <td className="erp_table_td">{docItem.godown_section_beans_name}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [GrnDetails, keyForViewUpdate])

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}
                    {/* <form id="GoodsReturnEntryFormId"> */}
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg text-center'>Goods Return / Sale {action_Label}</label>
                        </div>
                        <form id="GoodsReturnEntryFormId">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Goods Return Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_material_type"
                                                className="form-select form-select-sm"
                                                disabled={['view', 'update', 'approve'].includes(keyForViewUpdate)} value={cmb_material_type}
                                                onChange={() => { comboOnChange('materialTypeId'); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {materialtypeOptions.length !== 0 ? (
                                                    <>
                                                        {materialtypeOptions.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                {materialTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Goods Return No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_goods_return_no" className="erp_input_field" value={txt_goods_return_no} disabled />
                                                    <MDTypography variant="button" id="txt_goods_return_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_goods_return_version' className="erp_input_field text-end" value={txt_goods_return_version} disabled />
                                                    <MDTypography variant="button" id="txt_goods_return_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_goods_return_date' className="erp_input_field" value={dt_goods_return_date}
                                                        disabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}
                                                        onChange={e => { setGoodsReturnDate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="dt_goods_return_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Sales Type<span className="required">*</span></Form.Label>;
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_sales_job_type_id"
                                                className="form-select form-select-sm"
                                                value={cmb_sales_job_type}
                                                onChange={(e) => { setSalesOrderJob(e.target.value);clearAllDataTipsForMaterialRate(); validateFields(); }}
                                                // optional='optional'
                                                disabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}>
                                                <option value="" >Select</option>
                                                {jobTypeOptions?.map(jobtype => (
                                                    <option value={jobtype.field_name}>{jobtype.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sales_job_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Goods Return Status </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_goods_return_status" className="form-select form-select-sm" value={cmb_goods_return_status} onChange={(e) => { setGoodReturnStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Supplier<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <Select ref={supplierComboRef}
                                                options={supplierOptions}
                                                isDisabled={['view', 'update', 'approve'].includes(keyForViewUpdate)} inputId="cmb_supplier_id" // Provide the ID for the input box
                                                value={supplierOptions.find(option => option.value === cmb_supplier_id)}
                                                onChange={(selectedOpt) => {
                                                    setSupplier_id(selectedOpt.value);
                                                    supplierComboRef.current = selectedOpt ?? { value: "" };
                                                    comboOnChange("supplier");
                                                    validateFields();
                                                    FnValidateSupplierCombo();

                                                }}
                                                placeholder="Search for a supplier..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    {keyForViewUpdate === '' ? <>
                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Yarn Count</Form.Label>
                                            </div>
                                            <div className={`col-sm-9 col-12`}>
                                                <Select ref={materialsComboRef}
                                                    options={materialOptions}
                                                    isDisabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}
                                                    inputId="cmb_issue_no" // Provide the ID for the input box
                                                    value={materialOptions.find(option => option.value === cmb_product_material_id) || null}
                                                    onChange={(selectedOpt) => {
                                                        setProductMaterialId(selectedOpt.value);
                                                        materialsComboRef.current = selectedOpt;
                                                        comboOnChange('product_material_id');
                                                        validateFields();
                                                    }}
                                                    placeholder="Search for Issue No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_issue_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Lot No</Form.Label>
                                            </div>
                                            <div className={`col-sm-9 col-12`}>
                                                <Select ref={lotNoComboRef}
                                                    options={lotNoOptions}
                                                    isDisabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}
                                                    inputId="cmb_lot_no" // Provide the ID for the input box
                                                    value={lotNoOptions.find(option => option.value === cmb_lot_no) || null}
                                                    onChange={(selectedOpt) => {
                                                        setLotNo(selectedOpt.value);
                                                        lotNoComboRef.current = selectedOpt;
                                                        comboOnChange('lot_no');
                                                        validateFields();
                                                    }}
                                                    placeholder="Search for Lot No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_lot_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </> : null}
                                    {cmb_goods_return_status === "A" ?
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Approved By  </Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} optional="optional"
                                                        onChange={() => {
                                                            validateFields();
                                                        }} disabled>
                                                        <option value="">Select</option>
                                                        {employeeOptions.length !== 0 ? (
                                                            <>
                                                                {employeeOptions?.map(acceptedById => (
                                                                    <option value={acceptedById.field_id}>{acceptedById.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className={`row`}>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Approved Date</Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApproveDate(e.target.value); validateFields(); }} min={currentDate} optional='optional' disabled />
                                                    <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                    }
                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Goods Receipt No. <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <Select
                                                inputId="cmb_grn_no"
                                                ref={grnComboRef}
                                                options={grnOptions}
                                                value={grnOptions.find(option => option.value === cmb_grn_no) || null}
                                                onChange={(selectedOption) => {
                                                    grnComboRef.current = selectedOption.value
                                                    validateFields();
                                                    setGRNno(selectedOption.value);
                                                    comboOnChange("grn");
                                                    $("#error_cmb_grn_no").hide();
                                                }}
                                                isDisabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}
                                                placeholder="Search for a GRN no..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />

                                            <MDTypography variant="button" id="error_cmb_grn_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* {
                            // error check?
                            GrnDetails?.length !== 0 ? <>
                                <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>


                                    <thead className="erp_table_head">
                                        <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                                        <th className="erp_table_th">Sr No.</th>
                                        <th className="erp_table_th">Material name</th>
                                        <th className="erp_table_th">Batch No.</th>
                                        <th className="erp_table_th">Accepted Wt</th>
                                        <th className="erp_table_th">Total Cones</th>
                                        <th className="erp_table_th">Wt. per Cone</th>
                                        <th className="erp_table_th">No. of boxes</th>
                                        <th className="erp_table_th">GRN remark</th>
                                        <th className="erp_table_th">Closing quantity</th>
                                        <th className="erp_table_th">Closing Wt.</th>
                                        <th className="erp_table_th">Closing no of boxes</th>
                                        <th className="erp_table_th">Return Quantity</th>
                                        <th className="erp_table_th">Return Weight</th>
                                        <th className="erp_table_th">Return boxes</th>
                                        <th className="erp_table_th">Material rate</th>
                                        <th className="erp_table_th">Return Remark</th>
                                        <th className="erp_table_th">Godown Name</th>
                                        <th className="erp_table_th">Godown section name</th>
                                        <th className="erp_table_th">Godown section beans name</th>
                                    </thead>
                                    <tbody>
                                        {GrnDetails.map((docItem, Index) => (
                                            // <tr key={Index} style={{ height: "2rem", verticalAlign: "middle" }}>
                                            <tr key={Index} className="sticky-column">

                                               
                                                <td className={`erp_table_td  ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                                    <Tooltip title="Delete" placement="top">
                                                        <MDTypography className="erp-view-btn">
                                                            <MdDelete className="erp-delete-btn" id={`delete_btn_${Index}`} onClick={() => editUpdateRecord(Index, 'delete')} />
                                                        </MDTypography>
                                                    </Tooltip>
                                                </div>
                                                </td>
                                                <td className="erp_table_td">{Index + 1}</td>
                                                <td className="erp_table_td">{docItem.product_material_name}</td>
                                                <td className="erp_table_td">{docItem.batch_no}</td>
                                                <td className="erp_table_td">{docItem.product_material_grn_accepted_quantity}</td>
                                                <td className="erp_table_td">{docItem.total_quantity_in_box}</td>
                                                <td className="erp_table_td">{docItem.weight_per_box_item}</td>
                                                <td className="erp_table_td">{docItem.no_of_boxes}</td>
                                                <td className="erp_table_td">{docItem.remark}</td>
                                                <td className="erp_table_td">{docItem.closing_balance_quantity}</td>
                                                <td className="erp_table_td">{docItem.closing_balance_weight}</td>
                                                <td className="erp_table_td">{docItem.closing_no_of_boxes}</td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        className="erp_input_field"
                                                        id={`return_quantity_${docItem.product_rm_id}_${Index}`}
                                                        disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                        value={inputValues[Index]?.return_quantity || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(Index, 'return_quantity', e)
                                                        }
                                                        style={{ marginBottom: "0px" }}
                                                    />

                                                </td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        id={`return_weight_${docItem.product_rm_id}_${Index}`}

                                                        className="erp_input_field"
                                                        disabled
                                                        value={inputValues[Index]?.return_weight || ''}
                                                        style={{ marginBottom: "0px" }}
                                                    />

                                                </td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        id={`return_boxes_${Index}`}
                                                        className="erp_input_field"
                                                        disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                        value={inputValues[Index]?.return_boxes || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(Index, 'return_boxes', e)
                                                        }
                                                        style={{ marginBottom: "0px" }}
                                                    />

                                                </td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        id={`return_rate_${docItem.product_rm_id}_${Index}`}
                                                        className="erp_input_field"
                                                        disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                        value={inputValues[Index]?.return_rate || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(Index, 'return_rate', e)
                                                        }
                                                        style={{ marginBottom: "0px" }}
                                                    />

                                                </td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        className="erp_input_field"
                                                        // id={`return_quantity_${Index}`}
                                                        id={`goods_return_remark_${docItem.product_rm_id}_${Index}`}
                                                        disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                        value={inputValues[Index]?.goods_return_remark || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(Index, 'goods_return_remark', e)
                                                        }
                                                        style={{ marginBottom: "0px" }}
                                                    />
                                                </td>
                                                <td className="erp_table_td">{docItem.godown_name}</td>
                                                <td className="erp_table_td">{docItem.godown_section_name}</td>
                                                <td className="erp_table_td">{docItem.godown_section_beans_name}</td>
                                            </tr>
                                        ))}
                                    </tbody>


                                </Table></> : <></>} */}

                        <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Material Return Details</Accordion.Header>
                                <Accordion.Body>

                                    <div className="mt-10">
                                        {GrnDetails.length !== 0 ?
                                            <>
                                                {renderDetailsTable}
                                            </> :
                                            <div className='row  text-center'>
                                                <div className="col-12 ">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion >
                    </div>

                    <div className='erp_frm_Btns'>
                        <MDButton type="button"
                            onClick={() => {
                                const path = compType === 'Purchase' ? '/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing' : '/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnEntry';
                                navigate(path);
                            }}
                            className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" >Back</MDButton> &nbsp;
                        {showAddUpdateBtn && <MDButton type="button" onClick={FnAddUpdateRecord} className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" >{keyForViewUpdate === "approve" ? "Approve" : keyForViewUpdate === "" ? "Save" : "Update"}
                        </MDButton>}
                    </div>

                    <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                    <hr />
                    {/* </form> */}
                </>
            </DashboardLayout >
        </>
    )
}